import React from "react";
import { Link } from "react-router-dom";
import SingleProduct from "../../component/product/SingleProduct";
import { useSelector } from "react-redux";

const TopProducts = () => {
  const { recommendedProductTotal, trendingProductTotal, hotProductTotal } =
    useSelector((store) => store.product);
  return (
    <>
      {recommendedProductTotal.length > 0 && (
        <section className="section product-slider tab-slider-product">
          <div className="container">
            <div className="section-header d-none">
              <h2>Special Offers</h2>
              <p>Browse the huge variety of our best seller</p>
            </div>
            <div className="tabs-listing">
              <ul
                className="nav nav-tabs style1 justify-content-center"
                id="productTabs"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link head-font active"
                    id="bestsellers-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#bestsellers"
                    type="button"
                    role="tab"
                    aria-controls="bestsellers"
                    aria-selected="true"
                  >
                    Bestseller
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link head-font"
                    id="newarrivals-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#newarrivals"
                    type="button"
                    role="tab"
                    aria-controls="newarrivals"
                    aria-selected="false"
                  >
                    New Arrivals
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link head-font"
                    id="toprated-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#toprated"
                    type="button"
                    role="tab"
                    aria-controls="toprated"
                    aria-selected="false"
                  >
                    Top Rated
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="productTabsContent">
                <div
                  class="tab-pane show active"
                  id="bestsellers"
                  role="tabpanel"
                  aria-labelledby="bestsellers-tab"
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
                    <div className="grid-products grid-view-items">
                      <div className="row col-row product-options row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                        {recommendedProductTotal &&
                          recommendedProductTotal.map((product, index) => (
                            <SingleProduct key={index} product={product} />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="newarrivals"
                  role="tabpanel"
                  aria-labelledby="newarrivals-tab"
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
                    <div className="grid-products grid-view-items">
                      <div className="row col-row product-options row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                        {hotProductTotal &&
                          hotProductTotal.map((product, index) => (
                            <SingleProduct key={index} product={product} />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane"
                  id="toprated"
                  role="tabpanel"
                  aria-labelledby="toprated-tab"
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
                    <div className="grid-products grid-view-items">
                      <div className="row col-row product-options row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                        {trendingProductTotal &&
                          trendingProductTotal.map((product, index) => (
                            <SingleProduct key={index} product={product} />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default TopProducts;
