import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Home from "./pages/Home";
import ProductInfoPage from "./pages/ProductInfoPage";
import Register from "./pages/account/Register";
import MyAccount from "./pages/account/MyAccount";
import Wishlist from "./pages/account/Wishlist";
import Checkout from "./pages/checkout/Checkout";
import OrderSuccess from "./pages/ordersuccess/OrderSuccess";
import ViewCart from "./pages/checkout/ViewCart";
import ProductInfo from "./pages/product/ProductInfo";
import Blog from "./pages/blog/Blog";
import PrivacyPolicy from "./pages/footerabout/PrivacyPolicy";
import SearchProduct from "./pages/search/SearchProduct";
import BlogDetails from "./pages/blogdetail/BlogDetails";
import About from "./pages/about/About";
import Career from "./pages/career/Career";
import Content1 from "./pages/content/Content1";
import Affiliate from "./pages/affiliate/Affiliate";
import RefundPolicy from "./pages/refundpolicy/RefundPolicy";
import Faq from "./pages/faq/Faq";
import TermsUse from "./pages/terms/TermsUse";
import TermsSale from "./pages/terms/TermsSale";
import CancellationPolicy from "./pages/terms/CancellationPolicy";
import Compare from "./pages/compare/Compare";
import EmptyOder from "./pages/emptyoder/EmptyOder";
import { getSuperCategory } from "./redux/superCategory/superCategorySlice";
import { getCategory } from "./redux/category/categorySlice";
import { getSubCategory } from "./redux/subCategory/subCategorySlice";
import { getBrand } from "./redux/brand/brandSlice";
import { getCategorytag } from "./redux/categoryTags/CategoryTagsSlice";
import { getHotProduct, getRecommendProduct, getTrendingProduct } from "./redux/product/productSlice";
import OrderTracking from "./pages/account/account/OrderTracking";
import { getOrderbyClId } from "./redux/order/OrderSlice";
import CategorySearch from "./pages/search/CategorySearch";
import SubcategorySearch from "./pages/search/SubcategorySearch";
import SearchCategoryTag from "./pages/search/SearchCategoryTag";
import {
  getMain_home_Slider,
  get_three_Slider,
} from "./redux/sliders/SlidersSlice";
import Authentication from "./pages/Authentication";
import Category from "./pages/category/Category";
import SubCategories from "./pages/subcategories/SubCategories";

function ScrollToTop() {
  const {
    recomendproductLoading,
    recommendedProductTotal,
    isRecommendProductAvailable,
    trendingProductTotal,
    trendingproductLoading,
    istrendingProductAvailable,
    hotProductTotal,
    hotproductLoading,
    ishotProductAvailable,
  } = useSelector((store) => store.product);
  const {
    categorytotal,
    categoryLoading,
    isCategoryAvailable,
  } = useSelector((store) => store.category);
  const {
    subcategorytotal,
    subCategoryLoading,
    isSubCategoryAvailable,
  } = useSelector((store) => store.subCategories);
  const {
    categoryTagTotal,
    CategoryTagLoading,
    isCategoryTagAvailable,
  } = useSelector((store) => store.categorytag);
  const {
    mainHomeSliderTotal,
    mainHomeSliderLoading,
    isMainHomeSliderAvailable,

    threeSliderTotal,
    threeSliderLoading,
    isThreeSliderAvailable,
  } = useSelector((store) => store.slider);

  const { isAuth, loginData } = useSelector((state) => state.Athentication);

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {

    if (!isMainHomeSliderAvailable || mainHomeSliderTotal.length === 0) {
      dispatch(getMain_home_Slider());
    }

    if (!isSubCategoryAvailable || subcategorytotal.length === 0) {
      dispatch(getSubCategory());
    }

    if (!isThreeSliderAvailable || threeSliderTotal.length === 0) {
      dispatch(get_three_Slider());
    }

    if (!isRecommendProductAvailable || recommendedProductTotal.length === 0) {
      dispatch(getRecommendProduct());
    }
    if (!istrendingProductAvailable || trendingProductTotal.length === 0) {
      dispatch(getTrendingProduct());
    }
    if (!ishotProductAvailable || hotProductTotal.length === 0) {
      dispatch(getHotProduct());
    }

    if (!isCategoryAvailable || categorytotal.length === 0) {
      dispatch(getCategory());
    }
    if (!isCategoryTagAvailable || categoryTagTotal.length === 0) {
      dispatch(getCategorytag());
    }




  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {

      if (mainHomeSliderLoading) {
        dispatch(getMain_home_Slider());
      }
      if (subCategoryLoading) {
        dispatch(getSubCategory());
      }
      if (threeSliderLoading) {
        dispatch(get_three_Slider());
      }
      if (categoryLoading) {
        dispatch(getCategory());
      }
      if (CategoryTagLoading) {
        dispatch(getCategorytag());
      }
      if (recomendproductLoading) {
        dispatch(getRecommendProduct());
      }

      if (trendingproductLoading) {
        dispatch(getTrendingProduct());
      }

      if (hotproductLoading) {
        dispatch(getHotProduct());
      }

    }, 20000);
  }, []);

  useEffect(() => {

    dispatch(getSuperCategory());

    dispatch(getBrand());


    if (isAuth === true) {
      dispatch(getOrderbyClId(loginData._id));
    }
  }, [isAuth]);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/login" element={<Authentication />} />
            <Route path="/Register" element={<Register />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/Wishlist" element={<Wishlist />} />
            <Route path="/check-out" element={<Checkout />} />
            <Route path="/OrderSuccess" element={<OrderSuccess />} />
            <Route path="/cart" element={<ViewCart />} />
            <Route path="/ProductInfo" element={<ProductInfo />} />
            <Route
              path="/product-info/:producturl"
              element={<ProductInfoPage />}
            />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/category/:category" element={<Category />} />
            <Route path="/shop/:sub_category" element={<SubCategories />} />
            <Route path="/product-search/:search" element={<SearchProduct />} />
            <Route
              path="/tag-search/:cat_tag"
              element={<SearchCategoryTag />}
            />
            <Route
              path="/search/:super_category/:category"
              element={<CategorySearch />}
            />
            
            <Route
              path="/search/:super_category/:category/:sub_category"
              element={<SubcategorySearch />}
            />
            <Route path="/BlogDetails" element={<BlogDetails />} />
            <Route path="/About" element={<About />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Career" element={<Career />} />
            <Route path="/Content1" element={<Content1 />} />
            <Route path="/Affiliate" element={<Affiliate />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/RefundPolicy" element={<RefundPolicy />} />
            <Route path="/TermsUse" element={<TermsUse />} />
            <Route path="/TermsSale" element={<TermsSale />} />
            <Route
              path="/CancellationPolicy"
              element={<CancellationPolicy />}
            />
            <Route path="/Compare" element={<Compare />} />
            <Route path="/EmptyOder" element={<EmptyOder />} />
            <Route
              path="/order-tracking/:orderId"
              element={<OrderTracking />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
