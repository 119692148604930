import React from "react";
import SliderHomeBanner from "../../component/homecomponent/SliderHomeBanner";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const HomeBanner = () => {
  const { mainHomeSliderTotal } = useSelector((store) => store.slider);
  console.log(mainHomeSliderTotal, "mainHomeSliderTotal");
  return (
    <>
      <section className="slideshow slideshow-wrapper">
        <div className="home-slideshow slick-arrow-dots">
          {/* <SliderHomeBanner /> */}
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {mainHomeSliderTotal &&
              mainHomeSliderTotal.map((slider, index) => (
                <SwiperSlide key={index}>
                  <div className="slide">
                    <div className="slideshow-wrap">
                      <picture>
                        <source
                          media="(max-width:767px)"
                          srcSet={slider.desktopImage}
                          width={1150}
                          height={800}
                        />
                        <img
                          className="blur-up lazyload"
                          srcSet={slider.desktopImage}
                          alt="slideshow"
                          title
                          width={1920}
                          height={795}
                        />
                      </picture>
                      <div className="container">
                        <div className="slideshow-content slideshow-overlay middle-left">
                          {slider.position === "Right" ? (
                            <>
                              <div className="slideshow-content-in">
                                <div className="wrap-caption style1">
                                  <p className="ss-small-title">
                                    {slider.tagname}
                                  </p>
                                  <h2 className="ss-mega-title">
                                    {/* Making someone feel <br />
                                pretty is an art */}
                                    {slider.hedding}
                                  </h2>
                                  <p className="ss-sub-title xs-hide">
                                    {slider.sub_hedding}
                                  </p>
                                  <div className="ss-btnWrap">
                                    <span className="btn btn-primary">
                                      {slider.button_name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="slideshow-content-in"
                                style={{ left: "50%" }}
                              >
                                <div className="wrap-caption style1">
                                  <p className="ss-small-title">
                                    {slider.tagname}
                                  </p>
                                  <h2 className="ss-mega-title">
                                    {/* Making someone feel <br />
                                pretty is an art */}
                                    {slider.hedding}
                                  </h2>
                                  <p className="ss-sub-title xs-hide">
                                    {slider.sub_hedding}
                                  </p>
                                  <div className="ss-btnWrap">
                                    <span className="btn btn-primary">
                                      {slider.button_name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            {/* <SwiperSlide>
              <div className="slide">
                <div className="slideshow-wrap">
                  <picture>
                    <source
                      media="(max-width:767px)"
                      srcSet="/assets/images/slideshow/demo1-banner2-mbl.jpg"
                      width={1150}
                      height={800}
                    />
                    <img
                      className="blur-up lazyload"
                      src="/assets/images/slideshow/demo1-banner2.jpg"
                      alt="slideshow"
                      title
                      width={1920}
                      height={795}
                    />
                  </picture>
                  <div className="container">
                    <div className="slideshow-content slideshow-overlay middle-right">
                      <div className="slideshow-content-in">
                        <div className="wrap-caption style1">
                          <h2 className="ss-mega-title">
                            Spread Positive <br />
                            Energy With
                            <br /> Ew Shopping
                          </h2>
                          <p className="ss-sub-title xs-hide">
                            The must-have closet essential women wardrobe for
                            the year
                          </p>
                          <div className="ss-btnWrap d-flex-justify-start">
                            <a className="btn btn-primary" href="">
                              Explore Now!
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide">
                <div className="slideshow-wrap">
                  <picture>
                    <source
                      media="(max-width:767px)"
                      srcSet="/assets/images/slideshow/demo1-banner3-mbl.jpg"
                      width={1150}
                      height={800}
                    />
                    <img
                      className="blur-up lazyload"
                      src="/assets/images/slideshow/demo1-banner3.jpg"
                      alt="slideshow"
                      title
                      width={1920}
                      height={795}
                    />
                  </picture>
                  <div className="container">
                    <div className="slideshow-content slideshow-overlay middle-right">
                      <div className="slideshow-content-in">
                        <div className="wrap-caption style1">
                          <h2 className="ss-mega-title">
                            Design Your Next <br />
                            Favourite Wear
                          </h2>
                          <p className="ss-sub-title xs-hide">
                            The outfit that blend elegance and style for your
                            casual wear
                          </p>
                          <div className="ss-btnWrap">
                            <a className="btn btn-primary" href="">
                              Shop now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default HomeBanner;
