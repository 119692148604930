import React from 'react'

const TermsSale = () => {
  return (
    <>

      <div className="page-header text-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
              <div className="page-title">
                <h1>Terms Sale</h1>
              </div>
              <div className="breadcrumbs"><a to="/" title="Back to the home page">Home</a><span className="title"><i className="icon anm anm-angle-right-l" />Pages</span><span className="main-title fw-bold"><i className="icon anm anm-angle-right-l" />Information
                Page</span></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="text-content">
          <h3 style={{ color: "red" }}>Elderwise India Pvt Ltd</h3>

          <h3 style={{ textAlign: "center" }}>ALL JOBS</h3>


          <p style={{ textAlign: "justify" }}> Access to and use of www.EwShopping.com and the products and service available through the website are subject to the following terms, conditions and notices (“Terms of Service”). By browsing through these Terms of Service and using the services provided by our website (www.EwShopping.com), you agree to all Terms of Service along with the Privacy Policy on our website, which may be updated by us from time to time. Please check this page regularly to take notice of any changes we may have made to the Terms of Service.</p>
          <b style={{ textAlign: "center" }}>Introduction</b>
          <p>The domain name www.EwShopping.com is a site operated by EwShopping as the best online shopping site with our registered office EwShopping, Ewshopping, Rajendra Palace,
            New Delhi, Pin -110008</p>
          <b>Services</b>
          <p>EwShopping is an online retailer of apparel and lifestyle products offered at great values to the consumer. Membership allows customers to purchase a variety of products. Upon placing an order, www.EwShopping.com shall ship the product to you and be entitle to its payment for the service.</p>

          <h3>Third Party Websites and Content</h3>
          <p>Our website provides links for sharing our content on Facebook, twitter and other such third party website. These are only for sharing and/or listing purpose and we take no responsibility of the third party websites and/or their contents listed on our website (www.EwShopping.com) and disclaim all our liabilities arising out of any or all third party websites.</p>
          <p>We disclaim all liabilities and take no responsibility for the content that may be posted on such third party websites by the users of such websites in their personal capacity on any of the above mentioned links for sharing and/or listing purposes as well as any content and/or comments that may be posted by such user in their personal capacity on any official webpage of Beyoung on any social networking platform.</p>
          <b style={{ textAlign: "center" }}>Privacy</b>
          <p>Our Privacy Policy incorporated by reference in these Terms of Service, sets out how we will use personal information you provide to us. By using this Website, you agree to be bound by the Privacy Policy, and warrant that all data provided by you is accurate and up to date.</p>
          <h1>Exactness of the Product</h1>
          <p>The images of the items on the website are for illustrative purposes only. The actual color combination of the mobile cover and t-shirts may slightly vary as per the customer’s respective screen resolution.</p>
          <p>All sizes and measurements of items are approximate; however we do make every effort to ensure they are accurate as possible. We take all reasonable care to ensure that all details, descriptions and prices of items are as accurate as possible.

          </p>
          <p>Some mobile covers might not be completely covered from the functional keys side because of the curve display of the device.
          </p>
          <b style={{ textAlign: "center" }}>CUSTOMIZATION</b>
          <p><b>1. </b>In case of any size issues in the custom t-shirts there will be no replacement or refund under any circumstances. However, if the product is faulty or damaged from our end then the customer can proceed the return request.

          </p>
          <p><b>2. </b> The shoppers are requested to check the size guide before placing the order. </p>
          <p><b>3. </b>  The color of the custom t-shirt and custom mobile cover may slightly vary as per the customer's screen resolution. </p>
          <b>Pricing</b>
          <p>We ensure that all details of prices appearing on the website are accurate, however errors may occur. If we discover an error in the price of any goods which you have ordered, we will inform you of this as soon as possible. If we are unable to contact you we will treat the order as cancelled. If you cancel and you have already paid for the goods, you will receive a full refund.</p>

          <p>Additionally, prices for items may change from time to time without notice. However, these changes will not affect orders that have already been dispatched. The price of an item includes VAT (or similar sales tax) at the prevailing rate for which we are responsible as a seller. Please note that the prices listed on the website are only applicable for items purchased on the website and not through any other source.

          </p>
          <b>Payment</b>
          <p>Upon receiving your order we carry out a standard pre-authorization check on your payment card to ensure there are sufficient funds to fulfill the transaction. Goods will not be dispatched until this pre-authorization check has been completed. Your card will be debited once the order has been accepted. For any further payment related queries, please check our ,FAQs on Payment Mode.</p>
          <b>Delivery</b>
          <p>You will be given various options for delivery of items during the order process. The options available to you will vary depending on where you are ordering from. An estimated delivery time is displayed on the order summary page. On placing your order, you will receive an email containing a summary of the order and also the estimated delivery time to your location. Sometimes, delivery may take longer due to unforeseen circumstances. In such cases, we will proactively reach out to you by e-mail and SMS. However, we will not be able to compensate for any mental agony caused due to delay in delivery.</p>
          <h1 style={{ textAlign: "center" }}> RETURNS & REFUND</h1>
          <p>1. Please note that the single products/combo products are not subject to return once the product is delivered. However, in case the product has an issue or defect in the material or print, you can submit the return request. For more visit Return Policy</p>
          <p>2. If a product (apparel) has any size issue once the shipment is delivered, then there will be no replacement or refund in any circumstances. So ensure your size with respect to given Size Chart before placing order.</p>

          <b>INTELLECTUAL PROPERTY RIGHTS</b>
          <p>In case of any size issues in the custom t-shirts there will be no replacement or refund under any circumstances. However, if the product is faulty or damaged from our end then the customer can proceed the return request.</p>
          <b>LAW AND JURISDICTION</b>
          <p>These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts at Udaipur.</p>
          <b>INDEMNIFICATION</b>
          <p>You agree to indemnify, defend and hold harmless the Company, its directors, officers, employees, consultants, agents, and affiliates, from any and all third party claims, liability, damages or costs arising from your use of this website, your breach of these Terms of Service, or infringement of any intellectual property right.</p>

          <b>Violation & Termination</b>
          <p>You agree that the Company may, in its sole discretion and without prior notice, terminate your access to the website and block your future access if we determine that you have violated these Terms of Service or any other policies. If you or the Company terminates your use of any service, you shall still be liable to pay for any service that you have already ordered till the time of such termination.</p>
          <p>If you have any questions, comments or requests regarding our Terms of Service or the website please contact us at</p>
         


          <br />
          <h3 style={{ textAlign: "center" }}>OUR TOP CATEGORIES FOR ONLINE SHOPPING</h3>
          <p style={{ textAlign: "justify" }}>ELECTRONICS: Televisions | Speakers | Headphone & Earphones | Audio & Video | Winter Appliances | Washing Machines | Coolers | Fans | Grooming Appliances | Small Appliances</p>
          <p style={{ textAlign: "justify" }}>MOBILE, TABLETS: Mobile Cases & Covers | Mobile Phones | Tablets | Mobile Accessories | Wearables & Smartwatches | Tablet Accessories</p>
          <p style={{ textAlign: "justify" }}>Women's Fashion: Ethnic Wear | Footwear | Western Wear | Winter Wear | Handbags & Clutches | Eyewear | Fashion Jewellery | Watches | Precious Jewellery | Gold Coins & Bars | Fashion Accessories | Sunglasses</p>
          <p style={{ textAlign: "justify" }}> Mens Fashion: Foot Wear | Bags & Luggage | Clothing | Winterwear | Sports Wear | Mens Grooming | Eye Wear | Watches | Jewellery & Cufflinks | Accessories | Fragrances</p>
          <p style={{ textAlign: "justify" }}> Beauty Health: Beauty | Personal-Care-Grooming | Sexual Wellness | Food And Gourmet | Nutrition & Supplements | Health Monitoring Devices | General Wellness | Baby Care | Household Essentials</p>
          <p style={{ textAlign: "justify" }}>Toys Kids Fashion: Toys | Bicycles & Tricycles | Kids Footwear | Accessories | Kids Clothing | Girls Clothing | Boys Clothing | Baby Clothing | Baby Care | Stationery

          </p>
          <p style={{ textAlign: "justify" }}>Home Kitchen: Kitchen Appliances | Kitchenware | Home-Furnishing | Furniture | Home Decor | Home Improvement | Tools & Hardware | Pet Supplies

          </p>
          <p style={{ textAlign: "justify" }}>Sports Fitness Outdoor: Sports & Fitness | Get Fit At Home | Get Ready For Gym | Sports | Hiking & Outdoor</p>
          <p style={{ textAlign: "justify" }}>CAR MOTORBIKE: Automotive Accessories | Car & Vehicle Electronics | Car Audio Finder | Car Accessories | Tyre Inflators |Automotive | Car Fresheners | Air Purifiers & Ionizers | Biker Gear & Accessories | Biker Raincoats | Helmets & Accessories | Parts & Spares | Tyres & Alloys | Motorbike Batteries

          </p>
          <p style={{ textAlign: "justify" }}>Computers Gaming: Laptops | Printers & Inks | Networking Devices | Office Equipment | Storage | Computer Accessories | Desktops | Gaming | Computer Components | Software | Monitors | Top Laptop Brands

          </p>
          <p style={{ textAlign: "justify" }}>BOOKS MEDIA MUSIC: Top Exams| Browse By Genre | Online Education | Musical Instruments | Indian Instruments | Pa & Stage | Other Instruments | Hobbies New | E-Gift Cards | Physical Gift Cards | Digital Subscriptions | Movies & Tv Shows

          </p>
          <p style={{ textAlign: "justify" }}>Why Choose EwShopping as the Best Online Shopping Sites in India
          </p>
          <p style={{ textAlign: "justify" }}>Worldwide Shipping: Available as Standard or Express delivery EwShopping Promise: Products with this tag are quality checked, and shipped the same day from certified warehouses. So you get the right product, faster. Secure Payments: At EwShopping 100% Secure payment. If you have any issues, your money is immediately refunded. Sit back and enjoy your shopping. Free Return: Your money is yours! All refunds come with a question-asked guarantee for all orders. 24/7 Dedicated support: Need Help? Click Here. You can also talk to us on -------- to resolve your query.

          </p>
          <p style={{ textAlign: "justify" }}>India's Best Online Shopping Website for Fashion & Lifestyle
          </p>
          <p style={{ textAlign: "justify" }} >EwShopping is the Best Site for Online shopping in India when it comes to online clothes shopping for men, women, and kids at the lowest prices. The latest fashion trends and styles are showcased here, yes at your favorite online shopping destination. Well, if fashion is medicine then Beyoung is the chemist shop where you can do your online shopping for men and women with ease. EwShopping is without a doubt one of the best online shopping sites in India. The website is replete with the cool products that you always crave. Indeed, online shopping for women, men & kids at Beyoung is such hassle-free that in just a few clicks one can purchase whatever he or she wants. A one-stop destination for all your shopping needs, Beyoung caters to each taste and need for men’s clothing, Women's Clothing, and accessories.

          </p>
          <p style={{ textAlign: "justify" }}>EwShopping is the Best Site for Online shopping in India when it comes to online clothes shopping for men, women, and kids at the lowest prices. The latest fashion trends and styles are showcased here, yes at your favorite online shopping destination. Well, if fashion is medicine then Beyoung is the chemist shop where you can do your online shopping for men and women with ease. EwShopping is without a doubt one of the best online shopping sites in India. The website is replete with the cool products that you always crave. Indeed, online shopping for women, men & kids at Beyoung is such hassle-free that in just a few clicks one can purchase whatever he or she wants. A one-stop destination for all your shopping needs, Beyoung caters to each taste and need for men’s clothing, Women's Clothing, and accessories.

          </p>
          <br />
        </div>
      </div>
    </>
  )
}

export default TermsSale