import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setQuickViewProduct } from "../../redux/product/productSlice";
import { addTocart } from "../../redux/cart/CartSlice";

const SingleProduct = ({ product }) => {
  const dispatch = useDispatch();

  const addTocartPress = async (
    e,
    packIndex,
    optionIndex,
    pack,
    packOption
  ) => {
    e.preventDefault();

    const cart_form = {
      ProductName: product.name,
      ProductId: product._id,
      PackId: pack._id,
      PackOptionId: packOption._id,
      packSizeName: pack.packSizeName,
      thumbnail: pack.thumbnail,
      packSizeOptionName: packOption.packSizeOptionName,
      Mrp: Number(packOption.Mrp),
      Price: Number(packOption.Price),
      Product_total_Mrp: Number(packOption.Mrp) * 1,
      Product_total_Price: Number(packOption.Price) * 1,
      Product_total_Saving:
        Number(packOption.Mrp) * 1 - Number(packOption.Price) * 1,
      Discount: Number(packOption.Discount),
      superCategory: product.superCategory,
      category: product.category,
      SubCategory: product.SubCategory,
      CategoryTag: product.CategoryTag,
      cart_Quentity: 1,
      shopId: product.shopId,
      shopName: product.shopName,
      // shopId: "EWSiD",
      // shopName: "EWS",
    };

    const cart = await dispatch(addTocart(cart_form));
  };

  return (
    <>
      <div className="item col-item">
        {product.packSize.map((pack, packIndex) =>
          pack.PsPrime === true ? (
            <>
              <div className="product-box">
                <div className="product-image">
                  <Link
                    to={`/product-info/${product.slugUrl}`}
                    className="product-img rounded-0"
                  >
                    <img
                      className="primary rounded-0 blur-up lazyload"
                      data-src={pack.thumbnail}
                      src={pack.thumbnail}
                      alt="Product"
                      title="Product"
                      width={625}
                      height={808}
                    />

                    <img
                      className="hover rounded-0 blur-up lazyload"
                      data-src={pack.thumbnail}
                      src={pack.thumbnail}
                      alt="Product"
                      title="Product"
                      width={625}
                      height={808}
                    />
                  </Link>

                  {pack.PackSizeOptions.map((packOption, optionIndex) =>
                    packOption.PsnPrime === true ? (
                      <>
                        <div className="product-labels">
                          <span className="lbl on-sale">
                            {packOption.Discount}% Off
                          </span>
                        </div>

                        <div className="button-set style1">
                          {/*Cart Button*/}
                          <span
                            to="#addtocart-modal"
                            className="btn-icon addtocart add-to-cart-modal"
                            data-bs-toggle="modal"
                            data-bs-target="#addtocart_modal"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              dispatch(setQuickViewProduct(product));
                              addTocartPress(
                                e,
                                packIndex,
                                optionIndex,
                                pack,
                                packOption
                              );
                            }}
                          >
                            <span
                              className="icon-wrap d-flex-justify-center h-100 w-100"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Add to Cart"
                            >
                              <i className="icon anm anm-cart-l" />
                              <span className="text">Add to Cart</span>
                            </span>
                          </span>

                          <span
                            to="#quickview-modal"
                            className="btn-icon quickview quick-view-modal"
                            data-bs-toggle="modal"
                            data-bs-target="#quickview_modal"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              dispatch(setQuickViewProduct(product))
                            }
                          >
                            <span
                              className="icon-wrap d-flex-justify-center h-100 w-100"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Quick View"
                            >
                              <i className="icon anm anm-search-plus-l" />
                              <span className="text">Quick View</span>
                            </span>
                          </span>

                          <span
                            className="btn-icon wishlist"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Add To Wishlist"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <i className="icon anm anm-heart-l" />
                            <span className="text">Add To Wishlist</span>
                          </span>

                          <span
                            // to="/Compare"
                            className="btn-icon compare"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Add to Compare"
                          >
                            <i className="icon anm anm-random-r" />
                            <span className="text">Add to Compare</span>
                          </span>
                        </div>

                        <div className="product-availability rounded-5">
                          <div className="lh-1 d-flex justify-content-between">
                            <div className="text-sold">
                              Sold:
                              <strong className="text-primary ms-1">
                                {packOption.maximumQuantity}
                              </strong>
                            </div>
                            <div className="text-available">
                              Available:
                              <strong className="text-primary ms-1">
                                {packOption.availablestock}
                              </strong>
                            </div>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar w-100"
                              role="progressbar"
                              aria-valuenow={packOption.availablestock}
                              aria-valuemin={0}
                              aria-valuemax={packOption.maximumQuantity}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={optionIndex}></div>
                      </>
                    )
                  )}
                </div>
                {pack.PackSizeOptions.map((packOption, optionIndex) =>
                  packOption.PsnPrime === true ? (
                    <>
                      <div className="product-details text-left">
                        <div className="product-vendor">
                          {product.CategoryTag}
                        </div>

                        <div className="product-name limited-paragraph">
                          <Link to={`/product-info/${product.slugUrl}`}>
                            {product.name}
                          </Link>
                        </div>

                        <div className="product-price">
                          <span className="price old-price">
                            ₹{packOption.Mrp}
                          </span>
                          <span className="price">
                            <b>₹{packOption.Price}</b>
                          </span>
                        </div>

                        <div className="product-review">
                          <i className="icon anm anm-star-o" />
                          <i className="icon anm anm-star-o" />
                          <i className="icon anm anm-star-o" />
                          <i className="icon anm anm-star-o" />
                          <i className="icon anm anm-star-o" />
                          <span className="caption hidden ms-1">0 Reviews</span>
                        </div>

                        <p className="sort-desc hidden">
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          in some form, by injected humour, or randomised words
                          which don't look even slightly believable. If you are
                          going to use a passage...
                        </p>

                        {/* Variant */}
                        <ul className="variants-clr swatches">
                          {pack.PackSizeOptions.map((data, indexshow) => (
                            <li
                              className="swatch medium radius white"
                              key={indexshow}
                            >
                              {/* <img
                                src="assets/images/products/swatches/blue-red.jpg"
                                alt="image"
                                width={70}
                                height={70}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="black"
                              /> */}
                              <span
                                style={{
                                  color: "#000000",
                                  textAlign: "center",
                                  width: "5vh",
                                  height: "5vh",
                                }}
                              >
                                {data.packSizeOptionName}
                              </span>
                            </li>
                            // <h4 key={indexshow}>{data.packSizeOptionName}</h4>
                          ))}
                        </ul>

                        <div className="button-action hidden">
                          <div className="addtocart-btn">
                            <form
                              className="addtocart"
                              action="#"
                              method="post"
                            >
                              <Link
                                to="#addtocart-modal"
                                className="btn btn-md add-to-cart-modal"
                                data-bs-toggle="modal"
                                data-bs-target="#addtocart_modal"
                              >
                                <i className="icon anm anm-cart-l me-2" />
                                <span className="text">Add to Cart</span>
                              </Link>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div key={optionIndex}></div>
                    </>
                  )
                )}
              </div>
            </>
          ) : (
            <>
              <div key={packIndex}></div>
            </>
          )
        )}
      </div>
    </>
  );
};

export default SingleProduct;
