import React from "react";

const Mywishlist = () => {
  return (
    <>
      <div className="tab-pane fade h-100" id="wishlist">
        <div className="orders-card mt-0 h-100">
          <div className="top-sec d-flex-justify-center justify-content-between mb-4">
            <h2 className="mb-0">My Wishlist</h2>
          </div>
          <div className="table-bottom-brd table-responsive">
            <table className="table align-middle text-center order-table">
              <thead>
                <tr className="table-head text-nowrap">
                  <th scope="col">Image</th>
                  <th scope="col">Order Id</th>
                  <th scope="col">Product Details</th>
                  <th scope="col">Price</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      className="blur-up lazyload"
                      data-src="assets/images/products/product1-120x170.jpg"
                      src="assets/images/products/product1-120x170.jpg"
                      width={50}
                      alt="product"
                      title="product"
                    />
                  </td>
                  <td>
                    <span className="id">#12301</span>
                  </td>
                  <td>
                    <span className="name">Oxford Cuban Shirt</span>
                  </td>
                  <td>
                    <span className="price fw-500">$99.00</span>
                  </td>
                  <td>
                    <a to="cart-style1.html" className="btn btn-md text-nowrap">
                      Add to Cart
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      className="blur-up lazyload"
                      data-src="assets/images/products/product2-120x170.jpg"
                      src="assets/images/products/product2-120x170.jpg"
                      width={50}
                      alt="product"
                      title="product"
                    />
                  </td>
                  <td>
                    <span className="id">#12302</span>
                  </td>
                  <td>
                    <span className="name">Cuff Beanie Cap</span>
                  </td>
                  <td>
                    <span className="price fw-500">$128.00</span>
                  </td>
                  <td>
                    <a to="cart-style1.html" className="btn btn-md text-nowrap">
                      Add to Cart
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      className="blur-up lazyload"
                      data-src="assets/images/products/product3-120x170.jpg"
                      src="assets/images/products/product3-120x170.jpg"
                      width={50}
                      alt="product"
                      title="product"
                    />
                  </td>
                  <td>
                    <span className="id">#12303</span>
                  </td>
                  <td>
                    <span className="name">Flannel Collar Shirt</span>
                  </td>
                  <td>
                    <span className="price fw-500">$114.00</span>
                  </td>
                  <td>
                    <a to="cart-style1.html" className="btn btn-md text-nowrap">
                      Add to Cart
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      className="blur-up lazyload"
                      data-src="assets/images/products/product4-120x170.jpg"
                      src="assets/images/products/product4-120x170.jpg"
                      width={50}
                      alt="product"
                      title="product"
                    />
                  </td>
                  <td>
                    <span className="id">#12304</span>
                  </td>
                  <td>
                    <span className="name">Cotton Hooded Hoodie</span>
                  </td>
                  <td>
                    <span className="price fw-500">$198.00</span>
                  </td>
                  <td>
                    <a to="cart-style1.html" className="btn btn-md text-nowrap">
                      Add to Cart
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mywishlist;
