import React from 'react'

const BlogDetails = () => {
  return (
    <>
      <div id="page-content">
        <div className="page-header text-center">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                <div className="page-title">
                  <h1>Blog Details</h1>
                </div>
                <div className="breadcrumbs"><a to="index.html" title="Back to the home page">Home</a><span className="title"><i className="icon anm anm-angle-right-l" />Blog</span><span className="main-title fw-bold"><i className="icon anm anm-angle-right-l" />Blog
                  Details</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 blog-sidebar sidebar sidebar-bg">
              {/*Sidebar*/}
              <div className="sidebar-tags sidebar-sticky clearfix">
                {/*Category*/}
                <div className="sidebar-widget clearfix categories">
                  <div className="widget-title">
                    <h2>Category</h2>
                  </div>
                  <div className="widget-content">
                    <ul className="sidebar-categories scrollspy clearfix">
                      <li className="lvl-1 active"><a to="blog-grid-sidebar.html" className="site-nav lvl-1">Fashion <span className="count">4</span></a></li>
                      <li className="lvl-1"><a to="blog-grid-sidebar.html" className="site-nav lvl-1">Beauty
                        <span className="count">6</span></a></li>
                      <li className="lvl-1"><a to="blog-grid-sidebar.html" className="site-nav lvl-1">Accessories <span className="count">8</span></a></li>
                      <li className="lvl-1"><a to="blog-grid-sidebar.html" className="site-nav lvl-1">Trending <span className="count">12</span></a></li>
                      <li className="lvl-1"><a to="blog-grid-sidebar.html" className="site-nav lvl-1">Life
                        Style <span className="count">13</span></a></li>
                    </ul>
                  </div>
                </div>
                {/*End Category*/}
                {/*Archive*/}
                <div className="sidebar-widget clearfix categories">
                  <div className="widget-title">
                    <h2>Archive</h2>
                  </div>
                  <div className="widget-content">
                    <ul className="sidebar-categories scrollspy clearfix">
                      <li className="lvl-1"><a to="blog-grid-sidebar.html" className="site-nav lvl-1">24
                        February 2023</a></li>
                      <li className="lvl-1"><a to="blog-grid-sidebar.html" className="site-nav lvl-1">31
                        March 2023</a></li>
                      <li className="lvl-1"><a to="blog-grid-sidebar.html" className="site-nav lvl-1">10
                        April 2023</a></li>
                    </ul>
                  </div>
                </div>
                {/*End Archive*/}
                {/*Recent Posts*/}
                <div className="sidebar-widget clearfix">
                  <div className="widget-title">
                    <h2>Recent Posts</h2>
                  </div>
                  <div className="widget-content">
                    <div className="list list-sidebar-products">
                      <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                        <div className="mini-image"><a className="item-link" to="blog-details.html"><img className="featured-image blur-up lazyload" data-src="assets/images/blog/post-img1-100x.jpg" src="assets/images/blog/post-img1-100x.jpg" alt="blog" width={100} height={100} /></a></div>
                        <div className="ms-3 details">
                          <a className="item-title" to="blog-details.html">Gift ideas for
                            everyone</a>
                          <div className="item-meta opacity-75"><time dateTime="2023-01-02">Jan 02,
                            2023</time></div>
                        </div>
                      </div>
                      <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                        <div className="mini-image"><a className="item-link" to="blog-details.html"><img className="featured-image blur-up lazyload" data-src="assets/images/blog/post-img2-100x.jpg" src="assets/images/blog/post-img2-100x.jpg" alt="blog" width={100} height={100} /></a></div>
                        <div className="ms-3 details">
                          <a className="item-title" to="blog-details.html">Sales with best
                            collection</a>
                          <div className="item-meta opacity-75"><time dateTime="2023-01-24">Jan 24,
                            2023</time></div>
                        </div>
                      </div>
                      <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                        <div className="mini-image"><a className="item-link" to="blog-details.html"><img className="featured-image blur-up lazyload" data-src="assets/images/blog/post-img3-100x.jpg" src="assets/images/blog/post-img3-100x.jpg" alt="blog" width={100} height={100} /></a></div>
                        <div className="ms-3 details">
                          <a className="item-title" to="blog-details.html">Word in classical
                            literature</a>
                          <div className="item-meta opacity-75"><time dateTime="2023-02-14">Feb 14,
                            2023</time></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*End Recent Posts*/}
                {/*Popular Tags*/}
                <div className="sidebar-widget clearfix tags-clouds">
                  <div className="widget-title">
                    <h2>Popular Tags</h2>
                  </div>
                  <div className="widget-content">
                    <ul className="tags-list d-flex-wrap">
                      <li className="item"><a className="rounded-3" to="blog-grid-sidebar.html">Fashion</a>
                      </li>
                      <li className="item"><a className="rounded-3" to="blog-grid-sidebar.html">Shoes</a>
                      </li>
                      <li className="item"><a className="rounded-3" to="blog-grid-sidebar.html">Beauty</a>
                      </li>
                      <li className="item"><a className="rounded-3" to="blog-grid-sidebar.html">Accessories</a></li>
                      <li className="item"><a className="rounded-3" to="blog-grid-sidebar.html">Trending</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*End Popular Tags*/}
              </div>
              {/*End Sidebar*/}
            </div>
            {/* Blog Content*/}
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 main-col">
              <div className="blog-article">
                <div className="blog-img mb-3">
                  <img className="rounded-0 blur-up lazyload" data-src="assets/images/blog/post-img1-big.jpg" src="assets/images/blog/post-img1-big.jpg" alt="New shop collection our shop" width={1200} height={700} />
                </div>
                {/* Blog Content */}
                <div className="blog-content">
                  <h2 className="h1">Sales with best collection</h2>
                  <ul className="publish-detail d-flex-wrap">
                    <li><i className="icon anm anm-user-al" /> <span className="opacity-75 me-1">Posted
                      by:</span> User</li>
                    <li><i className="icon anm anm-clock-r" /> <time dateTime="2023-01-02">Jan 02,
                      2023</time></li>
                    <li><i className="icon anm anm-comments-l" /> <a to="#">2 Comments</a></li>
                    <li><i className="icon anm anm-tag-r" /><span className="opacity-75">Posted in</span><a className="ms-1" to="#">Accessories</a>,<a className="ms-1" to="#">Fashion</a>,<a className="ms-1" to="#">Bags</a></li>
                  </ul>
                  <hr />
                  <div className="content">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                      Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                      unknown printer took a galley of type and scrambled it to make a type specimen
                      book. It has survived not only five centuries, but also the leap into electronic
                      typesetting, remaining essentially unchanged.</p>
                    <p>It is a long established fact that a reader will be distracted by the readable
                      content of a page when looking at its layout. The point of using Lorem Ipsum is
                      that it has a more-or-less normal distribution of letters, as opposed to using
                      'Content here, content here', making it look like readable English. Many desktop
                      publishing packages and web page editors now use Lorem Ipsum as their default
                      model text, and a search for 'lorem ipsum' will uncover many web sites still in
                      their infancy.</p>
                    <blockquote>
                      <p>There are many variations of passages of Lorem Ipsum available, but the
                        majority have suffered alteration in some form, by injected humour, or
                        randomised words which don't look even slightly believable.</p>
                      <footer className="blockquote-footer m-0">Hema Alex</footer>
                    </blockquote>
                    <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for
                      those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et
                      Malorum" by Cicero are also reproduced in their exact original form, accompanied
                      by English versions from the 1914 translation by H. Rackham.</p>
                    <h3>Text Listing</h3>
                    <ul className="list-styled">
                      <li>Piece of classical Latin literature from 45 BC</li>
                      <li>Classical literature, discovered the undoubtable source.</li>
                      <li>Internet tend to repeat predefined chunks as necessary</li>
                    </ul>
                  </div>
                  <hr />
                  <div className="row blog-action d-flex-center justify-content-between">
                    <ul className="col-lg-6 tags-list d-flex-center">
                      <li className="item fw-600">Related Tags :</li>
                      <li className="item"><a className="text-link" to="blog-grid-sidebar.html">Fashion,</a>
                      </li>
                      <li className="item"><a className="text-link" to="blog-grid-sidebar.html">Shoes,</a>
                      </li>
                      <li className="item"><a className="text-link" to="blog-grid-sidebar.html">Accessories</a></li>
                    </ul>
                    <div className="col-lg-6 mt-2 mt-lg-0 social-sharing d-flex-center justify-content-lg-end">
                      <span className="sharing-lbl fw-600">Share :</span>
                      <a to="#" className="d-flex-center btn btn-link btn--share share-facebook" data-bs-toggle="tooltip" data-bs-placement="top" title="Share on Facebook"><i className="icon anm anm-facebook-f" /><span className="share-title d-none">Facebook</span></a>
                      <a to="#" className="d-flex-center btn btn-link btn--share share-facebook" data-bs-toggle="tooltip" data-bs-placement="top" title="Tweet on Twitter"><i className="icon anm anm-twitter" /><span className="share-title d-none">Twitter</span></a>
                      <a to="#" className="d-flex-center btn btn-link btn--share share-facebook" data-bs-toggle="tooltip" data-bs-placement="top" title="Pin on Pinterest"><i className="icon anm anm-pinterest-p" /><span className="share-title d-none">Pinterest</span></a>
                      <a to="#" className="d-flex-center btn btn-link btn--share share-facebook" data-bs-toggle="tooltip" data-bs-placement="top" title="Share on Instagram"><i className="icon anm anm-linkedin-in" /><span className="share-title d-none">Instagram</span></a>
                      <a to="#" className="d-flex-center btn btn-link btn--share share-facebook" data-bs-toggle="tooltip" data-bs-placement="top" title="Share by Email"><i className="icon anm anm-envelope-l" /><span className="share-title d-none">Email</span></a>
                    </div>
                  </div>
                  {/* Blog Nav */}
                  <div className="blog-nav d-flex-center justify-content-between mt-3">
                    <div className="nav-prev fs-14"><a to="#"><i className="align-middle me-2 icon anm anm-angle-left-r" /><span className="align-middle">Previous post</span></a></div>
                    <div className="nav-next fs-14"><a to="#"><span className="align-middle">Next
                      post</span><i className="align-middle ms-2 icon anm anm-angle-right-r" /></a></div>
                  </div>
                  {/* End Blog Nav */}
                  <hr />
                  <div className="author-bio">
                    <div className="author-image d-flex">
                      <a className="author-img" to="#"><img className="blur-up lazyload" data-src="assets/images/users/user-img3.jpg" src="assets/images/users/user-img3.jpg" alt="author" width={200} height={200} /></a>
                      <div className="author-info ms-4">
                        <h4 className="mb-2">Jhon Arthur</h4>
                        <p className="text-muted mb-2"><span className="postcount">234 posts</span><span className="postsince ms-2">Since 2023</span></p>
                        <p className="author-des">Hi there, I am a Hema blogger sharing Multipurpose
                          Template that will give you and your customers a smooth shopping
                          experience which can be used for various kinds of stores such as
                          fashion.</p>
                      </div>
                    </div>
                  </div>
                  {/* Blog Comments */}
                  <div className="blog-comment section">
                    <h2 className="mb-4">Comments (3)</h2>
                    <ol className="comments-list">
                      <li className="comments-items">
                        <div className="comments-item d-flex w-100">
                          <div className="flex-shrink-0 comment-img"><img className="blur-up lazyload" data-src="assets/images/users/user-img1.jpg" src="assets/images/users/user-img1.jpg" alt="comment" width={200} height={200} /></div>
                          <div className="flex-grow-1 comment-content">
                            <div className="comment-user d-flex-center justify-content-between">
                              <div className="comment-author fw-600">Alex Sort</div>
                              <div className="comment-date opacity-75"><time dateTime="2023-01-02">Jan 02, 2023</time></div>
                            </div>
                            <div className="comment-text my-2">It was popularised in the 1960s with
                              the release of Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software.</div>
                            <div className="comment-reply"><button type="button" className="text-link text-decoration-none"><i className="icon anm anm-reply me-2" />Reply</button></div>
                          </div>
                        </div>
                        <div className="comments-item d-flex w-100">
                          <div className="flex-shrink-0 comment-img"><img className="blur-up lazyload" data-src="assets/images/users/user-img2.jpg" src="assets/images/users/user-img2.jpg" alt="comment" width={200} height={200} /></div>
                          <div className="flex-grow-1 comment-content">
                            <div className="comment-user d-flex-center justify-content-between">
                              <div className="comment-author fw-600">Admin</div>
                              <div className="comment-date opacity-75"><time dateTime="2023-01-03">Jan 03, 2023</time></div>
                            </div>
                            <div className="comment-text my-2">It was popularised in the 1960s with
                              the release of Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software.</div>
                            <div className="comment-reply"><button type="button" className="text-link text-decoration-none"><i className="icon anm anm-reply me-2" />Reply</button></div>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </div>
                  {/* End Blog Comments */}
                  {/* Blog Comments Form */}
                  <div className="formFeilds comment-form form-vertical">
                    <form method="post" action="#">
                      <h2 className="mb-3">Leave a Comment</h2>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label htmlFor="commentName" className="d-none">Name</label>
                            <input type="text" id="commentName" name="contact[name]" placeholder="Name" defaultValue required />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="form-group">
                            <label htmlFor="commentEmail" className="d-none">Email</label>
                            <input type="email" id="commentEmail" name="contact[email]" placeholder="Email" defaultValue required />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <label htmlFor="commentMessage" className="d-none">Message</label>
                            <textarea rows={5} id="commentMessage" name="contact[body]" placeholder="Write Comment" required defaultValue={""} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <input type="submit" className="btn btn-lg" defaultValue="Post comment" />
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* End Blog Comments Form */}
                </div>
                {/* End Blog Content */}
              </div>
            </div>
            {/*End Blog Content*/}
          </div>
        </div>
        {/*End Main Content*/}
      </div>


    </>
  )
}

export default BlogDetails