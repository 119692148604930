import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import HomeBanner from "./home/HomeBanner";
import CallUsAnyTime from "./home/CallUsAnyTime";
import HomeSmallBanner from "./home/HomeSmallBanner";
import ShopByCategory from "./home/ShopByCategory";
import TopProducts from "./home/TopProducts";
import ParallaxBanner from "./home/ParallaxBanner";
import HappyCustomer from "./home/HappyCustomer";
import LatestPost from "./home/LatestPost";
import { setcurrentOrder } from "../redux/order/OrderSlice";
import { set_all_amount_data } from "../redux/cart/CartSlice";
import CallUsAnyTimeMobile from "./home/CallUsAnyTimeMobile";
import ShopBySubCategory from "./home/ShopBySubCategory";

const Home = () => {
  const { threeSliderTotal } = useSelector((store) => store.slider);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setcurrentOrder([]));
    dispatch(set_all_amount_data(""));
  }, []);

  return (
    <>
      <HomeBanner />
      <CallUsAnyTime />
      <ShopBySubCategory />
      <HomeSmallBanner />
      <TopProducts />
      <CallUsAnyTimeMobile />
      {/* <ParallaxBanner /> */}
      {/* <HappyCustomer /> */}
      {/* <LatestPost /> */}
    </>
  );
};

export default Home;
