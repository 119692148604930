import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  add_Address,
  set_checkout_process_steps,
  update_Address,
} from "../../../redux/athentication/Athentication";

const AddAddressCheckout = () => {
  const dispatch = useDispatch();
  const { loginData, current_address } = useSelector(
    (state) => state.Athentication
  );

  const [Mobile, setMobile] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [HNo, setHNo] = useState("");
  const [HNoerror, setHNoError] = useState("");
  const [Area, setArea] = useState("");
  const [Areaerror, setAreaError] = useState("");
  const [sub_locality, setsub_locality] = useState("");
  const [sub_localityerror, setsub_localityError] = useState("");
  const [locality, setlocality] = useState("");
  const [localityerror, setlocalityError] = useState("");
  const [LandMark, setLandMark] = useState("");
  const [LandMarkerror, setLandMarkError] = useState("");
  const [City, setCity] = useState("");
  const [Cityerror, setCityError] = useState("");
  const [State, setState] = useState("");
  const [Stateerror, setStateError] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Pincodeerror, setPincodeError] = useState("");
  const [Type, setType] = useState("Home");

  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (btnLoading === true) {
      if (Mobile.length !== 10) {
        setMobileError("Required");
      } else {
        setMobileError("");
      }

      if (HNo === "") {
        setHNoError("Required");
      } else {
        setHNoError("");
      }
      if (Area === "") {
        setAreaError("Required");
      } else {
        setAreaError("");
      }
      if (sub_locality === "") {
        setsub_localityError("Required");
      } else {
        setsub_localityError("");
      }
      if (locality === "") {
        setlocalityError("Required");
      } else {
        setlocalityError("");
      }

      if (City === "") {
        setCityError("Required");
      } else {
        setCityError("");
      }
      if (State === "") {
        setStateError("Required");
      } else {
        setStateError("");
      }
      if (Pincode.length !== 6) {
        setPincodeError("Required");
      } else {
        setPincodeError("");
      }
    }
  }, [
    btnLoading,
    Mobile,
    HNo,
    Area,
    sub_locality,
    locality,
    City,
    State,
    Pincode,
  ]);

  const add_address = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (Mobile.length !== 10) {
      setMobileError("Required");
    } else {
      setMobileError("");
    }

    if (HNo === "") {
      setHNoError("Required");
    } else {
      setHNoError("");
    }
    if (Area === "") {
      setAreaError("Required");
    } else {
      setAreaError("");
    }
    if (sub_locality === "") {
      setsub_localityError("Required");
    } else {
      setsub_localityError("");
    }
    if (locality === "") {
      setlocalityError("Required");
    } else {
      setlocalityError("");
    }

    if (City === "") {
      setCityError("Required");
    } else {
      setCityError("");
    }
    if (State === "") {
      setStateError("Required");
    } else {
      setStateError("");
    }
    if (Pincode.length !== 6) {
      setPincodeError("Required");
    } else {
      setPincodeError("");
    }

    if (
      Pincode.length === 6 &&
      Mobile.length === 10 &&
      HNo !== "" &&
      Area !== "" &&
      locality !== "" &&
      City !== "" &&
      State !== "" &&
      sub_locality !== ""
    ) {
      const form_data = {
        Name: loginData.Name,
        Alternative_Mobile: loginData.Mobile,
        ClientId: loginData._id,
        HNo: HNo,
        Area: Area,
        sub_locality: sub_locality,
        locality: locality,
        LandMark: LandMark,
        City: City,
        State: State,
        Pincode: Pincode,
        Type: Type,
        LandMark: LandMark,
        prime: true,
      };

      const add_address_post = await dispatch(add_Address(form_data));

      if (add_address_post.payload.success) {
        const client = add_address_post.payload.client;
        const data_address = client.Addresses;

        await dispatch(update_Address(data_address));

        setMobile("");
        setHNo("");
        setArea("");
        setsub_locality("");
        setlocality("");
        setLandMark("");
        setCity("");
        setState("");
        setPincode("");
        setBtnLoading(false);
      }
    }
  };

  return (
    <>
      <div className="tab-pane active" id="steps2">
        {current_address === "" ? (
          <>
            <div className="block shipping-address mb-4">
              <div className="block-content">
                <h3 className="title mb-3">Shipping Address</h3>
                <fieldset>
                  <div className="row">
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="firstname" className="form-label">
                        Name
                      </label>
                      <input
                        name="firstname"
                        id="firstname"
                        type="text"
                        className="form-control"
                        value={loginData.Name}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="lastname" className="form-label">
                        Mobile <span className="required">*</span>
                      </label>
                      <input
                        name="lastname"
                        id="lastname"
                        type="text"
                        className={
                          MobileError !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                        minLength={10}
                        maxLength={10}
                        value={Mobile}
                        onChange={(e) =>
                          setMobile(e.target.value.replace(/\D/g, ""))
                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        Alternative Mobile
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="Alternative Mobile"
                        value={loginData.Mobile}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        HNo <span className="required">*</span>
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="Street address"
                        value={HNo}
                        onChange={(e) => setHNo(e.target.value)}
                        className={
                          HNoerror !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        Area <span className="required">*</span>
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="Area"
                        value={Area}
                        onChange={(e) => setArea(e.target.value)}
                        className={
                          Areaerror !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                      />
                    </div>
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        sub locality <span className="required">*</span>
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="sub locality"
                        value={sub_locality}
                        onChange={(e) => setsub_locality(e.target.value)}
                        className={
                          sub_localityerror !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        locality <span className="required">*</span>
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="locality"
                        value={locality}
                        onChange={(e) => setlocality(e.target.value)}
                        className={
                          localityerror !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                      />
                    </div>
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        LandMark
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="LandMark"
                        value={LandMark}
                        onChange={(e) => setLandMark(e.target.value)}
                        className={
                          LandMarkerror !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        City <span className="required">*</span>
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="City"
                        value={City}
                        onChange={(e) => setCity(e.target.value)}
                        className={
                          Cityerror !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                      />
                    </div>
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        State <span className="required">*</span>
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="State"
                        value={State}
                        onChange={(e) => setState(e.target.value)}
                        className={
                          Stateerror !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                      <label htmlFor="address-1" className="form-label">
                        Pincode <span className="required">*</span>
                      </label>
                      <input
                        name="address_1"
                        id="address-1"
                        type="text"
                        placeholder="Pincode"
                        value={Pincode}
                        minLength={6}
                        maxLength={6}
                        onChange={(e) =>
                          setPincode(e.target.value.replace(/\D/g, ""))
                        }
                        className={
                          Pincodeerror !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                      />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div
                      className="form-group col-md-12 col-lg-12 mb-0"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="checkout-tearm customCheckbox"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="checkout_tearm"
                          name="tearm"
                          type="radio"
                          defaultValue="checkout tearm"
                          value={"Home"}
                          checked={Type === "Home"}
                          onChange={() => setType("Home")}
                        />
                        <label htmlFor="checkout_tearm">Home</label>
                      </div>
                      <div
                        className="checkout-tearm customCheckbox"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="checkout_tearm"
                          name="tearm"
                          type="radio"
                          defaultValue="checkout tearm"
                          value={Type}
                          checked={Type === "Office"}
                          onChange={() => setType("Office")}
                        />
                        <label htmlFor="checkout_tearm">Office</label>
                      </div>
                      <div
                        // className="checkout-tearm customCheckbox"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <input
                          id="checkout_tearm"
                          name="tearm"
                          type="radio"
                          defaultValue="checkout tearm"
                          value={Type}
                          onChange={() => setType("Others")}
                          checked={Type === "Others"}
                        />
                        <label htmlFor="checkout_tearm">Others</label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <button
                      style={{
                        visibility: "hidden",
                      }}
                    >
                      {/* Back to USER AUTHENTICATion */}
                    </button>
                    <button
                      type="button"
                      onClick={(e) => add_address(e)}
                      className="btn btn-secondary btnPrevious me-1"
                    >
                      Add address
                    </button>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-secondary btnPrevious me-1"
              >
                Back to USER AUTHENTICATion
              </button>
              <button type="button" className="btn btn-primary btnNext ms-1">
                Next Order Summary
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="address-book-section">
              <div className="row g-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1">
                <div className="address-select-box active">
                  <div className="address-box bg-block">
                    <div className="top d-flex-justify-center justify-content-between mb-3">
                      <h5 className="m-0">{current_address.Name}</h5>
                      <span className="product-labels start-auto end-0">
                        <span className="lbl pr-label1">
                          {current_address.Type}
                        </span>
                      </span>
                    </div>
                    <div className="middle">
                      <div className="address mb-2 text-muted">
                        <address className="m-0">
                          {current_address.HNo},{current_address.Area},
                          {current_address.sub_locality}, <br />
                          {current_address.locality},{current_address.LandMark},
                          {current_address.City}, <br />
                          {current_address.State},{current_address.country}-
                          {current_address.Pincode}
                        </address>
                      </div>
                      <div className="number">
                        <p>
                          Mobile:{" "}
                          <a to={`tel:${current_address.Mobile}`}>
                            {" "}
                            {current_address.Mobile}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-4 mb-md-0">
              <div className=" h-100 mt-4">
                <div className="block-content">
                  <button
                    type="submit"
                    name="Continue"
                    onClick={() => dispatch(set_checkout_process_steps(2))}
                    className="btn btn-secondary ms-2 btnNext"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddAddressCheckout;
