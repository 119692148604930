import { configureStore } from "@reduxjs/toolkit";
import Athenticationreducer from "./athentication/Athentication";
import superCategoryReducer from "./superCategory/superCategorySlice";
import categoryReducer from "./category/categorySlice";
import subCategoriesReducer from "./subCategory/subCategorySlice";
import brandReducer from "./brand/brandSlice";
import productReducer from "./product/productSlice";
import categorytagReducer from "./categoryTags/CategoryTagsSlice";
import cartReducer from "./cart/CartSlice";
import Orderreducer from "./order/OrderSlice";
import sliderreducer from "./sliders/SlidersSlice";

export const store = configureStore({
  reducer: {
    superCategory: superCategoryReducer,
    category: categoryReducer,
    subCategories: subCategoriesReducer,
    brand: brandReducer,
    categorytag: categorytagReducer,
    product: productReducer,
    cart: cartReducer,
    Athentication: Athenticationreducer,
    Order: Orderreducer,
    slider: sliderreducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});