
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  subcategorytotal: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal"))
    : [],
  subcategoryShow: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter((data) => data.status === true)
    : [],
  clothing_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter((data) => data.status === true && data.superCategory === "Clothing")
    : [],
  womens_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter((data) => data.status === true && data.category === "Women")
    : [],
  mens_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter((data) => data.status === true && data.category === "Men")
    : [],
  kids_subcategory: localStorage.getItem("subcategorytotal")
    ? JSON.parse(localStorage.getItem("subcategorytotal")).filter((data) => data.status === true && data.category === "Kids")
    : [],
  isSubCategoryAvailable: localStorage.getItem("categorytotal")
    ? true
    : false,
  subCategoryLoading: true,
};

export const getSubCategory = createAsyncThunk(
  "SubCategory/getSubCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcategory/all`;
      const resp = await axios(url);

      return resp.data.subcategory;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);


export const validateSlugUrl = createAsyncThunk(
  "subcategory/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/subcategory/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);



const SubCategorySlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder

      .addCase(getSubCategory.pending, (state) => {
        state.subCategoryLoading = true;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        state.subcategorytotal = action.payload;
        state.subcategoryShow = state.subcategorytotal.filter((data) => data.status === true);
        state.clothing_subcategory = state.subcategoryShow.filter((data) => data.superCategory === "Clothing");
        

        localStorage.setItem("subcategorytotal", JSON.stringify(state.subcategorytotal));

        state.subCategoryLoading = false;
        state.isSubCategoryAvailable = true;
      })
      .addCase(getSubCategory.rejected, (state) => {
        state.subCategoryLoading = true;
      });



  },

});
export const { } = SubCategorySlice.actions;
export default SubCategorySlice.reducer;