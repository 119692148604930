import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const AddressBook = () => {
  const { user_address, current_address } = useSelector(
    (state) => state.Athentication
  );
  return (
    <>
      <div className="tab-pane fade h-100" id="address">
        <div className="address-card mt-0 h-100">
          <div className="top-sec d-flex-justify-center justify-content-between mb-4">
            <h2 className="mb-0">Address Book</h2>
            {/* <button
              type="button"
              className="btn btn-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#addNewModal"
            >
              <i className="icon anm anm-plus-r me-1" /> Add New
            </button> */}
            {/* New Address Modal */}
            <div
              className="modal fade"
              id="addNewModal"
              tabIndex={-1}
              aria-labelledby="addNewModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="modal-title" id="addNewModalLabel">
                      Address details
                    </h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form className="add-address-from" method="post" action="#">
                      <div className="form-row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                        <div className="form-group">
                          <label htmlFor="new-name" className="d-none">
                            Name
                          </label>
                          <input
                            name="name"
                            placeholder="Name"
                            defaultValue
                            id="new-name"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="new-type" className="d-none">
                            Address type
                            <span className="required">*</span>
                          </label>
                          <select name="new_type_id" id="new-type">
                            <option value>Select Address type</option>
                            <option value="home">Home</option>
                            <option value="office">Office</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="new-company" className="d-none">
                            Company
                          </label>
                          <input
                            name="company"
                            placeholder="Company"
                            defaultValue
                            id="new-company"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="new-apartment" className="d-none">
                            Apartment
                            <span className="required">*</span>
                          </label>
                          <input
                            name="apartment"
                            placeholder="Apartment"
                            defaultValue
                            id="new-apartment"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="new-street-address"
                            className="d-none"
                          >
                            Street Address <span className="required">*</span>
                          </label>
                          <input
                            name="street_address"
                            placeholder="Street Address"
                            defaultValue
                            id="new-street-address"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="new-city" className="d-none">
                            City <span className="required">*</span>
                          </label>
                          <input
                            name="city"
                            placeholder="City"
                            defaultValue
                            id="new-city"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="new-postcode" className="d-none">
                            Post Code
                            <span className="required">*</span>
                          </label>
                          <input
                            name="postcode"
                            placeholder="Post Code"
                            defaultValue
                            id="new-postcode"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="new-telephone" className="d-none">
                            Telephone
                            <span className="required">*</span>
                          </label>
                          <input
                            name="telephone"
                            placeholder="Telephone"
                            defaultValue
                            id="new-telephone"
                            type="tel"
                          />
                        </div>
                        <div className="form-group mb-md-0">
                          <label htmlFor="new-zone" className="d-none">
                            Region / State
                            <span className="required">*</span>
                          </label>
                          <select name="new_zone_id" id="new-zone">
                            <option value>Select Region / State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                          </select>
                        </div>
                        <div className="form-group mb-0">
                          <label htmlFor="new-country" className="d-none">
                            Country
                            <span className="required">*</span>
                          </label>
                          <select name="new_country_id" id="new-country">
                            <option value>Select Country</option>
                            <option value="AI" label="Anguilla">
                              Anguilla
                            </option>
                            <option value="AG" label="Antigua and Barbuda">
                              Antigua and Barbuda
                            </option>
                            <option value="AR" label="Argentina">
                              Argentina
                            </option>
                            <option value="AW" label="Aruba">
                              Aruba
                            </option>
                            <option value="BS" label="Bahamas">
                              Bahamas
                            </option>
                            <option value="BB" label="Barbados">
                              Barbados
                            </option>
                            <option value="BZ" label="Belize">
                              Belize
                            </option>
                            <option value="BM" label="Bermuda">
                              Bermuda
                            </option>
                            <option value="BO" label="Bolivia">
                              Bolivia
                            </option>
                            <option value="BR" label="Brazil">
                              Brazil
                            </option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-primary m-0">
                      <span>Add Address</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* End New Address Modal */}
          </div>
          <div className="address-book-section">
            <div className="row g-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1">
              {user_address &&
                user_address.map((address, index) => (
                  <div className="address-select-box active" key={index}>
                    <div className="address-box bg-block">
                      <div className="top d-flex-justify-center justify-content-between mb-3">
                        <h5 className="m-0">{address.Name}</h5>
                        <span className="product-labels start-auto end-0">
                          <span className="lbl pr-label1">{address.Type}</span>
                        </span>
                      </div>
                      <div className="middle">
                      <div className="address mb-2 text-muted">
                        <address className="m-0">
                          {address.HNo},{address.Area},
                          {address.sub_locality}, <br />
                          {address.locality},{address.LandMark},
                          {address.City}, <br />
                          {address.State},{address.country}-
                          {address.Pincode}
                        </address>
                      </div>
                      <div className="number">
                        <p>
                          Mobile:{" "}
                          <a to={`tel:${address.Mobile}`}> {address.Mobile}</a>
                        </p>
                      </div>
                    </div>
                      {/* <div className="bottom d-flex-justify-center justify-content-between">
                    <button
                      type="button"
                      className="bottom-btn btn btn-gray btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#addEditModal"
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="bottom-btn btn btn-gray btn-sm"
                    >
                      Remove
                    </button>
                  </div> */}
                    </div>
                  </div>
                ))}
            </div>
            {/* Edit Address Modal */}
            <div
              className="modal fade"
              id="addEditModal"
              tabIndex={-1}
              aria-labelledby="addEditModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="modal-title" id="addEditModalLabel">
                      Edit Address details
                    </h2>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form
                      className="edit-address-from"
                      method="post"
                      action="#"
                    >
                      <div className="form-row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                        <div className="form-group">
                          <label htmlFor="edit-name" className="d-none">
                            Name
                          </label>
                          <input
                            name="name"
                            placeholder="Name"
                            defaultValue
                            id="edit-name"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="edit-type" className="d-none">
                            Address type
                            <span className="required">*</span>
                          </label>
                          <select name="edit_type_id" id="edit-type">
                            <option value>Select Address type</option>
                            <option value="home">Home</option>
                            <option value="office">Office</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="edit-company" className="d-none">
                            Company
                          </label>
                          <input
                            name="company"
                            placeholder="Company"
                            defaultValue
                            id="edit-company"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="edit-apartment" className="d-none">
                            Apartment
                            <span className="required">*</span>
                          </label>
                          <input
                            name="apartment"
                            placeholder="Apartment"
                            defaultValue
                            id="edit-apartment"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="edit-street-address"
                            className="d-none"
                          >
                            Street Address <span className="required">*</span>
                          </label>
                          <input
                            name="street_address"
                            placeholder="Street Address"
                            defaultValue
                            id="edit-street-address"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="edit-city" className="d-none">
                            City <span className="required">*</span>
                          </label>
                          <input
                            name="city"
                            placeholder="City"
                            defaultValue
                            id="edit-city"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="edit-postcode" className="d-none">
                            Post Code
                            <span className="required">*</span>
                          </label>
                          <input
                            name="postcode"
                            placeholder="Post Code"
                            defaultValue
                            id="edit-postcode"
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="edit-telephone" className="d-none">
                            Telephone
                            <span className="required">*</span>
                          </label>
                          <input
                            name="telephone"
                            placeholder="Telephone"
                            defaultValue
                            id="edit-telephone"
                            type="tel"
                          />
                        </div>
                        <div className="form-group mb-md-0">
                          <label htmlFor="edit-zone" className="d-none">
                            Region / State
                            <span className="required">*</span>
                          </label>
                          <select name="edit_zone_id" id="edit-zone">
                            <option value>Select Region / State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                          </select>
                        </div>
                        <div className="form-group mb-0">
                          <label htmlFor="edit-country" className="d-none">
                            Country
                            <span className="required">*</span>
                          </label>
                          <select name="edit_country_id" id="edit-country">
                            <option value>Select Country</option>
                            <option value="AI" label="Anguilla">
                              Anguilla
                            </option>
                            <option value="AG" label="Antigua and Barbuda">
                              Antigua and Barbuda
                            </option>
                            <option value="AR" label="Argentina">
                              Argentina
                            </option>
                            <option value="AW" label="Aruba">
                              Aruba
                            </option>
                            <option value="BS" label="Bahamas">
                              Bahamas
                            </option>
                            <option value="BB" label="Barbados">
                              Barbados
                            </option>
                            <option value="BZ" label="Belize">
                              Belize
                            </option>
                            <option value="BM" label="Bermuda">
                              Bermuda
                            </option>
                            <option value="BO" label="Bolivia">
                              Bolivia
                            </option>
                            <option value="BR" label="Brazil">
                              Brazil
                            </option>
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="submit" className="btn btn-primary m-0">
                      <span>Save Address</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* End Edit Address Modal */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressBook;
