import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { Slider, Spin } from "antd";
import SingleProduct from "../../component/product/SingleProduct";

const SearchCategoryTag = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [product_loading, setproduct_Loading] = useState(true);
  const [filtered_products, setfiltered_products] = useState([]);
  const [allproductLoading, setallproductLoading] = useState(true);
  const [current_category_tag, setcurrent_category_tag] = useState("");

  useEffect(() => {
    if (params.cat_tag) {
      const getProducts = async () => {
        const url = `${Baseurl}/api/v1/categorytag/slugurl/${params.cat_tag}`;
        const category_tag_Respo = await axios.get(url);
        if (category_tag_Respo.data.success) {
          const category_tag_data = category_tag_Respo.data.ctegoryTags;
          setcurrent_category_tag(category_tag_data);
          const product_url = `${Baseurl}/api/v1/product/all-products-by-category-tag-limit/${category_tag_data.name}`;
          const product_Respo = await axios.get(product_url);
          if (product_Respo.data.success) {
            const product_data = product_Respo.data.products;
            setfiltered_products(product_data);
            setproduct_Loading(false);
          }
        }
      };
      getProducts();
    }
  }, [params.cat_tag]);
  useEffect(() => {
    if (current_category_tag !== "") {
      const getProducts = async () => {
        const product_url = `${Baseurl}/api/v1/product/all-products-by-category-tag/${current_category_tag.name}`;
        const product_Respo = await axios.get(product_url);
        if (product_Respo.data.success) {
          const product_data = product_Respo.data.products;
          setfiltered_products(product_data);
          setallproductLoading(false);
        }
      };
      getProducts();
    }
  }, [current_category_tag]);

  return (
    <>
      {product_loading === true ? (
        <>
          <div className="example">
            <Spin />
          </div>
        </>
      ) : (
        <>
          <section className="section product-slider tab-slider-product">
            <div className="container">
              <div className="section-header d-none">
                <h2>Special Offers</h2>
                <p>Browse the huge variety of our best seller</p>
              </div>
              <div className="tabs-listing">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
                  <div className="grid-products grid-view-items">
                    <div className="row col-row product-options row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2">
                      {filtered_products &&
                        filtered_products.map((product, index) => (
                          <SingleProduct key={index} product={product} />
                        ))}

                      {product_loading === false &&
                        allproductLoading === true && (
                          <div className="example_new">
                            <Spin />
                          </div>
                        )}
                      {product_loading === false &&
                        allproductLoading === true && (
                          <div className="example_new">
                            <Spin />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SearchCategoryTag;
