import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  set_checkout_authentication_status,
  set_checkout_process_steps,
  signin,
  userRegistation,
  verifyUserEmail,
} from "../../redux/athentication/Athentication";
import { Link, useNavigate } from "react-router-dom";
const Registation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { otp, mob } = useSelector((state) => state.Athentication);

  const [email, setemail] = useState("");
  const [confirm_email, setconfirm_email] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [Name, setName] = useState("");
  const [errorName, setErrorName] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);

  useEffect(() => {
    if (buttonPress === true) {
      if (confirm_email === "") {
        setErrorEmail("Requied");
      } else {
        setErrorEmail("");
      }
      if (Name === "") {
        setErrorName("Requied");
      } else {
        setErrorName("");
      }
    }
  }, [buttonPress, confirm_email, Name]);

  const emailVerifyChange = async (e) => {
    const value = e.target.value;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setemail(value);
    if (value.length > 0) {
      if (value.match(mailformat)) {
        let responce = await dispatch(verifyUserEmail(value));

        if (responce.payload.success) {
          setErrorEmail("Requied");
        } else {
          setErrorEmail("");
          setconfirm_email(value);
        }
      }
    } else {
      setErrorEmail("Requied");
    }
  };

  const user_register_press = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    if (confirm_email === "") {
      setErrorEmail("Requied");
    } else {
      setErrorEmail("");
    }
    if (Name === "") {
      setErrorName("Requied");
    } else {
      setErrorName("");
    }

    if (confirm_email !== "" && Name !== "" && mob !== "") {
      const formdata = {
        Name: Name.charAt(0).toUpperCase() + Name.slice(1).toLowerCase(),
        Email: confirm_email,
        Mobile: mob,
      };

      const user_registation = await dispatch(userRegistation(formdata));

      if (user_registation.payload.success) {
      }
      const user_data = user_registation.payload.client;
      dispatch(
        signin({
          ...user_data,
          isAuth: true,
        })
      );
      dispatch(set_checkout_authentication_status(0));
      navigate("/");
    }
  };
  return (
    <>
      <div className="container">
        <div className="login-register pt-2">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <div className="inner h-100">
                <form method="post" action="#" className="customer-form">
                  <h2 className="text-center fs-4 mb-4">
                    Register here if you are a new customer
                  </h2>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label htmlFor="CustomerUsername" className="d-blok">
                        Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="customer[Username]"
                        placeholder="Enter your Name"
                        autofocus
                        className={
                          errorName !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-12">
                      <label htmlFor="CustomerEmail" className="d-block">
                        Email <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        name="customer[email]"
                        placeholder="Enter your Email"
                        autofocus
                        className={
                          errorEmail !== ""
                            ? "form-control error_show"
                            : "form-control"
                        }
                        value={email}
                        onChange={(e) => emailVerifyChange(e)}
                      />
                    </div>
                    <div className="form-group col-12">
                      <label htmlFor="CustomerPassword" className="d-none">
                        Mobile Number <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        name="customer[password]"
                        placeholder="Password"
                        id="CustomerPassword"
                        disabled={true}
                        value={mob}
                        contentEditable={false}
                      />
                    </div>

                    {/* <div className="form-group col-12">
                      <div className="login-remember-forgot d-flex justify-content-between align-items-center">
                        <div className="agree-check customCheckbox">
                          <input
                            id="agree"
                            name="agree"
                            type="checkbox"
                            defaultValue="agree"
                            required
                          />
                          <label htmlFor="agree">
                            {" "}
                            I agree to terms &amp; Policy.
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group col-12 mb-0">
                      <input
                        type="submit"
                        className="btn btn-primary btn-lg w-100"
                        defaultValue="Register"
                        onClick={(e) => user_register_press(e)}
                      />
                    </div>
                  </div>
                  <div className="login-divide">
                    <span className="login-divide-text">OR</span>
                  </div>
                  <p className="text-center fs-6 text-muted mb-3">
                    Or Sign up with
                  </p>
                  <div className="login-social d-flex-justify-center">
                    <span className="social-link facebook rounded-5 d-flex-justify-center">
                      <i className="icon anm anm-facebook-f me-2" /> Facebook
                    </span>
                    <span className="social-link google rounded-5 d-flex-justify-center">
                      <i className="icon anm anm-google-plus-g me-2" /> Google
                    </span>
                    <span className="social-link twitter rounded-5 d-flex-justify-center">
                      <i className="icon anm anm-twitter me-2" /> Twitter
                    </span>
                  </div>
                  <div className="login-signup-text mt-4 mb-2 fs-6 text-center text-muted">
                    Already have an account?{" "}
                    <span className="btn-link">Login now</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registation;
