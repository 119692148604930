import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>

            <div>
                <div className="page-header text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                                <div className="page-title">
                                    <h1>Privacy Policy</h1>
                                </div>
                                <div className="breadcrumbs"><a to="/" title="Back to the home page">Home</a><span className="title"><i className="icon anm anm-angle-right-l" />Pages</span><span className="main-title fw-bold"><i className="icon anm anm-angle-right-l" />Information
                                    Page</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="text-content">
                        {/* <h3>Privacy Policy</h3> */}
                        <ul className="list-dot">
                            {/* <li className="mb-1">What We Collect</li>
                            <li className="mb-1">Non-Personal InformationWhat</li>
                            <li className="mb-1">How We Use Cookies Advertising</li>
                            <li>We Do With The Information We Gather</li>
                            <li className="mb-1">Links To Other Websites</li>
                            <li className="mb-1">Payment Iinformation</li>
                            <li className="mb-1">Security</li>
                            <li>Controlling Your Personal Information</li>
                            <li>Contacting Us</li> */}
                        </ul>
                        <h3 style={{ color: "red" }}>Elderwise India Pvt Ltd</h3>

                        <h3>Best online shopping website in India</h3>
                        <ul className="">
                            <li className="mb-1 list-decimal" style={{textAlign:"justify"}}>EwShopping branch of (Elderwise India Pvt Ltd) the best online shopping website in India, respects the privacy of its users accessing its website at www.EwShopping.com (“Website”). For the purposes of this privacy policy (“Privacy Policy”) and wherever the context so requires “you”, “your”, “yourself”, “user” shall mean any natural or legal person who accesses or uses the Website and in the event that a natural person is representing a business entity, reference to such terms shall encompass a reference to the business entity.</li>
                            <li className="mb-1 list-decimal" style={{textAlign:"justify"}}>The Company has followed this Privacy Policy, in compliance with the provisions of applicable laws, to inform you of what records we gather from you through the Website, how we accumulate the information, how we may additionally use it and the steps we take to protect it.</li>
                        </ul>
                        <h3>WHAT WE COLLECT</h3>
                        <p  style={{textAlign:"justify"}}> <b>3. </b>Personal Information: We may additionally gather the following records</p>
                        <ol className="">
                            <li className="mb-1"> (a) Your user name along with your password.</li>
                            <li className="mb-1">(b) Your name</li>
                            <li className="mb-1">(c) Your address </li>
                            <li className="mb-1">(d) Your telephone number.</li>
                            <li className="mb-1">(e) Your e-mail address or other contact formation</li>
                            <li>(f) Your date of birth.</li>
                            <li>(g) Your gender.</li>
                        </ol>
                        <ul className="">
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>4. </b>Information regarding your transactions on the Website, (including sales or purchase history).</li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>5. </b>Your financial information such as bank account information or credit card or debit card or other payment instrument details including one-time password.</li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>6. </b>Internet protocol address</li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>7. </b>Such Personal Information may be collected in various ways which include during the path of you availing sure services offered on the Website. Such instances include however are not being restricted to making an online purchase, participating in any online survey or contest, communicating with Company’s customer service by phone, email, or otherwise, or posting user reviews on the items available on the Website.</li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>8. </b>Registering as a user on the Website</li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>9. </b>Registering as a seller on the Website</li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>10. </b>Availing of certain services offered on the Website. Such instances include but are not limited to making an offer for sale, online purchase, participating in any online survey or contest, communicating with Snapdeal's customer service by phone, email, or otherwise or posting user reviews on the items available on the Website, or</li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>11. </b>Otherwise doing business on the Website or otherwise dealing with any EwShopping entity. We may receive Personal Information about you from third parties, such as social media services, commercially available sources, and business partners. If you access a Website through a social media service or connect service on Website to a social media service, the information we collect may include your user name associated with that social media service, any information or content the social media service has the right to share with us, such as your profile picture, email address or friends list, and any information you have made public in connection with that social media service. When you access the Website or otherwise deal with any EwShopping entity through social media services or when you connect any Website to social media services, you are authorizing EwShopping to collect, store, and use and retain such information and content in accordance with this Privacy Policy.</li>
                            <h3>Non Personal Information</h3>


                            <li className="mb-1 "> <b>12. </b> The company may also collect information other than Personal Information from you through the Website when you visit and/or use the Website. Such information may be stored in server logs. This Non-Personal Information would not assist Company to identify you personally. This Non-Personal Information may include:
                            </li>
                            <li className="mb-1 "> <b>13. </b> Your geographic location.
                            </li>
                            <li className="mb-1 "> <b>14. </b> Details of your telecom service provider or internet service provider.
                            </li>
                            <li className="mb-1 "> <b>15. </b> The type of browser internet explorer, firefox, opera, google chrome, etc.
                            </li>
                            <li className="mb-1 "> <b>16. </b> The operating system of your system, device, and the Website you last visited before visiting the Website.
                            </li>
                            <li className="mb-1 "> <b>17. </b> The duration of your stay on the Website is also stored in the session along with the date and time of your access, Non-Personal Information is collected through various ways such as the use of cookies. </li>


                            <h3>What We Do With The Information We Gather</h3>
                            <li className="mb-1 "> <b>18. </b> We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
                            </li>
                            <li className="mb-1 "> <b>19. </b> Internal record keeping
                            </li>
                            <li className="mb-1 "> <b>20. </b> We may use the information to improve our products and services.

                            </li>
                            <li className="mb-1 "> <b>21. </b> We may periodically send promotional emails about new products, special offers, or other information

                            </li>
                            <li className="mb-1 "> <b>22. </b> which we think you may find interesting using the email address which you have provided with your permission.
                            </li>
                            <li className="mb-1 "> <b>23. </b> From time to time, we may also use your information to contact you for market research purposes. We may contact you by email or post. We may use the information to customize the website according to your interests.</li>


                            <h3>How We Use Cookies</h3>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>24. </b> A cookie is a small file that asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes, and dislikes by gathering and remembering information about your preferences.
                            </li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>25. </b> We use traffic log cookies to identify which pages are being used.This helps us analyze data about webpage traffic and improve our website in order to tailor it to customer needs.We only use this information for statistical analysis purposes and then the data is removed from the system.
                            </li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>26. </b> Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not.A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.

                            </li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>27. </b> You can choose to accept or decline cookies.Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.This may prevent you from taking full advantage of the website.

                            </li>
                            <h3>Advertising</h3>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>28. </b>
                                The Company uses some third parties to administer a limited set of advertisements on our Website and portals.During this process, none of your Personal Information is leaked.However, aggregate profile information, such as user community, may be used in the selection of advertising to make sure that it has relevance to the user.On some banner ads, an embedded pixel may be present, and while it does not associate with a cookie or other personal profile information, it may return session connection information that allows advertisers to better determine how many individual users have clicked on the ad banner.We do not disclose your Personal Information to third parties for their marketing and advertising purposes.
                            </li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>29. </b> Links To Other Websites
                            </li>
                            <li className="mb-1 " style={{textAlign:"justify"}}> <b>30. </b> The Website may provide third party information and links to other websites that are not affiliates of or operated or controlled by the Company including but not limited to payment gateways or social networking websites.The Company is not responsible for any form of transmission, whatsoever, received by you from any third party website and accordingly does not make any representations concerning the privacy practices or other policies of such third party websites.Under no circumstances shall the Company be deemed to control or guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, cookies, messages, or other materials available on such websites.Any User Information provided by you to such third party websites shall be governed in accordance with the privacy policies of such websites and it is recommended that you review the privacy policy of such websites prior to using such websites.
                            </li>
                            <h3>Payment Information</h3>
                            <li className="mb-1 "> <b>31. </b>
                                We do not store your credit card or net banking details.All monetary processing and transactions are done through a secure third - party payment gateway provided by EBS which is a leader in online payment systems and is verified by Verisign(TM).
                            </li>

                            <h3>Security</h3>
                            <li className="mb-1 "> <b>32. </b>
                                We are the cheap best online shopping site in India, committed to ensuring that your information is secure.In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
                            </li>
                            <h3>Controlling Your</h3>
                            <li className="mb-1 "> <b>33. </b>
                                We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
                            </li>
                            <li className="mb-1 "> <b>34. </b> You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable.If you would like a copy of the information held on you please write to support @ewshopping.com
                            </li>
                            <li className="mb-1 "> <b>35. </b> If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address.We will promptly correct any information found to be incorrect.

                            </li>
                            <li className="mb-1 "  style={{textAlign:"justify"}}> <b>36. </b> You may request to delete your account and the personal information associated with it by sending an email to support @ewshopping.com.We will retain records of any successful purchases you have made with us as far as we are legally required to do so.
                            </li>
                            <h3>Contacting</h3>
                            <li className="mb-1 "> <b>37. </b>
                                If there are any questions regarding this privacy policy you may contact us using the information below:
                            </li>
                            <h3>Controlling Your</h3>
                            <li className="mb-1 "> <b>38. </b>
                                Company Name: EwShopping

                            </li>
                            <li className="mb-1 "> <b>39. </b> Address:
                            </li>
                            <li className="mb-1 "> <b>40. </b> Telephone No:

                            </li>
                            <li className="mb-1 "> <b>41. </b> E-Mail ID: info@ewshopping.com
                            </li>

                        </ul>


                        <h3 style={{ textAlign: "center" }}>OUR TOP CATEGORIES FOR ONLINE SHOPPING</h3>
                        <p style={{textAlign:"justify"}}>ELECTRONICS: Televisions | Speakers | Headphone & Earphones | Audio & Video | Winter Appliances | Washing Machines | Coolers | Fans | Grooming Appliances | Small Appliances</p>
                        <p style={{textAlign:"justify"}}>MOBILE, TABLETS: Mobile Cases & Covers | Mobile Phones | Tablets | Mobile Accessories | Wearables & Smartwatches | Tablet Accessories</p>
                        <p style={{textAlign:"justify"}}>Women's Fashion: Ethnic Wear | Footwear | Western Wear | Winter Wear | Handbags & Clutches | Eyewear | Fashion Jewellery | Watches | Precious Jewellery | Gold Coins & Bars | Fashion Accessories | Sunglasses</p>
                        <p style={{textAlign:"justify"}}> Mens Fashion: Foot Wear | Bags & Luggage | Clothing | Winterwear | Sports Wear | Mens Grooming | Eye Wear | Watches | Jewellery & Cufflinks | Accessories | Fragrances</p>
                        <p style={{textAlign:"justify"}}> Beauty Health: Beauty | Personal-Care-Grooming | Sexual Wellness | Food And Gourmet | Nutrition & Supplements | Health Monitoring Devices | General Wellness | Baby Care | Household Essentials</p>
                        <p style={{textAlign:"justify"}}>Toys Kids Fashion: Toys | Bicycles & Tricycles | Kids Footwear | Accessories | Kids Clothing | Girls Clothing | Boys Clothing | Baby Clothing | Baby Care | Stationery

                        </p>
                        <p style={{textAlign:"justify"}}>Home Kitchen: Kitchen Appliances | Kitchenware | Home-Furnishing | Furniture | Home Decor | Home Improvement | Tools & Hardware | Pet Supplies

                        </p>
                        <p style={{textAlign:"justify"}}>Sports Fitness Outdoor: Sports & Fitness | Get Fit At Home | Get Ready For Gym | Sports | Hiking & Outdoor</p>
                        <p style={{textAlign:"justify"}}>CAR MOTORBIKE: Automotive Accessories | Car & Vehicle Electronics | Car Audio Finder | Car Accessories | Tyre Inflators |Automotive | Car Fresheners | Air Purifiers & Ionizers | Biker Gear & Accessories | Biker Raincoats | Helmets & Accessories | Parts & Spares | Tyres & Alloys | Motorbike Batteries

                        </p>
                        <p style={{textAlign:"justify"}}>Computers Gaming: Laptops | Printers & Inks | Networking Devices | Office Equipment | Storage | Computer Accessories | Desktops | Gaming | Computer Components | Software | Monitors | Top Laptop Brands

                        </p>
                        <p style={{textAlign:"justify"}}>BOOKS MEDIA MUSIC: Top Exams| Browse By Genre | Online Education | Musical Instruments | Indian Instruments | Pa & Stage | Other Instruments | Hobbies New | E-Gift Cards | Physical Gift Cards | Digital Subscriptions | Movies & Tv Shows

                        </p>
                        <p style={{textAlign:"justify"}}>Why Choose EwShopping as the Best Online Shopping Sites in India
                        </p>
                        <p style={{textAlign:"justify"}}>Worldwide Shipping: Available as Standard or Express delivery EwShopping Promise: Products with this tag are quality checked, and shipped the same day from certified warehouses. So you get the right product, faster. Secure Payments: At EwShopping 100% Secure payment. If you have any issues, your money is immediately refunded. Sit back and enjoy your shopping. Free Return: Your money is yours! All refunds come with a question-asked guarantee for all orders. 24/7 Dedicated support: Need Help? Click Here. You can also talk to us on -------- to resolve your query.

                        </p>
                        <p style={{textAlign:"justify"}}>India's Best Online Shopping Website for Fashion & Lifestyle
                        </p>
                        <p style={{textAlign:"justify"}} >EwShopping is the Best Site for Online shopping in India when it comes to online clothes shopping for men, women, and kids at the lowest prices. The latest fashion trends and styles are showcased here, yes at your favorite online shopping destination. Well, if fashion is medicine then Beyoung is the chemist shop where you can do your online shopping for men and women with ease. EwShopping is without a doubt one of the best online shopping sites in India. The website is replete with the cool products that you always crave. Indeed, online shopping for women, men & kids at Beyoung is such hassle-free that in just a few clicks one can purchase whatever he or she wants. A one-stop destination for all your shopping needs, Beyoung caters to each taste and need for men’s clothing, Women's Clothing, and accessories.

                        </p>
                        <p style={{textAlign:"justify"}}>EwShopping is the Best Site for Online shopping in India when it comes to online clothes shopping for men, women, and kids at the lowest prices. The latest fashion trends and styles are showcased here, yes at your favorite online shopping destination. Well, if fashion is medicine then Beyoung is the chemist shop where you can do your online shopping for men and women with ease. EwShopping is without a doubt one of the best online shopping sites in India. The website is replete with the cool products that you always crave. Indeed, online shopping for women, men & kids at Beyoung is such hassle-free that in just a few clicks one can purchase whatever he or she wants. A one-stop destination for all your shopping needs, Beyoung caters to each taste and need for men’s clothing, Women's Clothing, and accessories.

                        </p>
<br/>                    </div>
                </div>
            </div>

        </>
    )
}

export default PrivacyPolicy