import React from 'react'
import { Link } from "react-router-dom";
import TopProducts from '../home/TopProducts';


const ProductInfo = () => {
    return (
        <>

            <div id="page-content">
                <div className="page-header text-center">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="breadcrumbs"><Link to="/" title="Back to the home page">Home</Link><span className="main-title fw-bold"><i className="icon anm anm-angle-right-l" />Product
                                </span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="product-single">
                        <div className="row">
                            <div className="col-lg-9 col-md-12 col-sm-12 col-12 product-layout-img-info mb-4 mb-lg-0">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 product-layout-img mb-4 mb-md-0">
                                        <div className="product-details-img product-horizontal-style">
                                            <div className="zoompro-wrap">
                                                <div className="zoompro-span"><img id="zoompro" className="zoompro" src="assets/images/products/product5.jpg" data-zoom-image="assets/images/products/product5.jpg" alt="product" width={625} height={808} /></div>
                                                <div className="product-labels"><span className="lbl pr-label1">New</span><span className="lbl on-sale">Sale</span></div>
                                                <div className="product-buttons">
                                                    <a to="#productVideo-modal" className="btn popup-video" data-bs-toggle="modal" data-bs-target="#productVideo_modal">
                                                        <span className="icon-wrap d-flex-justify-center h-100 w-100" data-bs-toggle="tooltip" data-bs-placement="top" title="Watch Video"><i className="icon anm anm-video-r" /></span>
                                                    </a>
                                                    <a to="#" className="btn prlightbox" data-bs-toggle="tooltip" data-bs-placement="top" title="Zoom Image"><i className="icon anm anm-expand-l-arrows" /></a>
                                                </div>
                                            </div>
                                            <div className="product-thumb product-horizontal-thumb mt-3">
                                                <div id="gallery" className="product-thumb-horizontal">
                                                    <a data-image="assets/images/products/product5.jpg" data-zoom-image="assets/images/products/product5.jpg" className="slick-slide slick-cloned active">
                                                        <img className="blur-up lazyload" data-src="assets/images/products/product5.jpg" src="assets/images/products/product5.jpg" alt="product" width={625} height={808} />
                                                    </a>
                                                    <a data-image="assets/images/products/product5-1.jpg" data-zoom-image="assets/images/products/product5-1.jpg" className="slick-slide slick-cloned">
                                                        <img className="blur-up lazyload" data-src="assets/images/products/product5-1.jpg" src="assets/images/products/product5-1.jpg" alt="product" width={625} height={808} />
                                                    </a>
                                                    <a data-image="assets/images/products/product5-2.jpg" data-zoom-image="assets/images/products/product5-2.jpg" className="slick-slide slick-cloned">
                                                        <img className="blur-up lazyload" data-src="assets/images/products/product5-2.jpg" src="assets/images/products/product5-2.jpg" alt="product" width={625} height={808} />
                                                    </a>
                                                    <a data-image="assets/images/products/product5-3.jpg" data-zoom-image="assets/images/products/product5-3.jpg" className="slick-slide slick-cloned">
                                                        <img className="blur-up lazyload" data-src="assets/images/products/product5-3.jpg" src="assets/images/products/product5-3.jpg" alt="product" width={625} height={808} />
                                                    </a>
                                                    <a data-image="assets/images/products/product5-4.jpg" data-zoom-image="assets/images/products/product5-4.jpg" className="slick-slide slick-cloned">
                                                        <img className="blur-up lazyload" data-src="assets/images/products/product5-4.jpg" src="assets/images/products/product5-4.jpg" alt="product" width={625} height={808} />
                                                    </a>
                                                    <a data-image="assets/images/products/product5-5.jpg" data-zoom-image="assets/images/products/product5-5.jpg" className="slick-slide slick-cloned">
                                                        <img className="blur-up lazyload" data-src="assets/images/products/product5-5.jpg" src="assets/images/products/product5-5.jpg" alt="product" width={625} height={808} />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="lightboximages">
                                                <a to="assets/images/products/product5.jpg" data-size="1000x1280" />
                                                <a to="assets/images/products/product5-1.jpg" data-size="1000x1280" />
                                                <a to="assets/images/products/product5-2.jpg" data-size="1000x1280" />
                                                <a to="assets/images/products/product5-3.jpg" data-size="1000x1280" />
                                                <a to="assets/images/products/product5-4.jpg" data-size="1000x1280" />
                                                <a to="assets/images/products/product5-5.jpg" data-size="1000x1280" />
                                            </div>
                                        </div>
                                        <div className="social-sharing d-flex-center justify-content-center mt-3 mt-md-4 lh-lg">
                                            <span className="sharing-lbl fw-600">Share :</span>
                                            <a to="#" className="d-flex-center btn btn-link btn--share share-facebook"><i className="icon anm anm-facebook-f" /><span className="share-title">Facebook</span></a>
                                            <a to="#" className="d-flex-center btn btn-link btn--share share-twitter"><i className="icon anm anm-twitter" /><span className="share-title">Tweet</span></a>
                                            <a to="#" className="d-flex-center btn btn-link btn--share share-pinterest"><i className="icon anm anm-pinterest-p" /> <span className="share-title">Pin
                                                it</span></a>
                                            <a to="#" className="d-flex-center btn btn-link btn--share share-linkedin"><i className="icon anm anm-linkedin-in" /><span className="share-title">Linkedin</span></a>
                                            <a to="#" className="d-flex-center btn btn-link btn--share share-email"><i className="icon anm anm-envelope-l" /><span className="share-title">Email</span></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 product-layout-info">
                                        <div className="product-single-meta">
                                            <h2 className="product-main-title">Product Layout Style6</h2>
                                            <div className="product-review d-flex-center mb-3">
                                                <div className="reviewStar d-flex-center"><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /><span className="caption ms-2">24
                                                    Reviews</span></div>
                                                <a className="reviewLink d-flex-center" to="#reviews">Write a Review</a>
                                            </div>
                                            <div className="product-price d-flex-center my-3">
                                                <span className="price old-price"> ₹689.00</span><span className="price"> ₹599.00</span>
                                            </div>
                                            <div className="orderMsg d-flex-center mb-3" data-user={23} data-time={24}>
                                                <i className="icon anm anm-medapps" />
                                                <p className="m-0"><strong className="items">8</strong> Sold in last <strong className="time">14</strong> hours</p>
                                            </div>
                                            <div className="product-info">
                                                <p className="product-stock d-flex">Availability:
                                                    <span className="pro-stockLbl ps-0">
                                                        <span className="d-flex-center stockLbl instock text-uppercase">In
                                                            stock</span>
                                                    </span>
                                                </p>
                                                <p className="product-vendor">Vendor:<span className="text"><a to="#">Levis</a></span></p>
                                                <p className="product-type">Product Type:<span className="text">Dress</span></p>
                                                <p className="product-sku">SKU:<span className="text">RF10456</span></p>
                                            </div>
                                        </div>
                                        <div className="countdown-text style1 d-flex-wrap mt-3 mb-1 justify-content-center flex-column">
                                            <label>Hurry up! Sales End In </label>
                                            <div className="prcountdown" data-countdown="2028/10/01" />
                                        </div>
                                        <form method="post" action="#" className="product-form product-form-border hidedropdown">
                                            <div className="product-swatches-option">
                                                <div className="product-item swatches-image swatch-rectangle-style w-100 mb-4 swatch-0 option1" data-option-index={0}>
                                                    <label className="label d-flex align-items-center">Color:<span className="slVariant ms-1 fw-bold">Blue</span></label>
                                                    <ul className="variants-clr swatches d-flex-center pt-1 clearfix">
                                                        <li className="swatch radius available blue0 active"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="Blue">Blue</span></li>
                                                        <li className="swatch radius available black0"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="Black">Black</span></li>
                                                        <li className="swatch radius available purple0"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="Purple">Purple</span></li>
                                                        <li className="swatch radius available green0"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="Green">Green</span></li>
                                                        <li className="swatch radius soldout yellow0"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="Yellow">Yellow</span></li>
                                                    </ul>
                                                </div>
                                                <div className="product-item swatches-size swatch-rectangle-style w-100 mb-4 swatch-1 option2" data-option-index={1}>
                                                    <label className="label d-flex align-items-center">Size:<span className="slVariant ms-1 fw-bold">S</span> <a to="#sizechart-modal" className="text-link sizelink text-muted size-chart-modal" data-bs-toggle="modal" data-bs-target="#sizechart_modal">Size
                                                        Guide</a></label>
                                                    <ul className="variants-size size-swatches d-flex-center pt-1 clearfix">
                                                        <li className="swatch radius soldout"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="XS">XS</span></li>
                                                        <li className="swatch radius available active"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="S">S</span></li>
                                                        <li className="swatch radius available"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="M">M</span></li>
                                                        <li className="swatch radius available"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="L">L</span></li>
                                                        <li className="swatch radius available"><span className="swatchLbl" data-bs-toggle="tooltip" data-bs-placement="top" title="XL">XL</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-action w-100 d-flex-wrap my-3 my-md-4">
                                                <div className="product-form-quantity d-flex-center">
                                                    <div className="qtyField">
                                                        <a className="qtyBtn minus" to="#;"><i className="icon anm anm-minus-r" /></a>
                                                        <input type="text" name="quantity" defaultValue={1} className="product-form-input qty" />
                                                        <a className="qtyBtn plus" to="#;"><i className="icon anm anm-plus-r" /></a>
                                                    </div>
                                                </div>
                                                <div className="product-form-submit addcart fl-1 ms-3">
                                                    <button type="submit" name="add" className="btn btn-secondary product-form-cart-submit">
                                                        <span>Add to cart</span>
                                                    </button>
                                                </div>
                                                <div className="product-form-submit buyit d-flex w-100 mt-3">
                                                    <button type="submit" className="btn btn-primary proceed-to-checkout"><span>Buy it
                                                        now</span></button>
                                                    <a to="" className="btn btn-secondary wishlist-submit w-auto p-3 ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Add to Wishlist">
                                                        <i className="icon anm anm-heart-l fs-6" />
                                                    </a>
                                                </div>
                                            </div>
                                            <p className="infolinks d-flex-center">
                                                <a className="text-link compare" to=""><i className="icon anm anm-sync-ar me-2" /> <span>Add to
                                                    Compare</span></a>
                                                <a to="#shippingInfo-modal" className="text-link shippingInfo" data-bs-toggle="modal" data-bs-target="#shippingInfo_modal"><i className="icon anm anm-paper-l-plane me-2" /> <span>Delivery &amp;
                                                    Returns</span></a>
                                                <a to="#productInquiry-modal" className="text-link emaillink me-0" data-bs-toggle="modal" data-bs-target="#productInquiry_modal"><i className="icon anm anm-question-cil me-2" /> <span>Ask A
                                                    Question</span></a>
                                            </p>
                                        </form>
                                        <div className="userViewMsg featureText" data-user={20} data-time={11000}><i className="icon anm anm-eye-r" /><b className="uersView">21</b> People are Looking
                                            for this Product</div>
                                        <div className="shippingMsg featureText"><i className="icon anm anm-clock-r" />Estimated
                                            Delivery Between <b id="fromDate">Wed, May 1</b> and <b id="toDate">Tue, May
                                                7</b>.</div>
                                        <div className="freeShipMsg featureText" data-price={199}><i className="icon anm anm-truck-r" />Spent <b className="freeShip"><span className="money" data-currency-usd=" ₹199.00" data-currency="USD"> ₹199.00</span></b> More for Free shipping</div>
                                    </div>
                                </div>
                                <div className="upsell-bundle">
                                    <hr />
                                    <h4 className="mb-3">Buy The Look - Upsell Bundle</h4>
                                    <form method="post" action="#" id="usbForm" className="usbFrm">
                                        <div className="usbGroup d-md-flex">
                                            <div className="usbImgWrap mb-3 mb-md-0">
                                                <div className="row g-4 mb-3">
                                                    <div className="col usbImgCall usbImg1">
                                                        <a className="usb-link" to="#"><img className="usbImg1" src="assets/images/products/product1-120x170.jpg" alt="product" width={100} /></a>
                                                    </div>
                                                    <div className="col usbImgCall usbImg2">
                                                        <a className="usb-link" to="#"><img className="usbImg2" src="assets/images/products/product2-120x170.jpg" alt="product" width={100} /></a>
                                                    </div>
                                                    <div className="col usbImgCall usbImg3">
                                                        <a className="usb-link" to="#"><img className="usbImg3" src="assets/images/products/product3-120x170.jpg" alt="product" width={100} /></a>
                                                    </div>
                                                    <div className="col usbImgCall usbImg4">
                                                        <a className="usb-link" to="#"><img className="usbImg4" src="assets/images/products/product4-120x170.jpg" alt="product" width={100} /></a>
                                                    </div>
                                                </div>
                                                <div className="usbRow d-flex align-items-center mb-2 active">
                                                    <div className="customCheckbox clearfix">
                                                        <input id="usbCk1" name="usbCk1" type="checkbox" defaultChecked="checked" />
                                                        <label htmlFor="usbCk1"><b>This Item</b>: Oxford Cuban Shirt</label>
                                                    </div>
                                                    <b className="price mx-2"> ₹195.00</b>
                                                    <select className="usbVrt" name="items[][id]" id="PrSelect-195" data-section={195}>
                                                        <option selected="selected" value={195} data-price={195}>Red / S
                                                        </option>
                                                        <option value={195} data-price={195}>Black / S</option>
                                                        <option value={195} data-price={195}>Pink / S</option>
                                                        <option value={195} data-price={195}>Red / M</option>
                                                        <option value={195} data-price={195}>Blue / M</option>
                                                        <option value={195} data-price={195}>Pink / M</option>
                                                        <option value={195} data-price={195}>Red / L</option>
                                                        <option value={195} data-price={195}>Blue / L</option>
                                                        <option value={195} data-price={195}>Black / L</option>
                                                    </select>
                                                </div>
                                                <div className="usbRow d-flex align-items-center mb-2 active">
                                                    <div className="customCheckbox clearfix">
                                                        <input id="usbCk2" name="usbCk2" type="checkbox" defaultChecked="checked" />
                                                        <label htmlFor="usbCk2">Cuff Beanie Cap</label>
                                                    </div>
                                                    <b className="price mx-2"> ₹172.00</b>
                                                    <select className="usbVrt" name="items[][id]" id="PrSelect-172" data-section={172}>
                                                        <option selected="selected" value={39320359731260} data-price={172}>
                                                            Pink / S</option>
                                                        <option value={172} data-price={172}>Red / M</option>
                                                        <option value={172} data-price={172}>Black / L</option>
                                                    </select>
                                                </div>
                                                <div className="usbRow d-flex align-items-center mb-2 active">
                                                    <div className="customCheckbox clearfix">
                                                        <input id="usbCk3" name="usbCk3" type="checkbox" />
                                                        <label htmlFor="usbCk3">Ankle Casual Pants</label>
                                                    </div>
                                                    <b className="price mx-2"> ₹128.00</b>
                                                    <select className="usbVrt" name="items[][id]" id="PrSelect-128" data-section={128}>
                                                        <option selected="selected" value={128} data-price={128}>28</option>
                                                        <option value={128} data-price={128}>30</option>
                                                        <option value={128} data-price={128}>32</option>
                                                    </select>
                                                </div>
                                                <div className="usbRow d-flex align-items-center mb-0 active">
                                                    <div className="customCheckbox clearfix">
                                                        <input id="usbCk4" name="usbCk4" type="checkbox" />
                                                        <label htmlFor="usbCk4">Black Handbag</label>
                                                    </div>
                                                    <b className="price mx-2"> ₹99.00</b>
                                                    <select className="usbVrt hide" name="items[][id]" id="PrSelect-99" data-section={99}>
                                                        <option selected="selected" value={99} data-price={99}>Default Title
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="usbbtnCall text-md-center">
                                                <p className="mb-3">TOTAL: <b className="usbPrice product-price-sale fs-6 fw-600 text-primary" data-total={594} data-pr={594}> ₹594.00</b></p>
                                                <p className="mb-3"><button type="submit" name="upselladd" id="upsellAddToCart" className="btn upsellAddToCart">Add selected to cart</button></p>
                                                <p className="mb-0">Get a 15% discount buying these products together</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <a to="" className="product-nav prev-pro clr-none d-flex-center justify-content-between" title="Previous Product">
                                    <span className="details">
                                        <span className="name">Oxford Cuban Shirt</span>
                                        <span className="price"> ₹99.00</span>
                                    </span>
                                    <span className="img"><img src="assets/images/products/product1-120x170.jpg" alt="product" width={120} height={170} /></span>
                                </a>
                                <a to="" className="product-nav next-pro clr-none d-flex-center justify-content-between" title="Next Product">
                                    <span className="img"><img src="assets/images/products/product2-120x170.jpg" alt="product" width={120} height={170} /></span>
                                    <span className="details">
                                        <span className="name">Cuff Beanie Cap</span>
                                        <span className="price"> ₹128</span>
                                    </span>
                                </a>
                                <div className="accordion tab-accordian-style section pb-0" id="productAccordian">
                                    <div className="accordion-item border-0 bg-transparent mb-2">
                                        <h2 className="accordion-header" id="headingOne"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Description</button></h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#productAccordian">
                                            <div className="accordion-body px-0 product-description">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <p>There are many variations of passages of Lorem Ipsum available,
                                                            but the majority have suffered alteration in some form, by
                                                            injected humour, or randomised words which don't look even
                                                            slightly believable.</p>
                                                        <h4 className="mb-3">Features</h4>
                                                        <ul className="checkmark-info">
                                                            <li>High quality fabric, very comfortable to touch and wear.
                                                            </li>
                                                            <li>This cardigan sweater is cute for no reason,perfect for
                                                                travel and casual.</li>
                                                            <li>It can tie in front-is forgiving to you belly or tie behind.
                                                            </li>
                                                            <li>Light weight and perfect for layering.</li>
                                                        </ul>
                                                        <h4 className="mb-3">Fabric</h4>
                                                        <p>All the Lorem Ipsum generators on the Internet tend to repeat
                                                            predefined chunks as necessary, making this the first true
                                                            generator on the Internet. It uses a dictionary of over 200
                                                            Latin words. There are many variations of passages of Lorem
                                                            Ipsum available, but the majority have suffered alteration in
                                                            some form, by injected humour, or randomised words which don't
                                                            look even slightly believable. If you are going to use a
                                                            passage.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border-0 bg-transparent mb-2">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Additional
                                                Information</button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#productAccordian">
                                            <div className="accordion-body px-0 product-description" id="additionalInformation">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4 mb-md-0">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered align-middle table-part mb-0">
                                                                <tbody><tr>
                                                                    <th>Color</th>
                                                                    <td>Black, White, Blue, Red, Gray</td>
                                                                </tr>
                                                                    <tr>
                                                                        <th>Product Dimensions</th>
                                                                        <td>15 x 15 x 3 cm; 250 Grams</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Date First Available</th>
                                                                        <td>14 May 2023</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Manufacturer</th>
                                                                        <td>Fashion and Retail Limited</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Department</th>
                                                                        <td>Men Shirt</td>
                                                                    </tr>
                                                                </tbody></table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border-0 bg-transparent mb-2">
                                        <h2 className="accordion-header" id="headingTwo"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Size Chart</button></h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#productAccordian">
                                            <div className="accordion-body px-0 pb-3" id="size-chart">
                                                <h4 className="mb-2">Ready to Wear Clothing</h4>
                                                <p className="mb-4">This is a standardised guide to give you an idea of what
                                                    size you will need, however some brands may vary from these conversions.
                                                </p>
                                                <div className="size-chart-tbl table-responsive px-1">
                                                    <table className="table-bordered align-middle mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Size</th>
                                                                <th>XXS - XS</th>
                                                                <th>XS - S</th>
                                                                <th>S - M</th>
                                                                <th>M - L</th>
                                                                <th>L - XL</th>
                                                                <th>XL - XXL</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th>UK</th>
                                                                <td>6</td>
                                                                <td>8</td>
                                                                <td>10</td>
                                                                <td>12</td>
                                                                <td>14</td>
                                                                <td>16</td>
                                                            </tr>
                                                            <tr>
                                                                <th>US</th>
                                                                <td>2</td>
                                                                <td>4</td>
                                                                <td>6</td>
                                                                <td>8</td>
                                                                <td>10</td>
                                                                <td>12</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Italy (IT)</th>
                                                                <td>38</td>
                                                                <td>40</td>
                                                                <td>42</td>
                                                                <td>44</td>
                                                                <td>46</td>
                                                                <td>48</td>
                                                            </tr>
                                                            <tr>
                                                                <th>France (FR/EU)</th>
                                                                <td>34</td>
                                                                <td>36</td>
                                                                <td>38</td>
                                                                <td>40</td>
                                                                <td>42</td>
                                                                <td>44</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Denmark</th>
                                                                <td>32</td>
                                                                <td>34</td>
                                                                <td>36</td>
                                                                <td>38</td>
                                                                <td>40</td>
                                                                <td>42</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Russia</th>
                                                                <td>40</td>
                                                                <td>42</td>
                                                                <td>44</td>
                                                                <td>46</td>
                                                                <td>48</td>
                                                                <td>50</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Germany</th>
                                                                <td>32</td>
                                                                <td>34</td>
                                                                <td>36</td>
                                                                <td>38</td>
                                                                <td>40</td>
                                                                <td>42</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Japan</th>
                                                                <td>5</td>
                                                                <td>7</td>
                                                                <td>9</td>
                                                                <td>11</td>
                                                                <td>13</td>
                                                                <td>15</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Australia</th>
                                                                <td>6</td>
                                                                <td>8</td>
                                                                <td>10</td>
                                                                <td>12</td>
                                                                <td>14</td>
                                                                <td>16</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Korea</th>
                                                                <td>33</td>
                                                                <td>44</td>
                                                                <td>55</td>
                                                                <td>66</td>
                                                                <td>77</td>
                                                                <td>88</td>
                                                            </tr>
                                                            <tr>
                                                                <th>China</th>
                                                                <td>160/84</td>
                                                                <td>165/86</td>
                                                                <td>170/88</td>
                                                                <td>175/90</td>
                                                                <td>180/92</td>
                                                                <td>185/94</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Jeans</th>
                                                                <td>24-25</td>
                                                                <td>26-27</td>
                                                                <td>27-28</td>
                                                                <td>29-30</td>
                                                                <td>31-32</td>
                                                                <td>32-33</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border-0 bg-transparent mb-2">
                                        <h2 className="accordion-header" id="headingThree"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Shipping &amp; Return</button></h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#productAccordian">
                                            <div className="accordion-body px-0" id="shipping-return">
                                                <h4 className="pb-1">Shipping &amp; Return</h4>
                                                <ul className="checkmark-info">
                                                    <li>Dispatch: Within 24 Hours</li>
                                                    <li>1 Year Brand Warranty</li>
                                                    <li>Free shipping across all products on a minimum purchase of  ₹50.</li>
                                                    <li>International delivery time - 7-10 business days</li>
                                                    <li>Cash on delivery might be available</li>
                                                    <li>Easy 30 days returns and exchanges</li>
                                                </ul>
                                                <h4 className="pt-1">Free and Easy Returns</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                    since the 1500s, when an unknown printer took a galley of type and
                                                    scrambled it to make a type specimen book. It has survived not only five
                                                    centuries, but also the leap into electronic typesetting, remaining
                                                    essentially unchanged.</p>
                                                <h4 className="pt-1">Special Financing</h4>
                                                <p>There are many variations of passages of Lorem Ipsum available, but the
                                                    majority have suffered alteration in some form, by injected humour, or
                                                    randomised words which don't look even slightly believable. If you are
                                                    going to use a passage.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item border-0 bg-transparent mb-2">
                                        <h2 className="accordion-header" id="headingFour"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Reviews</button></h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#productAccordian">
                                            <div className="accordion-body px-0" id="reviews">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                                                        <div className="ratings-main">
                                                            <div className="avg-rating d-flex-center mb-3">
                                                                <h4 className="avg-mark">4.5</h4>
                                                                <div className="avg-content ms-3">
                                                                    <p className="text-rating">Average Rating</p>
                                                                    <div className="ratings-full product-review">
                                                                        <a className="reviewLink d-flex-center" to="#reviews"><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /><span className="caption ms-2">24 Ratings</span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="ratings-list">
                                                                <div className="ratings-container d-flex align-items-center mt-1">
                                                                    <div className="ratings-full product-review m-0">
                                                                        <a className="reviewLink d-flex align-items-center" to="#reviews"><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /></a>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={99} aria-valuemin={0} aria-valuemax={100} style={{ width: '99%' }} />
                                                                    </div>
                                                                    <div className="progress-value">99%</div>
                                                                </div>
                                                                <div className="ratings-container d-flex align-items-center mt-1">
                                                                    <div className="ratings-full product-review m-0">
                                                                        <a className="reviewLink d-flex align-items-center" to="#reviews"><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /></a>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} style={{ width: '75%' }} />
                                                                    </div>
                                                                    <div className="progress-value">75%</div>
                                                                </div>
                                                                <div className="ratings-container d-flex align-items-center mt-1">
                                                                    <div className="ratings-full product-review m-0">
                                                                        <a className="reviewLink d-flex align-items-center" to="#reviews"><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /><i className="icon anm anm-star-o" /></a>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '50%' }} />
                                                                    </div>
                                                                    <div className="progress-value">50%</div>
                                                                </div>
                                                                <div className="ratings-container d-flex align-items-center mt-1">
                                                                    <div className="ratings-full product-review m-0">
                                                                        <a className="reviewLink d-flex align-items-center" to="#reviews"><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /><i className="icon anm anm-star-o" /><i className="icon anm anm-star-o" /></a>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} style={{ width: '25%' }} />
                                                                    </div>
                                                                    <div className="progress-value">25%</div>
                                                                </div>
                                                                <div className="ratings-container d-flex align-items-center mt-1">
                                                                    <div className="ratings-full product-review m-0">
                                                                        <a className="reviewLink d-flex align-items-center" to="#reviews"><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /><i className="icon anm anm-star-o" /><i className="icon anm anm-star-o" /><i className="icon anm anm-star-o" /></a>
                                                                    </div>
                                                                    <div className="progress">
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={5} aria-valuemin={0} aria-valuemax={100} style={{ width: '5%' }} />
                                                                    </div>
                                                                    <div className="progress-value">05%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 mb-4">
                                                        <form method="post" action="#" className="product-review-form new-review-form">
                                                            <h3 className="spr-form-title">Write a Review</h3>
                                                            <p>Your email address will not be published. Required fields are
                                                                marked *</p>
                                                            <fieldset className="row spr-form-contact">
                                                                <div className="col-sm-6 spr-form-contact-name form-group">
                                                                    <label className="spr-form-label" htmlFor="nickname">Name <span className="required">*</span></label>
                                                                    <input className="spr-form-input spr-form-input-text" id="nickname" type="text" name="name" required />
                                                                </div>
                                                                <div className="col-sm-6 spr-form-contact-email form-group">
                                                                    <label className="spr-form-label" htmlFor="email">Email <span className="required">*</span></label>
                                                                    <input className="spr-form-input spr-form-input-email " id="email" type="email" name="email" required />
                                                                </div>
                                                                <div className="col-sm-6 spr-form-review-title form-group">
                                                                    <label className="spr-form-label" htmlFor="review">Review Title
                                                                    </label>
                                                                    <input className="spr-form-input spr-form-input-text " id="review" type="text" name="review" />
                                                                </div>
                                                                <div className="col-sm-6 spr-form-review-rating form-group">
                                                                    <label className="spr-form-label">Rating</label>
                                                                    <div className="product-review pt-1">
                                                                        <div className="review-rating">
                                                                            <a to="#;"><i className="icon anm anm-star-o" /></a><a to="#;"><i className="icon anm anm-star-o" /></a><a to="#;"><i className="icon anm anm-star-o" /></a><a to="#;"><i className="icon anm anm-star-o" /></a><a to="#;"><i className="icon anm anm-star-o" /></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 spr-form-review-body form-group">
                                                                    <label className="spr-form-label" htmlFor="message">Body of
                                                                        Review <span className="spr-form-review-body-charactersremaining">(1500)
                                                                            characters remaining</span></label>
                                                                    <div className="spr-form-input">
                                                                        <textarea className="spr-form-input spr-form-input-textarea" id="message" name="message" rows={3} defaultValue={""} />
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                            <div className="spr-form-actions clearfix">
                                                                <input type="submit" className="btn btn-primary spr-button spr-button-primary" defaultValue="Submit Review" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="spr-reviews">
                                                            <h3 className="spr-form-title">Customer Reviews</h3>
                                                            <div className="review-inner">
                                                                <div className="spr-review d-flex w-100">
                                                                    <div className="spr-review-profile flex-shrink-0">
                                                                        <img className="blur-up lazyload" data-src="assets/images/users/user-img1.jpg" src="assets/images/users/user-img1.jpg" alt width={200} height={200} />
                                                                    </div>
                                                                    <div className="spr-review-content flex-grow-1">
                                                                        <div className="d-flex justify-content-between flex-column mb-2">
                                                                            <div className="title-review d-flex align-items-center justify-content-between">
                                                                                <h5 className="spr-review-header-title text-transform-none mb-0">
                                                                                    Eleanor Pena</h5>
                                                                                <span className="product-review spr-starratings m-0"><span className="reviewLink"><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /></span></span>
                                                                            </div>
                                                                        </div>
                                                                        <b className="head-font">Good and High quality</b>
                                                                        <p className="spr-review-body">There are many variations
                                                                            of passages of Lorem Ipsum available, but the
                                                                            majority have suffered alteration in some form,
                                                                            by injected humour.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="spr-review d-flex w-100">
                                                                    <div className="spr-review-profile flex-shrink-0">
                                                                        <img className="blur-up lazyload" data-src="assets/images/users/testimonial1.jpg" src="assets/images/users/testimonial1.jpg" alt width={200} height={200} />
                                                                    </div>
                                                                    <div className="spr-review-content flex-grow-1">
                                                                        <div className="d-flex justify-content-between flex-column mb-2">
                                                                            <div className="title-review d-flex align-items-center justify-content-between">
                                                                                <h5 className="spr-review-header-title text-transform-none mb-0">
                                                                                    Courtney Henry</h5>
                                                                                <span className="product-review spr-starratings m-0"><span className="reviewLink"><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /><i className="icon anm anm-star-o" /></span></span>
                                                                            </div>
                                                                        </div>
                                                                        <b className="head-font">Feature Availability</b>
                                                                        <p className="spr-review-body">The standard chunk of
                                                                            Lorem Ipsum used since the 1500s is reproduced
                                                                            below for those interested. Sections 1.10.32 and
                                                                            1.10.33</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*End Product Accordian*/}
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12 product-sidebar sidebar sidebar-bg">
                                {/*Shipping Info*/}
                                <div className="sidebar-widget clearfix">
                                    <div className="widget-content pt-0 mt-0 border-0">
                                        <div className="store-info-item d-flex align-items-center mb-3">
                                            <div className="icon me-3"><i className="icon anm anm-truck-r fs-5" /></div>
                                            <div className="content">
                                                <h5 className="title text-transform-none mb-1">Free &amp; Fast shipping</h5>
                                                <p className="text text-muted text-small">Free Shipping Worldwide</p>
                                            </div>
                                        </div>
                                        <div className="store-info-item d-flex align-items-center mb-3">
                                            <div className="icon me-3"><i className="icon anm anm-credit-card-l fs-5" /></div>
                                            <div className="content">
                                                <h5 className="title text-transform-none mb-1">Secure Checkout</h5>
                                                <p className="text text-muted text-small">We accept all major credit cards</p>
                                            </div>
                                        </div>
                                        <div className="store-info-item d-flex align-items-center mb-3">
                                            <div className="icon me-3"><i className="icon anm anm-clock-r fs-5" /></div>
                                            <div className="content">
                                                <h5 className="title text-transform-none mb-1">Online Support</h5>
                                                <p className="text text-muted text-small">We support online 24/7 on day</p>
                                            </div>
                                        </div>
                                        <div className="store-info-item d-flex align-items-center mb-3">
                                            <div className="icon me-3"><i className="icon anm anm-thumbs-up-l fs-5" /></div>
                                            <div className="content">
                                                <h5 className="title text-transform-none mb-1">Satisfaction Guarantee</h5>
                                                <p className="text text-muted text-small">1 Year Brand Warranty</p>
                                            </div>
                                        </div>
                                        <div className="store-info-item d-flex align-items-center">
                                            <div className="icon me-3"><i className="icon anm anm-shield-alt fs-5" /></div>
                                            <div className="content">
                                                <h5 className="title text-transform-none mb-1">Privacy Protected</h5>
                                                <p className="text text-muted text-small">More than 8 different secure</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar-widget clearfix">
                                    <div className="widget-title">
                                        <h2>Recently Viewed Products</h2>
                                    </div>
                                    <div className="widget-content">
                                        <div className="list list-sidebar-products">
                                            <div className="mini-list-item d-flex w-100 clearfix">
                                                <div className="mini-image"><a className="item-link" to=""><img className="primary blur-up lazyload" data-src="assets/images/products/product1-120x170.jpg" src="assets/images/products/product1-120x170.jpg" alt="image" title="product" width={120} height={170} /></a></div>
                                                <div className="ms-3 details">
                                                    <div className="product-name"><a className="item-title" to="">Oxford Cuban Shirt</a></div>
                                                    <div className="product-price"><span className="old-price"> ₹114.00</span><span className="price"> ₹99.00</span></div>
                                                    <div className="product-review d-flex align-items-center justify-content-start">
                                                        <i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" /><i className="icon anm anm-star-o" />
                                                        <span className="caption hidden ms-2">3 reviews</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mini-list-item d-flex w-100 clearfix">
                                                <div className="mini-image"><a className="item-link" to=""><img className="primary blur-up lazyload" data-src="assets/images/products/product2-120x170.jpg" src="assets/images/products/product2-120x170.jpg" alt="image" title="product" width={120} height={170} /></a></div>
                                                <div className="ms-3 details">
                                                    <div className="product-name"><a className="item-title" to="">Cuff Beanie Cap</a></div>
                                                    <div className="product-price"><span className="price"> ₹128.00</span></div>
                                                    <div className="product-review d-flex align-items-center justify-content-start">
                                                        <i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" />
                                                        <span className="caption hidden ms-2">9 reviews</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mini-list-item d-flex w-100 clearfix">
                                                <div className="mini-image"><a className="item-link" to="prod"><img className="primary blur-up lazyload" data-src="assets/images/products/product3-120x170.jpg" src="assets/images/products/product3-120x170.jpg" alt="image" title="product" width={120} height={170} /></a></div>
                                                <div className="ms-3 details">
                                                    <div className="product-name"><a className="item-title" to="">Flannel Collar Shirt</a></div>
                                                    <div className="product-price"><span className="price"> ₹99.00</span></div>
                                                    <div className="product-review d-flex align-items-center justify-content-start">
                                                        <i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star" /><i className="icon anm anm-star-o" />
                                                        <span className="caption hidden ms-2">30 reviews</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar-widget clearfix">
                                    <div className="widget-title">
                                        <h2>Static Block Content</h2>
                                    </div>
                                    <div className="widget-content">
                                        <p>Use this text to share information about your brand with your customers. Describe
                                            a product, share announcements, or welcome customers to your store.</p>
                                    </div>
                                </div>
                                <div className="sidebar-widget static-banner p-0">
                                    <a to=""><img className="blur-up lazyload" data-src="assets/images/banners/shop-banner.jpg" src="assets/images/banners/shop-banner.jpg" alt="image" width={274} height={367} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TopProducts />
            <div className="stickyCart">
                <div className="container">
                    <form method="post" action="#" id="stickycart-form" className="d-flex-center justify-content-center">
                        <div className="product-featured-img"><img className="blur-up lazyload" data-src="assets/images/products/product1-120x170.jpg" src="assets/images/products/product1-120x170.jpg" alt="product" width={120} height={170} />
                        </div>
                        <div className="sticky-title ms-2 ps-1 pe-5">Oxford Cuban Shirt</div>
                        <div className="stickyOptions position-relative">
                            <div className="selectedOpt">Red / S - <span className="money">₹130.00</span></div>
                            <ul>
                                <li className="vrOpt" data-val={31677941252156} data-no={0}>Red / S - ₹130.00</li>
                                <li className="vrOpt" data-val={31677941383228} data-no={1}>Red / M - ₹130.00</li>
                                <li className="vrOpt" data-val={31677941514300} data-no={2}>Green / L - ₹130.00</li>
                                <li className="vrOpt" data-val={31677941678140} data-no={3}>Green / XL - ₹130.00</li>
                                <li className="vrOpt" data-val={31677941284924} data-no={4}>Pink / S - ₹104.00</li>
                                <li className="vrOpt" data-val={31677941415996} data-no={5}>Pink / M - ₹130.00</li>
                                <li className="vrOpt" data-val={31677941579836} data-no={6}>Peach / L - ₹130.00</li>
                                <li className="vrOpt" data-val={31677941710908} data-no={7}>Peach / XL - ₹130.00</li>
                                <li className="soldout">White / S - Sold out</li>
                                <li className="vrOpt" data-val={31677941481532} data-no={9}>White / M - ₹130.00</li>
                                <li className="vrOpt" data-val={31677941612604} data-no={10}>Blue / L - ₹130.00</li>
                                <li className="vrOpt" data-val={31677941776444} data-no={11}>Blue / XL - ₹130.00</li>
                            </ul>
                        </div>
                        <select name="id" id="variantOptions1" className="product-form__variants selectbox no-js d-none ms-3">
                            <option selected="selected" value={31677941252156}>Red / S</option>
                            <option value={31677941383228}>Red / S</option>
                            <option value={31677941514300}>Red / M</option>
                            <option value={31677941678140}>Green / XL</option>
                            <option value={31677941284924}>Pink / S</option>
                            <option value={31677941415996}>Pink / M</option>
                            <option value={31677941579836}>Peach / L</option>
                            <option value={31677941710908}>Peach / XL</option>
                            <option disabled="disabled">White / S - Sold out</option>
                            <option value={31677941481532}>White / M</option>
                            <option value={31677941612604}>Blue / L</option>
                            <option value={31677941776444}>Blue / XL</option>
                        </select>
                        <div className="qtyField mx-2">
                            <a className="qtyBtn minus" to="#;"><i className="icon anm anm-minus-r" /></a>
                            <input type="text" name="quantity" defaultValue={1} className="product-form-input qty" />
                            <a className="qtyBtn plus" to="#;"><i className="icon anm anm-plus-r" /></a>
                        </div>
                        <button type="submit" name="add" className="btn btn-secondary product-form-cart-submit">
                            <span>Add to cart</span>
                        </button>
                    </form>
                </div>
            </div>

        </>
    )
}

export default ProductInfo