import React from 'react'

const About = () => {
    return (
        <>
            <div id="page-content">
                <div className="page-header text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                                <div className="page-title"><h1>About Us Style2</h1></div>
                                <div className="breadcrumbs"><a href="index.html" title="Back to the home page">Home</a><span className="title"><i className="icon anm anm-angle-right-l" />Pages</span><span className="main-title fw-bold"><i className="icon anm anm-angle-right-l" />About Us Style2</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="destination-section section pt-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="about-images">
                                    <img className="rounded-0 w-100 blur-up lazyload" data-src="assets/images/about/about4.jpg" src="assets/images/about/about4.jpg" alt="about" width={700} height={600} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6">
                                <div className="about-details faqs-style faqs-style2 px-50">
                                    <h2 className="fs-3 mb-3">We Provide Continuous &amp; Kind Service for Customers</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                                    <div className="accordion" id="accordionFaq">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">Customer Service</button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFaq">
                                                <div className="accordion-body">
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Online Consultation</button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFaq">
                                                <div className="accordion-body">
                                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Sales Management</button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFaq">
                                                <div className="accordion-body">
                                                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words discovered source.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="destination-section section section-color-light">
                    <div className="container">
                        <div className="row row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-4 text-center">
                            <div className="counterup-items">
                                <i className="icon anm anm-history fs-3 mb-3" />
                                <p className="counterup-number"><span className="counterup" data-count={50}>50</span><span className="ms-1 text">M+</span></p>
                                <h5 className="counterup-title">Years of foundation</h5>
                            </div>
                            <div className="counterup-items">
                                <i className="icon anm anm-users-r fs-3 mb-3" />
                                <p className="counterup-number"><span className="counterup" data-count={100}>100</span><span className="ms-1 text">B+</span></p>
                                <h5 className="counterup-title">Skilled team members</h5>
                            </div>
                            <div className="counterup-items">
                                <i className="icon anm anm-handshake-l fs-3 mb-3" />
                                <p className="counterup-number"><span className="counterup" data-count={80}>80</span><span className="ms-1 text">M+</span></p>
                                <h5 className="counterup-title">Happy customers</h5>
                            </div>
                            <div className="counterup-items">
                                <i className="icon anm anm-bar-chart-o fs-3 mb-3" />
                                <p className="counterup-number"><span className="counterup" data-count={70}>70</span><span className="ms-1 text">B+</span></p>
                                <h5 className="counterup-title">Monthly orders</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-section section">
                    <div className="container">
                        <div className="section-header">
                            <p className="mb-2 mt-0">Good leaders make it better</p>
                            <h2>Meet Our Leaders</h2>
                        </div>
                        <div className="team-items row col-row row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 text-center">
                            <div className="team-item col-item">
                                <div className="team-img zoom-scal rounded-0">
                                    <img className="rounded-0 blur-up lazyload w-100" data-src="assets/images/about/about-team1.jpg" src="assets/images/about/about-team1.jpg" alt="team" width={350} height={350} />
                                    <ul className="list-inline social-icons d-flex-justify-center">
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook"><i className="icon anm anm-facebook-f" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Twitter"><i className="icon anm anm-twitter" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Pinterest"><i className="icon anm anm-pinterest-p" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram"><i className="icon anm anm-linkedin-in" /></a></li>
                                    </ul>
                                </div>
                                <div className="team-bio pt-3">
                                    <h3 className="mb-2">Bruce Sutton</h3>
                                    <p>CEO - Founder</p>
                                </div>
                            </div>
                            <div className="team-item col-item">
                                <div className="team-img zoom-scal rounded-0">
                                    <img className="rounded-0 blur-up lazyload w-100" data-src="assets/images/about/about-team2.jpg" src="assets/images/about/about-team2.jpg" alt="team" width={350} height={350} />
                                    <ul className="list-inline social-icons d-flex-justify-center">
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook"><i className="icon anm anm-facebook-f" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Twitter"><i className="icon anm anm-twitter" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Pinterest"><i className="icon anm anm-pinterest-p" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram"><i className="icon anm anm-linkedin-in" /></a></li>
                                    </ul>
                                </div>
                                <div className="team-bio pt-3">
                                    <h3 className="mb-2">Ellen Thornton</h3>
                                    <p>Products Manager</p>
                                </div>
                            </div>
                            <div className="team-item col-item">
                                <div className="team-img zoom-scal rounded-0">
                                    <img className="rounded-0 blur-up lazyload w-100" data-src="assets/images/about/about-team3.jpg" src="assets/images/about/about-team3.jpg" alt="team" width={350} height={350} />
                                    <ul className="list-inline social-icons d-flex-justify-center">
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook"><i className="icon anm anm-facebook-f" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Twitter"><i className="icon anm anm-twitter" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Pinterest"><i className="icon anm anm-pinterest-p" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram"><i className="icon anm anm-linkedin-in" /></a></li>
                                    </ul>
                                </div>
                                <div className="team-bio pt-3">
                                    <h3 className="mb-2">Lisa John</h3>
                                    <p>Marketing</p>
                                </div>
                            </div>
                            <div className="team-item col-item">
                                <div className="team-img zoom-scal rounded-0">
                                    <img className="rounded-0 blur-up lazyload w-100" data-src="assets/images/about/about-team1.jpg" src="assets/images/about/about-team1.jpg" alt="team" width={350} height={350} />
                                    <ul className="list-inline social-icons d-flex-justify-center">
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook"><i className="icon anm anm-facebook-f" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Twitter"><i className="icon anm anm-twitter" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Pinterest"><i className="icon anm anm-pinterest-p" /></a></li>
                                        <li className="list-inline-item"><a className="clr-none" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram"><i className="icon anm anm-linkedin-in" /></a></li>
                                    </ul>
                                </div>
                                <div className="team-bio pt-3">
                                    <h3 className="mb-2">Jackly Smith</h3>
                                    <p>Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="awards-section section section-color-light">
                    <div className="container">
                        <div className="row col-row row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-2 text-center">
                            <div className="awards-items col-item">
                                <p className="awards-icon d-flex-justify-center"><i className="icon anm anm-trophy-ar" /></p>
                                <h2 className="awards-title">Winner Master <br /> Award 2019</h2>
                            </div>
                            <div className="awards-items col-item">
                                <p className="awards-icon d-flex-justify-center"><i className="icon anm anm-trophy-ar" /></p>
                                <h2 className="awards-title">Media Agencies <br /> Partner 2020</h2>
                            </div>
                            <div className="awards-items col-item">
                                <p className="awards-icon d-flex-justify-center"><i className="icon anm anm-trophy-ar" /></p>
                                <h2 className="awards-title">Fastest Growing <br /> Award 2021</h2>
                            </div>
                            <div className="awards-items col-item">
                                <p className="awards-icon d-flex-justify-center"><i className="icon anm anm-trophy-ar" /></p>
                                <h2 className="awards-title">National Excellence <br /> Award 2023</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="video-popup-section section pb-0">
                    <div className="container">
                        <div className="section-header d-none">
                            <h2>Hema fashion store</h2>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="video-popup-content position-relative">
                                    <a href="#aboutVideo-modal" className="popup-video d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#aboutVideo_modal" title="View Video">
                                        <img className="w-100 d-block blur-up lazyload" data-src="assets/images/about/video-popup-bg.jpg" src="assets/images/about/video-popup-bg.jpg" alt="image" title width={1100} height={600} />
                                        <i className="icon anm anm-play-cir" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="content-section text-center col-lg-9 mx-auto mt-4">
                                    <h3 className="fs-4 mb-3">Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default About