import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  clearCart,
  getCartTotal,
  removefromCart,
  set_all_amount_data,
} from "../../../redux/cart/CartSlice";
import { newOrder, setcurrentOrder } from "../../../redux/order/OrderSlice";
import { set_checkout_process_steps } from "../../../redux/athentication/Athentication";

const OrderSummery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );

  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
    coupon,
    DeliveryCharge,
    rainStatus,
    RainFee,
    Netpayable,
    SmallCartFee,
    wallet,
    TotalMrp,
  } = useSelector((state) => state.cart);

  const [buttonPress, setbuttonPress] = useState(false);
  const [orderComment, setorderComment] = useState("");

  const removefromCartitem = async (e, item) => {
    e.preventDefault();
    const cart = await dispatch(removefromCart(item));
    dispatch(getCartTotal());
  };

  const cash_on_delivery_press = async (e) => {
    e.preventDefault();
    setbuttonPress(true);

    try {
      if (CartItems.length > 0 && current_address !== "") {
        const address_values = Object.values(current_address);
        const address_string = address_values.join(",");

        var date = new Date();
        date.setDate(date.getDate() + 4);
        var today_date = new Date();
        today_date.setDate(today_date.getDate());
        let all_post_orders = [];
        for (let index = 0; index < CartItems.length; index++) {
          let cartItem = CartItems[index];
          let order_form = {};
          try {
            order_form = {
              ...cartItem,
              userId: loginData._id,
              UserName: loginData.Name,
              UserEmail: loginData.Email,
              UserMobile: loginData.Mobile,
              Address: address_string,
              TotalMrp: cartItem.Product_total_Mrp,
              TotalPrice: cartItem.Product_total_Price,
              SmallCartFee: SmallCartFee,
              HandllingFee: HandllingFee,
              RainFee: RainFee,
              DeliveryCharge: DeliveryCharge,
              wallet: wallet,
              coupon: coupon,
              Saving: cartItem.Product_total_Mrp - cartItem.Product_total_Price,
              TotalAmount:
                cartItem.Product_total_Price +
                SmallCartFee +
                HandllingFee +
                RainFee +
                DeliveryCharge,
              Netpayable:
                cartItem.Product_total_Price +
                SmallCartFee +
                HandllingFee +
                RainFee +
                DeliveryCharge -
                wallet -
                coupon,
              PaymentMode: "Cash on Delivery",
              PaymentStatus: "Not Paid",
              TxnId: "",
              ExpectedDelDate: date,
              OrderComment: orderComment,
              OrderprocessDate: {
                OrderBookedDate: today_date,
                OrderBookedDateShow: true,
              },
            };
            const order_post_respo = await dispatch(newOrder(order_form));

            if (order_post_respo.payload.success) {
              const order_respo = order_post_respo.payload.order;
              all_post_orders = [...all_post_orders, order_respo];
            }
          } catch (error) {
            setbuttonPress(false);
          }
        }

        const all_price_details = {
          TotalMrp: TotalMrp,
          TotalPrice: TotalPrice,
          SmallCartFee: SmallCartFee,
          HandllingFee: HandllingFee,
          RainFee: RainFee,
          DeliveryCharge: DeliveryCharge,
          wallet: wallet,
          coupon: coupon,
          TotalAmount: TotalAmount,
          Netpayable: Netpayable,
          rainStatus: rainStatus,
        };

        if (all_post_orders.length === CartItems.length) {
          dispatch(setcurrentOrder(all_post_orders));
          dispatch(set_all_amount_data(all_price_details));
          dispatch(set_checkout_process_steps(0));
          navigate("/OrderSuccess");
        } else {
          setbuttonPress(false);
        }
      }
    } catch (error) {
      setbuttonPress(false);
    }
  };

  return (
    <>
      <div className="tab-pane active" id="steps3">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-7 col-lg-8">
            <div className="block order-summary">
              <div className="block-content">
                <h3 className="title mb-3">Order Summary</h3>
                <div className="table-responsive table-bottom-brd order-table">
                  <table className="table table-hover align-middle mb-0">
                    <thead>
                      <tr>
                        <th className="action">&nbsp;</th>
                        <th className="text-start">Image</th>
                        <th className="text-start proName">Product</th>
                        <th className="text-center">Qty</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CartItems &&
                        CartItems.map((item, index) => (
                          <tr>
                            <td className="text-center cart-delete">
                              <span
                                className="btn btn-secondary cart-remove remove-icon position-static"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Remove to Cart"
                                onClick={(e) => removefromCartitem(e, item)}
                              >
                                <i className="icon anm anm-times-r" />
                              </span>
                            </td>
                            <td className="text-start">
                              <span className="thumb">
                                <img
                                  className="rounded-0 blur-up lazyload"
                                  data-src={item.thumbnail}
                                  src={item.thumbnail}
                                  alt={item.packSizeOptionName}
                                  title="product"
                                  width={120}
                                  height={170}
                                />
                              </span>
                            </td>
                            <td className="text-start proName">
                              <div className="list-view-item-title limited-paragraph">
                                {item.ProductName}
                              </div>
                              <div className="cart-meta-text ">
                                Pack size: {item.packSizeOptionName}
                                <br />
                                Size: {item.packSizeOptionName}
                                <br />
                                Qty: {item.cart_Quentity}
                              </div>
                            </td>
                            <td className="text-center">
                              {item.cart_Quentity}
                            </td>
                            <td className="text-center">₹{item.Price}</td>
                            <td className="text-center">
                              <strong>₹{item.Product_total_Price}</strong>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="block order-comments my-4">
              <div className="block-content">
                <h3 className="title mb-3">Order Comment</h3>
                <fieldset>
                  <div className="row">
                    <div className="form-group col-md-12 col-lg-12 col-xl-12 mb-0">
                      <textarea
                        className="resize-both form-control"
                        rows={3}
                        placeholder="Place your comment here"
                        value={orderComment}
                        onChange={(e) => setorderComment(e.target.value)}
                      />
                      <small className="mt-2 d-block">
                        *Savings include promotions, coupons, rueBUCKS, and
                        shipping (if applicable).
                      </small>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-5 col-lg-4">
            <div className="block mb-3 apply-code mb-4">
              <div className="block-content">
                <h3 className="title mb-3">Apply Promocode</h3>
                <div id="coupon" className="coupon-dec">
                  <p>
                    Got a promo code? Then you're a few randomly combined
                    numbers &amp; letters away from fab savings!
                  </p>
                  {/* <div className="input-group mb-0 d-flex">
                    <input
                      id="coupon-code"
                      required
                      type="text"
                      className="form-control"
                      placeholder="Promotion/Discount Code"
                    />
                    <button
                      className="coupon-btn btn btn-primary"
                      type="submit"
                    >
                      Apply
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="cart-info mb-4">
              <div className="cart-order-detail cart-col">
                <div className="row g-0 border-bottom pb-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title">
                    <strong>Subtotal</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                    <span className="money"> ₹{TotalPrice}</span>
                  </span>
                </div>
                <div className="row g-0 border-bottom py-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title">
                    <strong>Coupon Discount</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                    <span className="money">- ₹{coupon}</span>
                  </span>
                </div>

                <div className="row g-0 border-bottom py-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title">
                    <strong>Handlling Fee</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                    <span className="money"> ₹{HandllingFee}</span>
                  </span>
                </div>
                {rainStatus === true && (
                  <div className="row g-0 border-bottom py-2">
                    <span className="col-6 col-sm-6 cart-subtotal-title">
                      <strong>Rain Fee</strong>
                    </span>
                    <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                      <span className="money"> ₹{RainFee}</span>
                    </span>
                  </div>
                )}

                <div className="row g-0 border-bottom py-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title">
                    <strong>Shipping</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                    {DeliveryCharge === 0 ? (
                      <>
                        <span className="money">Free shipping</span>
                      </>
                    ) : (
                      <>
                        <span className="money">{DeliveryCharge}</span>
                      </>
                    )}
                  </span>
                </div>
                <div className="row g-0 pt-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title fs-6">
                    <strong>Netpayable</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title fs-5 cart-subtotal text-end text-primary">
                    <b className="money"> ₹{Netpayable}</b>
                  </span>
                </div>
                {/* <p className="cart-shipping mt-3">
                    Shipping &amp; taxes calculated at checkout
                  </p> */}
                {DeliveryCharge === 0 && (
                  <p className="cart-shipping fst-normal freeShipclaim">
                    <i className="me-2 align-middle icon anm anm-truck-l" />
                    <b>FREE SHIPPING</b> ELIGIBLE
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button type="button" className="btn btn-secondary me-1 btnPrevious">
            Back to Order Summary
          </button>
          <button
            type="button"
            onClick={(e) => cash_on_delivery_press(e)}
            className="btn btn-primary ms-1 btnNext"
          >
            Proceed to Place Order
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderSummery;
