import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SelectPayment = () => {
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );
  return (
    <>
      <div className="tab-pane active" id="steps4">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-7 col-lg-8">
            <div className="block mb-3 delivery-methods mb-4">
              <div className="block-content">
                <h3 className="title mb-3">Delivery Methods</h3>
                <div className="delivery-methods-content">
                  <div className="customRadio clearfix">
                    <input
                      id="formcheckoutRadio1"
                      defaultValue
                      name="radio1"
                      type="radio"
                      className="radio"
                      defaultChecked="checked"
                    />
                    <label htmlFor="formcheckoutRadio1" className="mb-0">
                      Standard Delivery ₹2.99 (3-5 days)
                    </label>
                  </div>
                  <div className="customRadio clearfix">
                    <input
                      id="formcheckoutRadio2"
                      defaultValue
                      name="radio1"
                      type="radio"
                      className="radio"
                    />
                    <label htmlFor="formcheckoutRadio2" className="mb-0">
                      Express Delivery ₹10.99 (1-2 days)
                    </label>
                  </div>
                  <div className="customRadio clearfix mb-0">
                    <input
                      id="formcheckoutRadio3"
                      defaultValue
                      name="radio1"
                      type="radio"
                      className="radio"
                    />
                    <label htmlFor="formcheckoutRadio3" className="mb-0">
                      Same-Day ₹20.00 (Evening Delivery)
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="block mb-3 payment-methods mb-4">
              <div className="block-content">
                <h3 className="title mb-3">Payment Methods</h3>
                <div className="payment-accordion-radio">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item card mb-2">
                      <div className="card-header" id="headingOne">
                        <button
                          className="card-link"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <span className="customRadio clearfix mb-0">
                            <input
                              id="paymentRadio1"
                              defaultValue
                              name="payment"
                              type="radio"
                              className="radio"
                              defaultChecked="checked"
                            />
                            <label htmlFor="paymentRadio1" className="mb-0">
                              Pay with credit card
                            </label>
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body px-0">
                          <fieldset>
                            <div className="row">
                              <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                <label htmlFor="input-cardname">
                                  Name on Card{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  name="cardname"
                                  defaultValue
                                  placeholder
                                  id="input-cardname"
                                  className="form-control"
                                  type="text"
                                  pattern="[0-9\-]*"
                                />
                              </div>
                              <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                <label>
                                  Credit Card Type{" "}
                                  <span className="required">*</span>
                                </label>
                                <select
                                  name="country_id"
                                  className="form-control"
                                >
                                  <option value>Please Select</option>
                                  <option value={1}>American Express</option>
                                  <option value={2}>Visa Card</option>
                                  <option value={3}>Master Card</option>
                                  <option value={4}>Discover Card</option>
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-12 col-sm-4 col-md-4 col-lg-4">
                                <label htmlFor="input-cardno">
                                  Credit Card Number{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  name="cardno"
                                  defaultValue
                                  placeholder
                                  id="input-cardno"
                                  className="form-control"
                                  type="text"
                                  pattern="[0-9\-]*"
                                />
                              </div>
                              <div className="form-group col-12 col-sm-4 col-md-4 col-lg-4">
                                <label htmlFor="input-cvv">
                                  CVV Code <span className="required">*</span>
                                </label>
                                <input
                                  name="cvv"
                                  defaultValue
                                  placeholder
                                  id="input-cvv"
                                  className="form-control"
                                  type="text"
                                  pattern="[0-9\-]*"
                                />
                              </div>
                              <div className="form-group col-12 col-sm-4 col-md-4 col-lg-4">
                                <label>
                                  Expiration Date{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  type="date"
                                  name="exdate"
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-12 col-sm-4 col-md-4 col-lg-4 mb-0">
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item card mb-2">
                      <div className="card-header" id="headingTwo">
                        <button
                          className="card-link"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <span className="customRadio clearfix mb-0">
                            <input
                              id="paymentRadio2"
                              defaultValue
                              name="payment"
                              type="radio"
                              className="radio"
                            />
                            <label htmlFor="paymentRadio2" className="mb-0">
                              Pay with Paypal
                            </label>
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body px-0">
                          <p>
                            Pay via PayPal you can pay with your credit card if
                            you don't have a PayPal account.
                          </p>
                          <div className="input-group mb-0 d-flex">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="paypal@example.com"
                              required
                            />
                            <button className="btn btn-primary" type="submit">
                              Pay 99.00 USD
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item card mb-2">
                      <div className="card-header" id="headingThree">
                        <button
                          className="card-link"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <span className="customRadio clearfix mb-0">
                            <input
                              id="paymentRadio3"
                              defaultValue
                              name="payment"
                              type="radio"
                              className="radio"
                            />
                            <label htmlFor="paymentRadio3" className="mb-0">
                              Cheque Payment
                            </label>
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body px-0">
                          <p>
                            Please send your cheque to Store Name, Store Street,
                            Store Town, Store State / County, Store Postcode.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item card mb-0">
                      <div className="card-header" id="headingFour">
                        <button
                          className="card-link"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <span className="customRadio clearfix mb-0">
                            <input
                              id="paymentRadio4"
                              defaultValue
                              name="payment"
                              type="radio"
                              className="radio"
                            />
                            <label htmlFor="paymentRadio4" className="mb-0">
                              Cash On Delivery
                            </label>
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body px-0">
                          <p>
                            Cash on delivery refers to an arrangement in which
                            payment for a purchase is made directly by the
                            purchaser to the person who delivers the item.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-5 col-lg-4">
            <div className="cart-info">
              <div className="cart-order-detail cart-col">
                <div className="row g-0 border-bottom pb-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title">
                    <strong>Subtotal</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                    <span className="money">₹226.00</span>
                  </span>
                </div>
                <div className="row g-0 border-bottom py-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title">
                    <strong>Coupon Discount</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                    <span className="money">-₹25.00</span>
                  </span>
                </div>
                <div className="row g-0 border-bottom py-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title">
                    <strong>Tax</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                    <span className="money">₹10.00</span>
                  </span>
                </div>
                <div className="row g-0 border-bottom py-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title">
                    <strong>Shipping</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                    <span className="money">Free shipping</span>
                  </span>
                </div>
                <div className="row g-0 pt-2">
                  <span className="col-6 col-sm-6 cart-subtotal-title fs-6">
                    <strong>Total</strong>
                  </span>
                  <span className="col-6 col-sm-6 cart-subtotal-title fs-5 cart-subtotal text-end text-primary">
                    <b className="money">₹311.00</b>
                  </span>
                </div>
                <Link
                  to="/OrderSuccess"
                  id="cartCheckout"
                  className="btn btn-lg my-4 checkout w-100"
                >
                  Place order
                </Link>
                <div className="paymnet-img text-center">
                  <img
                    src="assets/images/icons/safepayment.png"
                    alt="Payment"
                    width={299}
                    height={28}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPayment;
