import React from "react";
import { useDispatch, useSelector } from "react-redux";

const AccountInfo = () => {
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );
  return (
    <>
      <div className="tab-pane fade h-100 show active" id="info">
        <div className="account-info h-100">
          <div className="welcome-msg mb-4">
            <h2>
              Hello, <span className="text-primary">Jecno Janesen</span>
            </h2>
            <p>
              From your My Account Dashboard you have the ability to view a
              snapshot of your recent account activity and update your account
              information. Select a link below to view or edit information.
            </p>
          </div>
          <div className="row g-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-1 mb-4">
            <div className="counter-box">
              <div className="bg-block d-flex-center flex-nowrap">
                <img
                  className="blur-up lazyload"
                  data-src="assets/images/icons/sale.png"
                  src="assets/images/icons/sale.png"
                  alt="icon"
                  width={64}
                  height={64}
                />
                <div className="content">
                  <h3 className="fs-5 mb-1 text-primary">238</h3>
                  <p>Total Order</p>
                </div>
              </div>
            </div>
            <div className="counter-box">
              <div className="bg-block d-flex-center flex-nowrap">
                <img
                  className="blur-up lazyload"
                  data-src="assets/images/icons/homework.png"
                  src="assets/images/icons/homework.png"
                  alt="icon"
                  width={64}
                  height={64}
                />
                <div className="content">
                  <h3 className="fs-5 mb-1 text-primary">124</h3>
                  <p>Pending Orders</p>
                </div>
              </div>
            </div>
            <div className="counter-box">
              <div className="bg-block d-flex-center flex-nowrap">
                <img
                  className="blur-up lazyload"
                  data-src="assets/images/icons/order.png"
                  src="assets/images/icons/order.png"
                  alt="icon"
                  width={64}
                  height={64}
                />
                <div className="content">
                  <h3 className="fs-5 mb-1 text-primary">102</h3>
                  <p>Awaiting Payments</p>
                </div>
              </div>
            </div>
          </div>
          <div className="account-box">
            {/* <h3 className="mb-3">Account Information</h3>
            <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
              <div className="box-info mb-4">
                <div className="box-title d-flex-center">
                  <h4>Contact Information</h4>{" "}
                  <a to="#" className="btn-link ms-auto">
                    Edit
                  </a>
                </div>
                <div className="box-content mt-3">
                  <h5>Hema Jecno</h5>
                  <p className="mb-2">info@example.com</p>
                  <p>
                    <a to="#" className="btn-link">
                      Change Password
                    </a>
                  </p>
                </div>
              </div>
              <div className="box-info mb-4">
                <div className="box-title d-flex-center">
                  <h4>Newsletters</h4>{" "}
                  <a to="#" className="btn-link ms-auto">
                    Edit
                  </a>
                </div>
                <div className="box-content mt-3">
                  <p>You are currently not subscribed to any newsletter.</p>
                </div>
              </div>
            </div> */}
            <div className="box-info mb-4">
              <div className="box-title d-flex-center">
                <h4>Address Book</h4>{" "}
                <a to="#" className="btn-link ms-auto">
                  Edit
                </a>
              </div>
              <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                <div className="box-content mt-3">
                  <h5>Default Billing Address</h5>
                  <address className="mb-2">
                    {current_address.HNo}, {current_address.Area},{" "}
                    {current_address.sub_locality}, <br />
                    {current_address.locality}, {current_address.LandMark},{" "}
                    {current_address.City} <br />
                    {current_address.State},{current_address.country} -{" "}
                    {current_address.Pincode}.
                  </address>
                  {/* <p>
                    <a to="#" className="btn-link">
                      Edit Address
                    </a>
                  </p> */}
                </div>
                {/* <div className="box-content mt-3">
                  <h5>Default Shipping Address</h5>
                  <address className="mb-2">
                    You have not set a default shipping address.
                  </address>
                  <p>
                    <a to="#" className="btn-link">
                      Edit Address
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountInfo;
