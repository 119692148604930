import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCartTotal, set_all_amount_data } from "../../redux/cart/CartSlice";
import { setcurrentOrder } from "../../redux/order/OrderSlice";
import { FiPhone } from "react-icons/fi";

const Hadear = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const {
    CartItems,
    amountToGetfeeDelivery,
    amountToGetfeeDeliveryPercentage,
    TotalPrice,
    TotalAmount,
    HandllingFee,
  } = useSelector((state) => state.cart);

  const { isAuth } = useSelector((state) => state.Athentication);

  const { categoryShow } = useSelector((store) => store.category);
  const { categoryTagShow } = useSelector((store) => store.categorytag);
  const { subcategoryShow } = useSelector((store) => store.subCategories);

  const [search, setSearch] = useState("");
  const [subcatSearchResult, setSubcatSearchResult] = useState([]);
  const [categoryTagSearchResult, setCategoryTagSearchResult] = useState([]);
  const [categorySearchResult, setCategorySearchResult] = useState([]);
  const [ProductSearchResult, setProductSearchResult] = useState([]);

  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    dispatch(getCartTotal());
  }, [CartItems]);

  const searchchange = (e) => {
    const result = e.target.value;
    setSearch(result);

    if (result.length >= 2) {
      const filter_category = categoryShow
        .filter((category) =>
          category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_sub_category = subcategoryShow
        .filter((sub_category) =>
          sub_category.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);
      const filter_category_tag = categoryTagShow
        .filter((category_tag) =>
          category_tag.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 4);

      setCategorySearchResult(filter_category);
      setSubcatSearchResult(filter_sub_category);
      // setCategoryTagSearchResult(filter_category_tag);
      setShowResults(true);
    }
  };
  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  const searchsubmit = (e) => {
    e.preventDefault();
    navigate(`/product-search/${search}`);
    setShowResults(false);
  };

  const serchclick = () => {
    navigate(`/product-search/${search}`);
    setShowResults(false);
  };

  return (
    <>
      <div>
        <div className="topbar-slider clearfix">
          <div className="container-fluid">
            <div className="marquee-text">
              <div className="top-info-bar d-flex">
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE FASHIONABLE
                      APPAREL
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE BEST DEALS
                      ON ELECTRONICS
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
                <div className="flex-item center">
                  <Link>
                    <span>
                      {" "}
                      <i className="anm anm-worldwide" /> BUY ONLINE STYLISH
                      OUTFITS & LATEST TRENDS
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-truck-l" /> FREE SHIPPING ON ALL
                      ORDERS ABOVE ₹500
                    </span>
                    <span>
                      {" "}
                      <i className="anm anm-redo-ar" /> EXTENDED RETURN UNTIL 30
                      DAYS
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6 col-sm-6 col-md-3 col-lg-4 text-left">
                <FiPhone className="icon anm  me-2" />
                <a to="tel:401234567890">+91 8447282606</a>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center d-none d-md-block">
                Free shipping on all orders over ₹500{" "}
                <Link to="/" className="text-link ms-1">
                  Shop now
                </Link>
              </div>
              <div className="col-6 col-sm-6 col-md-3 col-lg-4 text-right d-flex align-items-center justify-content-end">
                <div className="select-wrap language-picker float-start">
                  <div className="option english">
                    <span>info@ewshopping.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header className="header d-flex align-items-center header-1 header-fixed">
          {/* <header className="header d-flex align-items-center header-1 header-fixed is-fixed is-small animated fadeIn"> */}
          <div className="container">
            <div className="row">
              <div className="logo col-5 col-sm-3 col-md-3 col-lg-2 align-self-center is-fixed desktopVersion">
                <Link className="logoImg" to="/">
                  <img
                    src="/assets/images/ew-rbg.png"
                    alt="EwShopping"
                    title="EwShopping"
                    className="imglogo"
                  />
                  {/* <h1 style={{color:"#063D79", marginTop:"20px", fontSize:"30px"}}>EWShopping</h1> */}
                </Link>
              </div>

              <div className="col-1 col-sm-1 col-md-1 col-lg-8 align-self-center d-menu-col">
                <nav className="navigation" id="AccessibleNav">
                  <div
                    className="search-body"
                    style={{
                      border: "1.5px solid #2f415d",
                      borderRadius: "5px",
                    }}
                    ref={ref}
                  >
                    <form
                      className="form minisearch"
                      id="header-search"
                      onSubmit={(e) => searchsubmit(e)}
                    >
                      <div
                        className="d-flex searchField"
                        style={{ position: "relative" }}
                      >
                        <div className="input-box d-flex fl-1">
                          <input
                            type="text"
                            className="input-text border-start-0 border-end-0"
                            placeholder="Search for products..."
                            value={search}
                            onChange={searchchange}
                            style={{
                              borderRadius: "5px",
                            }}
                          />
                          <button
                            type="submit"
                            onClick={serchclick}
                            className="action search d-flex-justify-center btn rounded-start-0"
                          >
                            <span style={{ color: "white" }}>
                              {" "}
                              <i className="icon anm anm-search-l" />
                            </span>
                          </button>
                        </div>
                      </div>
                      {showResults && (
                        <>
                          <div className="serchsugist">
                            {categorySearchResult.length > 0 && (
                              <ul className="">
                                {categorySearchResult &&
                                  categorySearchResult.map(
                                    (category, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/search/${category.superCategory}/${category.slugUrl}`}
                                              onClick={() =>
                                                setShowResults(false)
                                              }
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={category.desktopImage}
                                                src={category.desktopImage}
                                                alt={category.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                onClick={() =>
                                                  setShowResults(false)
                                                }
                                              >
                                                {category.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                4 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                            {subcatSearchResult.length > 0 && (
                              <ul>
                                {subcatSearchResult &&
                                  subcatSearchResult.map(
                                    (sub_category, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                              onClick={() =>
                                                setShowResults(false)
                                              }
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={
                                                  sub_category.desktopImage
                                                }
                                                src={sub_category.desktopImage}
                                                alt={sub_category.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                                onClick={() =>
                                                  setShowResults(false)
                                                }
                                              >
                                                {sub_category.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                3 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                            {categoryTagSearchResult.length > 0 && (
                              <ul>
                                {categoryTagSearchResult &&
                                  categoryTagSearchResult.map(
                                    (category_tag, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/tag-search/${category_tag.slugUrl}`}
                                              onClick={() =>
                                                setShowResults(false)
                                              }
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={
                                                  category_tag.desktopImage
                                                }
                                                src={category_tag.desktopImage}
                                                alt={category_tag.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/tag-search/${category_tag.slugUrl}`}
                                                onClick={() =>
                                                  setShowResults(false)
                                                }
                                              >
                                                {category_tag.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                3 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </nav>
              </div>

              <div className="col-7 col-sm-9 col-md-9 col-lg-2 align-self-center icons-col text-right desktopVersion">
                <div className="search-parent iconset ">
                  <div className="site-search mobileVersion" title="Search">
                    <Link
                      to=""
                      className="search-icon clr-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#search-drawer"
                    >
                      <i className="hdr-icon icon anm anm-search-l" />
                    </Link>
                  </div>

                  <div
                    className="search-drawer offcanvas offcanvas-top"
                    tabIndex={-1}
                    id="search-drawer"
                    // style={{zIndex:9999}}
                  >
                    <div
                      className="container"
                      style={{ zIndex: "9999 !important" }}
                    >
                      <div className="search-header d-flex-center justify-content-between mb-3">
                        <h3 className="title m-0">What are you looking for?</h3>
                        <button
                          type="button"
                          className="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        />
                      </div>
                      <div className="search-body">
                        <form
                          className="form minisearch"
                          id="header-search"
                          onSubmit={(e) => searchsubmit(e)}
                        >
                          <div className="d-flex searchField">
                            {/* <div className="search-category">
                              <select className="rgsearch-category rounded-end-0">
                                <option hidden selected>
                                  All Categories
                                </option>
                                {categoryShow &&
                                  categoryShow.map((category, index) => (
                                    <option key={index} value={category.name}>
                                      {category.name}
                                    </option>
                                  ))}
                              </select>
                            </div> */}
                            <div className="input-box d-flex fl-1">
                              <input
                                type="text"
                                className="input-text border-start-0 border-end-0"
                                placeholder="Search for products..."
                                value={search}
                                onChange={searchchange}
                              />
                              <button
                                type="submit"
                                onClick={serchclick}
                                className="action search d-flex-justify-center btn rounded-start-0"
                              >
                                <span style={{ color: "white" }}>
                                  {" "}
                                  <i className="icon anm anm-search-l" />
                                </span>
                              </button>
                            </div>
                          </div>
                          {/* <div className="popular-searches d-flex-justify-center mt-3">
                            <span className="title fw-600">Trending Now:</span>
                            <div className="d-flex-wrap searches-items">
                              <Link className="text-link ms-2">T-Shirt,</Link>
                              <Link className="text-link ms-2">Shoes,</Link>
                              <Link className="text-link ms-2">Bags</Link>
                            </div>
                          </div> */}
                          <div className="search-products">
                            {subcatSearchResult.length === 0 &&
                              categorySearchResult.length === 0 &&
                              categoryTagSearchResult.length === 0 && (
                                <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                  <li className="item empty w-100 text-center text-muted d-block">
                                    You don't have any items in your search.
                                  </li>
                                </ul>
                              )}

                            {categorySearchResult.length > 0 && (
                              <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                {categorySearchResult &&
                                  categorySearchResult.map(
                                    (category, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/search/${category.superCategory}/${category.slugUrl}`}
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={category.desktopImage}
                                                src={category.desktopImage}
                                                alt={category.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/search/${category.superCategory}/${category.slugUrl}`}
                                              >
                                                {category.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                4 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                            {subcatSearchResult.length > 0 && (
                              <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                {subcatSearchResult &&
                                  subcatSearchResult.map(
                                    (sub_category, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={
                                                  sub_category.desktopImage
                                                }
                                                src={sub_category.desktopImage}
                                                alt={sub_category.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                              >
                                                {sub_category.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                3 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                            {categoryTagSearchResult.length > 0 && (
                              <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                {categoryTagSearchResult &&
                                  categoryTagSearchResult.map(
                                    (category_tag, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/tag-search/${category_tag.slugUrl}`}
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={
                                                  category_tag.desktopImage
                                                }
                                                src={category_tag.desktopImage}
                                                alt={category_tag.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/tag-search/${category_tag.slugUrl}`}
                                              >
                                                {category_tag.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                3 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="account-parent iconset">
                  <div className="account-link" title="Account">
                    <i className="hdr-icon icon anm anm-user-al" />
                  </div>
                  <div id="accountBox">
                    <div className="customer-links">
                      <ul className="m-0">
                        {isAuth === true ? (
                          <>
                            <li>
                              <Link to="/my-account">
                                <i className="icon anm anm-user-cil" />
                                My Account
                              </Link>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link to="/login">
                                <i className="icon anm anm-user-cil" />
                                Sign In
                              </Link>
                            </li>
                          </>
                        )}
                        <li>
                          <Link to="/Wishlist">
                            <i className="icon anm anm-heart-l" />
                            Wishlist
                          </Link>
                        </li>
                        <li>
                          <Link to="/">
                            <i className="icon anm anm-sign-out-al" />
                            Sign out
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="wishlist-link iconset" title="Wishlist">
                  <Link to="/Wishlist">
                    <i className="hdr-icon icon anm anm-heart-l" />
                    <span className="wishlist-count">0</span>
                  </Link>
                </div>
                <div className="header-cart iconset" title="Cart">
                  <Link
                    className="header-cart btn-minicart clr-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#minicart-drawer"
                  >
                    <i className="hdr-icon icon anm anm-cart-l" />
                    <span className="cart-count">{CartItems.length}</span>
                  </Link>

                  <div
                    id="minicart-drawer"
                    className="minicart-right-drawer offcanvas offcanvas-end"
                    tabIndex={-1}
                  >
                    {CartItems.length === 0 ? (
                      <>
                        <div
                          id="cartEmpty"
                          className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                        >
                          <div className="minicart-header d-flex-center justify-content-between w-100">
                            <h4 className="fs-6">
                              Your cart ({CartItems.length} Items)
                            </h4>
                            <button
                              className="close-cart border-0"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              <i
                                className="icon anm anm-times-r"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Close"
                              />
                            </button>
                          </div>
                          <div className="cartEmpty-content mt-4">
                            <i className="icon anm anm-cart-l fs-1 text-muted" />
                            <p className="my-3">No Products in the Cart</p>
                            <span
                              className="btn btn-primary cart-btn"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Continue shopping
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div id="cart-drawer" className="block block-cart">
                          <div className="minicart-header">
                            <button
                              className="close-cart border-0"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              <i
                                className="icon anm anm-times-r"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Close"
                              />
                            </button>
                            <h4 className="fs-6">
                              Your cart ({CartItems.length} Items)
                            </h4>
                          </div>
                          <div className="minicart-content">
                            <ul className="m-0 clearfix">
                              {CartItems &&
                                CartItems.map((item, index) => (
                                  <li
                                    className="item d-flex justify-content-center align-items-center"
                                    key={index}
                                  >
                                    <span className="product-image rounded-3">
                                      <img
                                        className="blur-up lazyload"
                                        data-src={item.thumbnail}
                                        src={item.thumbnail}
                                        alt="product"
                                        title="Product"
                                        width={120}
                                        height={170}
                                      />
                                    </span>
                                    <div className="product-details">
                                      <span className="product-title limited-paragraph">
                                        {item.ProductName}
                                      </span>
                                      <div className="variant-cart my-2 limited-paragraph">
                                        {item.packSizeOptionName} /{" "}
                                        {item.packSizeName}
                                      </div>
                                      <div className="priceRow">
                                        <div className="product-price">
                                          <span className="price">
                                            {" "}
                                            ₹{item.Price}
                                          </span>{" "}
                                          x{" "}
                                          <span className="price">
                                            {" "}
                                            {item.cart_Quentity}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className="minicart-bottom">
                            <div className="shipinfo">
                              {amountToGetfeeDelivery !== 0 && (
                                <div className="progress mb-2">
                                  <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    aria-valuenow={70}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{
                                      width: `${amountToGetfeeDeliveryPercentage}%`,
                                    }}
                                  >
                                    {amountToGetfeeDeliveryPercentage}%
                                  </div>
                                </div>
                              )}

                              {amountToGetfeeDeliveryPercentage === 0 ? (
                                <>
                                  <div className="freeShipMsg">
                                    <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                    Only{" "}
                                    <span
                                      className="money"
                                      data-currency-usd=" ₹199.00"
                                      data-currency="USD"
                                    >
                                      {" "}
                                      ₹{amountToGetfeeDelivery}
                                    </span>{" "}
                                    away from
                                    <b>Free Shipping</b>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="freeShipMsg d-block">
                                    <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                    Congrats! You are eligible for
                                    <b>Free Shipping</b>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="subtotal clearfix my-3">
                              <div className="totalInfo clearfix mb-1 d-block">
                                <span>Total:</span>
                                <span className="item product-price">
                                  {" "}
                                  ₹{TotalPrice}
                                </span>
                              </div>
                              <div className="totalInfo clearfix mb-1 d-block">
                                <span>Handlling Fee:</span>
                                <span className="item product-price">
                                  {" "}
                                  ₹{HandllingFee}
                                </span>
                              </div>
                              <div className="totalInfo clearfix">
                                <span>Netpayable:</span>
                                <span className="item product-price">
                                  {" "}
                                  ₹{TotalAmount}
                                </span>
                              </div>
                            </div>
                            {/* <div className="agree-check customCheckbox">
                              <input
                                id="prTearm"
                                name="tearm"
                                type="checkbox"
                                defaultValue="tearm"
                                required
                              />
                              <label htmlFor="prTearm">
                                {" "}
                                I agree with the{" "}
                              </label>
                              <Link  className="ms-1 text-link">
                                Terms &amp; conditions
                              </Link>
                            </div> */}
                            <div className="minicart-action d-flex mt-3">
                              <span
                                onClick={() => navigate("/check-out")}
                                className="proceed-to-checkout btn btn-primary w-50 me-1"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Check Out
                              </span>
                              <span
                                onClick={() => navigate("/cart")}
                                className="cart-btn btn btn-secondary w-50 ms-1"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                View Cart
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {/*MiniCart Content*/}
                  </div>
                </div>
                {/* <button type="button" className="iconset pe-0 menu-icon js-mobile-nav-toggle mobile-nav--open d-lg-none" title="Menu"><i className="hdr-icon icon anm anm-times-l" /><i className="hdr-icon icon anm anm-bars-r" /></button> */}
              </div>

              <div className="logo col-4 col-sm-3 col-md-3 col-lg-2 align-self-center is-fixed mobileVersion">
                <Link className="logoImg" to="/">
                  <img
                    src="/assets/images/ew-rbg.png"
                    alt="EwShopping"
                    title="EwShopping"
                    className="imglogo"
                  />
                  {/* <h1 style={{color:"#063D79", marginTop:"20px", fontSize:"30px"}}>EWShopping</h1> */}
                </Link>
              </div>

              <div className="col-7 col-sm-1 col-md-1 col-lg-8 align-self-center d-menu-col mobileVersion">
                <nav className="navigation" id="AccessibleNav">
                  <div
                    className="search-body"
                    style={{
                      border: "1.5px solid #2f415d",
                      borderRadius: "5px",
                    }}
                    ref={ref}
                  >
                    <form
                      className="form minisearch"
                      id="header-search"
                      onSubmit={(e) => searchsubmit(e)}
                    >
                      <div
                        className="d-flex searchField"
                        style={{ position: "relative" }}
                      >
                        <div className="input-box d-flex fl-1">
                          <input
                            type="text"
                            className="input-text border-start-0 border-end-0"
                            placeholder="Search for products..."
                            value={search}
                            onChange={searchchange}
                            style={{
                              // borderRadius: "5px",
                              border:"none"
                            }}
                          />
                          <button
                            type="submit"
                            onClick={serchclick}
                            className="action search d-flex-justify-center btn rounded-start-0"
                          >
                            <span style={{ color: "white" }}>
                              {" "}
                              <i className="icon anm anm-search-l" />
                            </span>
                          </button>
                        </div>
                      </div>
                      {showResults && (
                        <>
                          <div className="serchsugist">
                            {categorySearchResult.length > 0 && (
                              <ul className="">
                                {categorySearchResult &&
                                  categorySearchResult.map(
                                    (category, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/search/${category.superCategory}/${category.slugUrl}`}
                                              onClick={() =>
                                                setShowResults(false)
                                              }
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={category.desktopImage}
                                                src={category.desktopImage}
                                                alt={category.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/search/${category.superCategory}/${category.slugUrl}`}
                                                onClick={() =>
                                                  setShowResults(false)
                                                }
                                              >
                                                {category.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                4 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                            {subcatSearchResult.length > 0 && (
                              <ul>
                                {subcatSearchResult &&
                                  subcatSearchResult.map(
                                    (sub_category, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                              onClick={() =>
                                                setShowResults(false)
                                              }
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={
                                                  sub_category.desktopImage
                                                }
                                                src={sub_category.desktopImage}
                                                alt={sub_category.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                                onClick={() =>
                                                  setShowResults(false)
                                                }
                                              >
                                                {sub_category.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                3 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                            {categoryTagSearchResult.length > 0 && (
                              <ul>
                                {categoryTagSearchResult &&
                                  categoryTagSearchResult.map(
                                    (category_tag, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/tag-search/${category_tag.slugUrl}`}
                                              onClick={() =>
                                                setShowResults(false)
                                              }
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={
                                                  category_tag.desktopImage
                                                }
                                                src={category_tag.desktopImage}
                                                alt={category_tag.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/tag-search/${category_tag.slugUrl}`}
                                                onClick={() =>
                                                  setShowResults(false)
                                                }
                                              >
                                                {category_tag.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                3 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                          </div>
                        </>
                      )}
                    </form>
                  </div>
                </nav>
              </div>

              <div className="col-1 col-sm-9 col-md-9 col-lg-2 align-self-center icons-col text-right mobileVersion">
                <div className="search-parent iconset ">
                  <div className="site-search mobileVersion" title="Search">
                    <Link
                      to=""
                      className="search-icon clr-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#search-drawer"
                    >
                      <i className="hdr-icon icon anm anm-search-l" />
                    </Link>
                  </div>

                  <div
                    className="search-drawer offcanvas offcanvas-top"
                    tabIndex={-1}
                    id="search-drawer"
                    // style={{zIndex:9999}}
                  >
                    <div
                      className="container"
                      style={{ zIndex: "9999 !important" }}
                    >
                      <div className="search-header d-flex-center justify-content-between mb-3">
                        <h3 className="title m-0">What are you looking for?</h3>
                        <button
                          type="button"
                          className="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        />
                      </div>
                      <div className="search-body">
                        <form
                          className="form minisearch"
                          id="header-search"
                          onSubmit={(e) => searchsubmit(e)}
                        >
                          <div className="d-flex searchField">
                            {/* <div className="search-category">
                              <select className="rgsearch-category rounded-end-0">
                                <option hidden selected>
                                  All Categories
                                </option>
                                {categoryShow &&
                                  categoryShow.map((category, index) => (
                                    <option key={index} value={category.name}>
                                      {category.name}
                                    </option>
                                  ))}
                              </select>
                            </div> */}
                            <div className="input-box d-flex fl-1">
                              <input
                                type="text"
                                className="input-text border-start-0 border-end-0"
                                placeholder="Search for products..."
                                value={search}
                                onChange={searchchange}
                              />
                              <button
                                type="submit"
                                onClick={serchclick}
                                className="action search d-flex-justify-center btn rounded-start-0"
                              >
                                <span style={{ color: "white" }}>
                                  {" "}
                                  <i className="icon anm anm-search-l" />
                                </span>
                              </button>
                            </div>
                          </div>
                          {/* <div className="popular-searches d-flex-justify-center mt-3">
                            <span className="title fw-600">Trending Now:</span>
                            <div className="d-flex-wrap searches-items">
                              <Link className="text-link ms-2">T-Shirt,</Link>
                              <Link className="text-link ms-2">Shoes,</Link>
                              <Link className="text-link ms-2">Bags</Link>
                            </div>
                          </div> */}
                          <div className="search-products">
                            {subcatSearchResult.length === 0 &&
                              categorySearchResult.length === 0 &&
                              categoryTagSearchResult.length === 0 && (
                                <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                  <li className="item empty w-100 text-center text-muted d-block">
                                    You don't have any items in your search.
                                  </li>
                                </ul>
                              )}

                            {categorySearchResult.length > 0 && (
                              <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                                {categorySearchResult &&
                                  categorySearchResult.map(
                                    (category, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/search/${category.superCategory}/${category.slugUrl}`}
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={category.desktopImage}
                                                src={category.desktopImage}
                                                alt={category.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/search/${category.superCategory}/${category.slugUrl}`}
                                              >
                                                {category.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                4 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                            {subcatSearchResult.length > 0 && (
                              <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                {subcatSearchResult &&
                                  subcatSearchResult.map(
                                    (sub_category, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/search/${sub_category.superCategory}/${sub_category.slugUrl}`}
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={
                                                  sub_category.desktopImage
                                                }
                                                src={sub_category.desktopImage}
                                                alt={sub_category.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/search/${sub_category.superCategory}/${sub_category.category}/${sub_category.slugUrl}`}
                                              >
                                                {sub_category.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                3 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                            {categoryTagSearchResult.length > 0 && (
                              <ul className="items g-2 g-md-3 row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 mt-1">
                                {categoryTagSearchResult &&
                                  categoryTagSearchResult.map(
                                    (category_tag, index) => (
                                      <li className="item" key={index}>
                                        <div className="mini-list-item d-flex align-items-center w-100 clearfix">
                                          <div className="mini-image text-center">
                                            <Link
                                              className="item-link"
                                              to={`/tag-search/${category_tag.slugUrl}`}
                                            >
                                              <img
                                                className="blur-up lazyload"
                                                data-src={
                                                  category_tag.desktopImage
                                                }
                                                src={category_tag.desktopImage}
                                                alt={category_tag.name}
                                                title="product"
                                                width={120}
                                                height={170}
                                              />
                                            </Link>
                                          </div>
                                          <div className="ms-3 details text-left">
                                            <div className="product-name">
                                              <Link
                                                className="item-title"
                                                to={`/tag-search/${category_tag.slugUrl}`}
                                              >
                                                {category_tag.name}
                                              </Link>
                                            </div>

                                            <div className="product-review d-flex align-items-center justify-content-start">
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star" />
                                              <i className="icon anm anm-star-o" />
                                              <i className="icon anm anm-star-o" />
                                              <span className="caption hidden ms-2">
                                                3 reviews
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="account-parent iconset">
                  <div className="account-link" title="Account">
                    <i className="hdr-icon icon anm anm-user-al" />
                  </div>
                  <div id="accountBox">
                    <div className="customer-links">
                      <ul className="m-0">
                        {isAuth === true ? (
                          <>
                            <li>
                              <Link to="/my-account">
                                <i className="icon anm anm-user-cil" />
                                My Account
                              </Link>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link to="/login">
                                <i className="icon anm anm-user-cil" />
                                Sign In
                              </Link>
                            </li>
                          </>
                        )}
                        <li>
                          <Link to="/Wishlist">
                            <i className="icon anm anm-heart-l" />
                            Wishlist
                          </Link>
                        </li>
                        <li>
                          <Link to="/">
                            <i className="icon anm anm-sign-out-al" />
                            Sign out
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="wishlist-link iconset" title="Wishlist">
                  <Link to="/Wishlist">
                    <i className="hdr-icon icon anm anm-heart-l" />
                    <span className="wishlist-count">0</span>
                  </Link>
                </div>
                <div className="header-cart iconset" title="Cart">
                  <Link
                    className="header-cart btn-minicart clr-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#minicart-drawer"
                  >
                    <i className="hdr-icon icon anm anm-cart-l" />
                    <span className="cart-count">{CartItems.length}</span>
                  </Link>

                  <div
                    id="minicart-drawer"
                    className="minicart-right-drawer offcanvas offcanvas-end"
                    tabIndex={-1}
                  >
                    {CartItems.length === 0 ? (
                      <>
                        <div
                          id="cartEmpty"
                          className="cartEmpty d-flex-justify-center flex-column text-center p-3 text-muted d-block"
                        >
                          <div className="minicart-header d-flex-center justify-content-between w-100">
                            <h4 className="fs-6">
                              Your cart ({CartItems.length} Items)
                            </h4>
                            <button
                              className="close-cart border-0"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              <i
                                className="icon anm anm-times-r"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Close"
                              />
                            </button>
                          </div>
                          <div className="cartEmpty-content mt-4">
                            <i className="icon anm anm-cart-l fs-1 text-muted" />
                            <p className="my-3">No Products in the Cart</p>
                            <span
                              className="btn btn-primary cart-btn"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Continue shopping
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div id="cart-drawer" className="block block-cart">
                          <div className="minicart-header">
                            <button
                              className="close-cart border-0"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              <i
                                className="icon anm anm-times-r"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Close"
                              />
                            </button>
                            <h4 className="fs-6">
                              Your cart ({CartItems.length} Items)
                            </h4>
                          </div>
                          <div className="minicart-content">
                            <ul className="m-0 clearfix">
                              {CartItems &&
                                CartItems.map((item, index) => (
                                  <li
                                    className="item d-flex justify-content-center align-items-center"
                                    key={index}
                                  >
                                    <span className="product-image rounded-3">
                                      <img
                                        className="blur-up lazyload"
                                        data-src={item.thumbnail}
                                        src={item.thumbnail}
                                        alt="product"
                                        title="Product"
                                        width={120}
                                        height={170}
                                      />
                                    </span>
                                    <div className="product-details">
                                      <span className="product-title limited-paragraph">
                                        {item.ProductName}
                                      </span>
                                      <div className="variant-cart my-2 limited-paragraph">
                                        {item.packSizeOptionName} /{" "}
                                        {item.packSizeName}
                                      </div>
                                      <div className="priceRow">
                                        <div className="product-price">
                                          <span className="price">
                                            {" "}
                                            ₹{item.Price}
                                          </span>{" "}
                                          x{" "}
                                          <span className="price">
                                            {" "}
                                            {item.cart_Quentity}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div className="minicart-bottom">
                            <div className="shipinfo">
                              {amountToGetfeeDelivery !== 0 && (
                                <div className="progress mb-2">
                                  <div
                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    aria-valuenow={70}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    style={{
                                      width: `${amountToGetfeeDeliveryPercentage}%`,
                                    }}
                                  >
                                    {amountToGetfeeDeliveryPercentage}%
                                  </div>
                                </div>
                              )}

                              {amountToGetfeeDeliveryPercentage === 0 ? (
                                <>
                                  <div className="freeShipMsg">
                                    <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                    Only{" "}
                                    <span
                                      className="money"
                                      data-currency-usd=" ₹199.00"
                                      data-currency="USD"
                                    >
                                      {" "}
                                      ₹{amountToGetfeeDelivery}
                                    </span>{" "}
                                    away from
                                    <b>Free Shipping</b>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="freeShipMsg d-block">
                                    <i className="icon anm anm-truck-l fs-6 me-2 align-middle" />
                                    Congrats! You are eligible for
                                    <b>Free Shipping</b>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="subtotal clearfix my-3">
                              <div className="totalInfo clearfix mb-1 d-block">
                                <span>Total:</span>
                                <span className="item product-price">
                                  {" "}
                                  ₹{TotalPrice}
                                </span>
                              </div>
                              <div className="totalInfo clearfix mb-1 d-block">
                                <span>Handlling Fee:</span>
                                <span className="item product-price">
                                  {" "}
                                  ₹{HandllingFee}
                                </span>
                              </div>
                              <div className="totalInfo clearfix">
                                <span>Netpayable:</span>
                                <span className="item product-price">
                                  {" "}
                                  ₹{TotalAmount}
                                </span>
                              </div>
                            </div>
                            {/* <div className="agree-check customCheckbox">
                              <input
                                id="prTearm"
                                name="tearm"
                                type="checkbox"
                                defaultValue="tearm"
                                required
                              />
                              <label htmlFor="prTearm">
                                {" "}
                                I agree with the{" "}
                              </label>
                              <Link  className="ms-1 text-link">
                                Terms &amp; conditions
                              </Link>
                            </div> */}
                            <div className="minicart-action d-flex mt-3">
                              <span
                                onClick={() => navigate("/check-out")}
                                className="proceed-to-checkout btn btn-primary w-50 me-1"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Check Out
                              </span>
                              <span
                                onClick={() => navigate("/cart")}
                                className="cart-btn btn btn-secondary w-50 ms-1"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                View Cart
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {/*MiniCart Content*/}
                  </div>
                </div>
                {/* <button type="button" className="iconset pe-0 menu-icon js-mobile-nav-toggle mobile-nav--open d-lg-none" title="Menu"><i className="hdr-icon icon anm anm-times-l" /><i className="hdr-icon icon anm anm-bars-r" /></button> */}
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Hadear;
