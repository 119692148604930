import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  mob: "",
  otp: "",
  isAuth: JSON.parse(localStorage.getItem("loginData"))
    ? JSON.parse(localStorage.getItem("loginData")).isAuth
    : false,
  loginData: localStorage.getItem("loginData")
    ? JSON.parse(localStorage.getItem("loginData"))
    : "",
  current_address: localStorage.getItem("current_address")
    ? JSON.parse(localStorage.getItem("current_address"))
    : "",
  user_address: localStorage.getItem("user_address")
    ? JSON.parse(localStorage.getItem("user_address"))
    : [],
  checkout_authentication_status: 0,
  checkout_process_steps: 0,
  userLoading: true,
  addressLoading: true,
};


export const getUserByMob = createAsyncThunk(
  "user/getUserByMob",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not found",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/loginclient`;
      resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const userRegistation = createAsyncThunk(
  "Athentication/userRegistation",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);
export const send_sms_through_backend = createAsyncThunk(
  "Athentication/send_sms_through_backend",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/sendsms`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);

export const verifyUserEmail = createAsyncThunk("user/verifyUserEmail", async (email, thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/client/email/${email}`;
    const resp = await axios(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const add_Address = createAsyncThunk(
  "Athentication/add_Address",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/address`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);


const AthenticationSlice = createSlice({
  name: "Athentication",
  initialState,
  reducers: {
    LoginOtp(state, action) {
      state.mob = action.payload.number;
      state.otp = action.payload.OTP;
    },
    set_checkout_authentication_status(state, action) {
      state.checkout_authentication_status = action.payload;
    },
    set_checkout_process_steps(state, action) {
      state.checkout_process_steps = action.payload;
    },
    signin(state, action) {
      state.isAuth = action.payload.isAuth;
      state.loginData = action.payload;

      state.user_address = action.payload.Addresses;
      if (state.user_address.length >= 1 && state.current_address === "") {
        state.current_address = state.user_address[0];
      }

      localStorage.setItem("loginData", JSON.stringify(state.loginData));
      localStorage.setItem("user_address", JSON.stringify(state.user_address));
      localStorage.setItem(
        "current_address",
        JSON.stringify(state.current_address)
      );
    },
    signout(state, action) {
      localStorage.removeItem("loginData");
      state.loginData.isAuth = false;
      state.isAuth = false;
    },

    set_Current_address(state, action) {
      state.current_address = action.payload;
      localStorage.setItem(
        "current_address",
        JSON.stringify(state.current_address)
      );
    },
    update_Address(state, action) {
      state.user_address = action.payload;
      state.current_address = state.user_address[0];
      localStorage.setItem("user_address", JSON.stringify(state.user_address));
      localStorage.setItem(
        "current_address",
        JSON.stringify(state.current_address)
      );
    },
  },
});

export const {
  LoginOtp,
  signin,
  signout,
  set_Current_address,
  update_Address,
  set_checkout_authentication_status,
  set_checkout_process_steps
} = AthenticationSlice.actions;
export default AthenticationSlice.reducer;
