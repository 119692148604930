import React, { useEffect, useState } from "react";
import { Slider, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getSingleProduct } from "../redux/product/productSlice";
import { addTocart, decrementCart } from "../redux/cart/CartSlice";
import { set_checkout_process_steps } from "../redux/athentication/Athentication";
import moment from "moment/moment";

const ProductInfoPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { CartItems, amountToGetfeeDelivery } = useSelector(
    (state) => state.cart
  );

  const [showButton, setShowButton] = useState(false);
  const [productifo, setProductinfo] = useState("");
  const [loading, setLoading] = useState(true);
  const [protest, setProtest] = useState(false);
  const [first_dates, setfirst_date] = useState(null);
  const [second_dates, setsecond_date] = useState(null);

  useEffect(() => {
    const data = productifo;
    setProductinfo(data);
  }, [productifo]);

  useEffect(() => {
    if (params.producturl) {
      const getProduct = async () => {
        const respoProduct = await dispatch(
          getSingleProduct(params.producturl)
        );

        if (respoProduct.payload.success) {
          const respo = respoProduct.payload.product;
          let first_date = new Date();
          first_date.setDate(
            first_date.getDate() + Number(respo.noof_delivery_days)
          );
          let second_date = new Date();
          second_date.setDate(
            second_date.getDate() + Number(respo.noof_delivery_days) + 3
          );
          setfirst_date(first_date);
          setsecond_date(second_date);
          let update_Product = respo;

          update_Product.packSize = update_Product.packSize.map((pack) => {
            pack.PackSizeOptions = pack.PackSizeOptions.map((pack_option) => {
              pack_option = {
                ...pack_option,
                cart_Quentity: 0,
              };
              return pack_option;
            });
            return pack;
          });

          let product_info = update_Product;
          CartItems.forEach((cartitem) => {
            let packIndex = -1;
            packIndex = product_info.packSize.findIndex(
              (pack) => String(pack._id) === String(cartitem.PackId)
            );

            if (packIndex >= 0) {
              let packOptionIndex = -1;

              packOptionIndex = product_info.packSize[
                packIndex
              ].PackSizeOptions.findIndex(
                (pack_option) =>
                  String(pack_option._id) === String(cartitem.PackOptionId)
              );

              const pack_option_Id =
                product_info.packSize[packIndex].PackSizeOptions[
                  packOptionIndex
                ]._id;

              let cart_index = -1;
              cart_index = CartItems.findIndex(
                (item) => String(item.PackOptionId) === String(pack_option_Id)
              );

              product_info.packSize[packIndex].PackSizeOptions[
                packOptionIndex
              ].cart_Quentity = CartItems[cart_index].cart_Quentity;
            }
          });

          setProductinfo(product_info);
          setLoading(false);
        }
      };
      getProduct();
    }
  }, [params.producturl]);

  const addTocartPress = async (
    e,
    packIndex,
    optionIndex,
    pack,
    packOption
  ) => {
    e.preventDefault();

    let update_cart_quentity = productifo;

    update_cart_quentity.packSize = update_cart_quentity.packSize.map(
      (pack, pcIndex) => {
        if (pcIndex === packIndex) {
          pack.PackSizeOptions = pack.PackSizeOptions.map(
            (pack_option, option_Index) => {
              if (option_Index === optionIndex) {
                pack_option.cart_Quentity = pack_option.cart_Quentity + 1;
              }
              return pack_option;
            }
          );
        }
        return pack;
      }
    );
    update_cart_quentity = update_cart_quentity;
    setProductinfo(update_cart_quentity);
    setProtest(!protest);

    const cart_form = {
      ProductName: productifo.name,
      ProductId: productifo._id,
      PackId: pack._id,
      PackOptionId: packOption._id,
      packSizeName: pack.packSizeName,
      thumbnail: pack.thumbnail,
      packSizeOptionName: packOption.packSizeOptionName,
      Mrp: Number(packOption.Mrp),
      Price: Number(packOption.Price),
      Product_total_Mrp: Number(packOption.Mrp) * 1,
      Product_total_Price: Number(packOption.Price) * 1,
      Product_total_Saving:
        Number(packOption.Mrp) * 1 - Number(packOption.Price) * 1,
      Discount: Number(packOption.Discount),
      superCategory: productifo.superCategory,
      category: productifo.category,
      SubCategory: productifo.SubCategory,
      CategoryTag: productifo.CategoryTag,
      cart_Quentity: 1,
      shopId: productifo.shopId,
      shopName: productifo.shopName,
      // shopId: "EWSiD",
      // shopName: "EWS",
    };

    const cart = await dispatch(addTocart(cart_form));
  };
  const add_to_cart_and_navigate = async (
    e,
    packIndex,
    optionIndex,
    pack,
    packOption
  ) => {
    e.preventDefault();

    let update_cart_quentity = productifo;

    update_cart_quentity.packSize = update_cart_quentity.packSize.map(
      (pack, pcIndex) => {
        if (pcIndex === packIndex) {
          pack.PackSizeOptions = pack.PackSizeOptions.map(
            (pack_option, option_Index) => {
              if (option_Index === optionIndex) {
                pack_option.cart_Quentity = pack_option.cart_Quentity + 1;
              }
              return pack_option;
            }
          );
        }
        return pack;
      }
    );
    update_cart_quentity = update_cart_quentity;
    setProductinfo(update_cart_quentity);
    setProtest(!protest);

    const cart_form = {
      ProductName: productifo.name,
      ProductId: productifo._id,
      PackId: pack._id,
      PackOptionId: packOption._id,
      packSizeName: pack.packSizeName,
      thumbnail: pack.thumbnail,
      packSizeOptionName: packOption.packSizeOptionName,
      Mrp: Number(packOption.Mrp),
      Price: Number(packOption.Price),
      Product_total_Mrp: Number(packOption.Mrp) * 1,
      Product_total_Price: Number(packOption.Price) * 1,
      Product_total_Saving:
        Number(packOption.Mrp) * 1 - Number(packOption.Price) * 1,
      Discount: Number(packOption.Discount),
      superCategory: productifo.superCategory,
      category: productifo.category,
      SubCategory: productifo.SubCategory,
      CategoryTag: productifo.CategoryTag,
      cart_Quentity: 1,
      shopId: productifo.shopId,
      shopName: productifo.shopName,
      shopId: productifo.shopId,
      shopName: productifo.shopName,
      // shopId: "EWSiD",
      // shopName: "EWS",
    };

    const cart = await dispatch(addTocart(cart_form));
    dispatch(set_checkout_process_steps(0));
    navigate("/check-out");
  };
  const decrementTocartPress = async (
    e,
    packIndex,
    optionIndex,
    pack,
    packOption
  ) => {
    e.preventDefault();

    let update_cart_quentity = productifo;

    update_cart_quentity.packSize = update_cart_quentity.packSize.map(
      (pack, pcIndex) => {
        if (pcIndex === packIndex) {
          pack.PackSizeOptions = pack.PackSizeOptions.map(
            (pack_option, option_Index) => {
              if (option_Index === optionIndex) {
                pack_option.cart_Quentity = pack_option.cart_Quentity - 1;
              }
              return pack_option;
            }
          );
        }
        return pack;
      }
    );
    update_cart_quentity = update_cart_quentity;
    setProductinfo(update_cart_quentity);
    setProtest(!protest);

    const cart_form = {
      ProductName: productifo.name,
      ProductId: productifo._id,
      PackId: pack._id,
      PackOptionId: packOption._id,
      packSizeName: pack.packSizeName,
      thumbnail: pack.thumbnail,
      packSizeOptionName: packOption.packSizeOptionName,
      Mrp: Number(packOption.Mrp),
      Price: Number(packOption.Price),
      Product_total_Mrp: Number(packOption.Mrp) * 1,
      Product_total_Price: Number(packOption.Price) * 1,
      Product_total_Saving:
        Number(packOption.Mrp) * 1 - Number(packOption.Price) * 1,
      Discount: Number(packOption.Discount),
      superCategory: productifo.superCategory,
      category: productifo.category,
      SubCategory: productifo.SubCategory,
      CategoryTag: productifo.CategoryTag,
      cart_Quentity: 1,
      shopId: productifo.shopId,
      shopName: productifo.shopName,
    };

    const cart = await dispatch(decrementCart(cart_form));
  };

  return (
    <>
      {loading ? (
        <>
          <div className="example">
            <Spin />
          </div>
        </>
      ) : (
        <>
          <div id="page-content">
            <div className="container">
              <div className="product-single">
                <div className="row">
                  {productifo.packSize.map((pack, packIndex) =>
                    pack.PsPrime === true ? (
                      <>
                        <div className="col-lg-9 col-md-12 col-sm-12 col-12 product-layout-img-info mb-4 mb-lg-0">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 product-layout-img mb-4 mb-md-0">
                              <div className="product-details-img product-horizontal-style">
                                <div className="zoompro-wrap">
                                  <div className="zoompro-span">
                                    <img
                                      id="zoompro"
                                      className="zoompro"
                                      src={pack.thumbnail}
                                      data-zoom-image={pack.thumbnail}
                                      alt="product"
                                      width={625}
                                      height={808}
                                    />
                                  </div>

                                  <div className="product-labels">
                                    <span className="lbl pr-label1">New</span>
                                    <span className="lbl on-sale">Sale</span>
                                  </div>
                                  {/* <div className="product-buttons">
                                    <a
                                      to="#productVideo-modal"
                                      className="btn popup-video"
                                      data-bs-toggle="modal"
                                      data-bs-target="#productVideo_modal"
                                    >
                                      <span
                                        className="icon-wrap d-flex-justify-center h-100 w-100"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Watch Video"
                                      >
                                        <i className="icon anm anm-video-r" />
                                      </span>
                                    </a>
                                    <a
                                      to="#"
                                      className="btn prlightbox"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Zoom Image"
                                    >
                                      <i className="icon anm anm-expand-l-arrows" />
                                    </a>
                                  </div> */}
                                </div>
                                <div className="product-thumb product-horizontal-thumb mt-3">
                                  <div
                                    id="gallery"
                                    className="product-thumb-horizontal"
                                  >
                                    <a
                                      data-image="assets/images/products/product5.jpg"
                                      data-zoom-image="assets/images/products/product5.jpg"
                                      className="slick-slide slick-cloned active"
                                    >
                                      <img
                                        className="blur-up lazyload"
                                        data-src="assets/images/products/product5.jpg"
                                        src="assets/images/products/product5.jpg"
                                        alt="product"
                                        width={625}
                                        height={808}
                                      />
                                    </a>
                                    <a
                                      data-image="assets/images/products/product5-1.jpg"
                                      data-zoom-image="assets/images/products/product5-1.jpg"
                                      className="slick-slide slick-cloned"
                                    >
                                      <img
                                        className="blur-up lazyload"
                                        data-src="assets/images/products/product5-1.jpg"
                                        src="assets/images/products/product5-1.jpg"
                                        alt="product"
                                        width={625}
                                        height={808}
                                      />
                                    </a>
                                    <a
                                      data-image="assets/images/products/product5-2.jpg"
                                      data-zoom-image="assets/images/products/product5-2.jpg"
                                      className="slick-slide slick-cloned"
                                    >
                                      <img
                                        className="blur-up lazyload"
                                        data-src="assets/images/products/product5-2.jpg"
                                        src="assets/images/products/product5-2.jpg"
                                        alt="product"
                                        width={625}
                                        height={808}
                                      />
                                    </a>
                                    <a
                                      data-image="assets/images/products/product5-3.jpg"
                                      data-zoom-image="assets/images/products/product5-3.jpg"
                                      className="slick-slide slick-cloned"
                                    >
                                      <img
                                        className="blur-up lazyload"
                                        data-src="assets/images/products/product5-3.jpg"
                                        src="assets/images/products/product5-3.jpg"
                                        alt="product"
                                        width={625}
                                        height={808}
                                      />
                                    </a>
                                    <a
                                      data-image="assets/images/products/product5-4.jpg"
                                      data-zoom-image="assets/images/products/product5-4.jpg"
                                      className="slick-slide slick-cloned"
                                    >
                                      <img
                                        className="blur-up lazyload"
                                        data-src="assets/images/products/product5-4.jpg"
                                        src="assets/images/products/product5-4.jpg"
                                        alt="product"
                                        width={625}
                                        height={808}
                                      />
                                    </a>
                                    <a
                                      data-image="assets/images/products/product5-5.jpg"
                                      data-zoom-image="assets/images/products/product5-5.jpg"
                                      className="slick-slide slick-cloned"
                                    >
                                      <img
                                        className="blur-up lazyload"
                                        data-src="assets/images/products/product5-5.jpg"
                                        src="assets/images/products/product5-5.jpg"
                                        alt="product"
                                        width={625}
                                        height={808}
                                      />
                                    </a>
                                  </div>
                                </div>
                                <div className="lightboximages">
                                  <span
                                    to="assets/images/products/product5.jpg"
                                    data-size="1000x1280"
                                  />
                                  <span
                                    to="assets/images/products/product5-1.jpg"
                                    data-size="1000x1280"
                                  />
                                  <span
                                    to="assets/images/products/product5-2.jpg"
                                    data-size="1000x1280"
                                  />
                                  <span
                                    to="assets/images/products/product5-3.jpg"
                                    data-size="1000x1280"
                                  />
                                  <span
                                    to="assets/images/products/product5-4.jpg"
                                    data-size="1000x1280"
                                  />
                                  <span
                                    to="assets/images/products/product5-5.jpg"
                                    data-size="1000x1280"
                                  />
                                </div>
                              </div>
                              {/* <div className="social-sharing d-flex-center justify-content-center mt-3 mt-md-4 lh-lg">
                                <span className="sharing-lbl fw-600">
                                  Share :
                                </span>
                                <a
                                  to="#"
                                  className="d-flex-center btn btn-link btn--share share-facebook"
                                >
                                  <i className="icon anm anm-facebook-f" />
                                  <span className="share-title">Facebook</span>
                                </a>
                                <a
                                  to="#"
                                  className="d-flex-center btn btn-link btn--share share-twitter"
                                >
                                  <i className="icon anm anm-twitter" />
                                  <span className="share-title">Tweet</span>
                                </a>
                                <a
                                  to="#"
                                  className="d-flex-center btn btn-link btn--share share-pinterest"
                                >
                                  <i className="icon anm anm-pinterest-p" />{" "}
                                  <span className="share-title">Pin it</span>
                                </a>
                                <a
                                  to="#"
                                  className="d-flex-center btn btn-link btn--share share-linkedin"
                                >
                                  <i className="icon anm anm-linkedin-in" />
                                  <span className="share-title">Linkedin</span>
                                </a>
                                <a
                                  to="#"
                                  className="d-flex-center btn btn-link btn--share share-email"
                                >
                                  <i className="icon anm anm-envelope-l" />
                                  <span className="share-title">Email</span>
                                </a>
                              </div> */}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 product-layout-info">
                              <div className="product-single-meta">
                                <h4
                                  className="product-main-title"
                                  style={{
                                    fontSize: "1rem",
                                  }}
                                >
                                  {productifo.name}
                                </h4>
                                <div className="product-review d-flex-center mb-3">
                                  <div className="reviewStar d-flex-center">
                                    <i className="icon anm anm-star" />
                                    <i className="icon anm anm-star" />
                                    <i className="icon anm anm-star" />
                                    <i className="icon anm anm-star" />
                                    <i className="icon anm anm-star-o" />
                                    <span className="caption ms-2">
                                      24 Reviews
                                    </span>
                                  </div>
                                  {/* <a
                                    className="reviewLink d-flex-center"
                                    to="#reviews"
                                  >
                                    Write a Review
                                  </a> */}
                                </div>
                                {pack.PackSizeOptions.map(
                                  (packOption, optionIndex) =>
                                    packOption.PsnPrime === true ? (
                                      <>
                                        <div className="product-price d-flex-center my-3">
                                          <span className="price old-price">
                                            {" "}
                                            ₹{packOption.Mrp}
                                          </span>
                                          <span className="price">
                                            {" "}
                                            ₹{packOption.Price}
                                          </span>
                                        </div>
                                        <div
                                          className="orderMsg d-flex-center mb-3"
                                          data-user={23}
                                          data-time={24}
                                        >
                                          <i className="icon anm anm-medapps" />
                                          <p className="m-0">
                                            <strong className="items">8</strong>{" "}
                                            Sold in last{" "}
                                            <strong className="time">14</strong>{" "}
                                            hours
                                          </p>
                                        </div>
                                        <div className="product-info">
                                          <p className="product-stock d-flex">
                                            Availability:
                                            <span className="pro-stockLbl ps-0">
                                              <span className="d-flex-center stockLbl instock text-uppercase">
                                                In stock
                                              </span>
                                            </span>
                                          </p>
                                          <p className="product-vendor">
                                            Vendor:
                                            <span className="text">
                                              <span>{productifo.shopName}</span>
                                            </span>
                                          </p>
                                          <p className="product-type">
                                            Product Type:
                                            <span className="text">
                                              {productifo.CategoryTag}
                                            </span>
                                          </p>
                                          <p className="product-sku">
                                            SKU:
                                            <span className="text">
                                              RF10456
                                            </span>
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <div key={optionIndex}></div>
                                      </>
                                    )
                                )}
                              </div>
                              <div className="countdown-text style1 d-flex-wrap mt-3 mb-1 justify-content-center flex-column">
                                <label>Hurry up! Sales End In </label>
                                <div
                                  className="prcountdown"
                                  data-countdown="2028/10/01"
                                />
                              </div>
                              <form
                                method="post"
                                action="#"
                                className="product-form product-form-border hidedropdown"
                              >
                                <div className="product-swatches-option">
                                  {/* <div
                                    className="product-item swatches-image swatch-rectangle-style w-100 mb-4 swatch-0 option1"
                                    data-option-index={0}
                                  >
                                    <label className="label d-flex align-items-center">
                                      Color:
                                      <span className="slVariant ms-1 fw-bold">
                                        Blue
                                      </span>
                                    </label>
                                    <ul className="variants-clr swatches d-flex-center pt-1 clearfix">
                                      <li className="swatch radius available blue0 active">
                                        <span
                                          className="swatchLbl"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Blue"
                                        >
                                          Blue
                                        </span>
                                      </li>
                                      <li className="swatch radius available black0">
                                        <span
                                          className="swatchLbl"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Black"
                                        >
                                          Black
                                        </span>
                                      </li>
                                      <li className="swatch radius available purple0">
                                        <span
                                          className="swatchLbl"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Purple"
                                        >
                                          Purple
                                        </span>
                                      </li>
                                      <li className="swatch radius available green0">
                                        <span
                                          className="swatchLbl"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Green"
                                        >
                                          Green
                                        </span>
                                      </li>
                                      <li className="swatch radius soldout yellow0">
                                        <span
                                          className="swatchLbl"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Yellow"
                                        >
                                          Yellow
                                        </span>
                                      </li>
                                    </ul>
                                  </div> */}
                                  {pack.PackSizeOptions.map(
                                    (packOption, optionIndex) =>
                                      packOption.PsnPrime === true ? (
                                        <>
                                          <div
                                            className="product-item swatches-size swatch-rectangle-style w-100 mb-4 swatch-1 option2"
                                            data-option-index={1}
                                          >
                                            <label className="label d-flex align-items-center">
                                              Size:
                                              <span className="slVariant ms-1 fw-bold">
                                                {packOption.packSizeOptionName}
                                              </span>{" "}
                                            </label>
                                            <ul className="variants-size size-swatches d-flex-center pt-1 clearfix">
                                              {/* <li className="swatch radius soldout">
                                                <span
                                                  className="swatchLbl"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="XS"
                                                >
                                                  XS
                                                </span>
                                              </li> */}
                                              {pack.PackSizeOptions.map(
                                                (data, index) => (
                                                  <li
                                                    className="swatch radius available active"
                                                    key={index}
                                                  >
                                                    <span
                                                      className="swatchLbl"
                                                      data-bs-toggle="tooltip"
                                                      data-bs-placement="top"
                                                      title="S"
                                                    >
                                                      {data.packSizeOptionName}
                                                    </span>
                                                  </li>
                                                )
                                              )}

                                              {/* <li className="swatch radius available">
                                                <span
                                                  className="swatchLbl"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="M"
                                                >
                                                  M
                                                </span>
                                              </li> */}
                                            </ul>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div key={optionIndex}></div>
                                        </>
                                      )
                                  )}
                                </div>
                                <div className="product-action w-100 d-flex-wrap my-3 my-md-4">
                                  {pack.PackSizeOptions.map(
                                    (packOption, optionIndex) =>
                                      packOption.PsnPrime === true ? (
                                        <>
                                          {packOption.cart_Quentity === 0 ? (
                                            <>
                                              <div className="product-form-submit addcart fl-1 ms-3">
                                                <button
                                                  name="add"
                                                  className="btn btn-secondary product-form-cart-submit"
                                                  onClick={(e) =>
                                                    addTocartPress(
                                                      e,
                                                      packIndex,
                                                      optionIndex,
                                                      pack,
                                                      packOption
                                                    )
                                                  }
                                                >
                                                  <span>Add to cart</span>
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="product-form-quantity d-flex-center">
                                                <div className="qtyField">
                                                  <span
                                                    className="qtyBtn minus cursor_pointer"
                                                    onClick={(e) =>
                                                      decrementTocartPress(
                                                        e,
                                                        packIndex,
                                                        optionIndex,
                                                        pack,
                                                        packOption
                                                      )
                                                    }
                                                  >
                                                    <i className="icon anm anm-minus-r" />
                                                  </span>
                                                  <input
                                                    type="text"
                                                    name="quantity"
                                                    contentEditable={false}
                                                    className="product-form-input qty"
                                                    value={
                                                      packOption.cart_Quentity
                                                    }
                                                  />
                                                  <span
                                                    className="qtyBtn plus cursor_pointer"
                                                    onClick={(e) =>
                                                      addTocartPress(
                                                        e,
                                                        packIndex,
                                                        optionIndex,
                                                        pack,
                                                        packOption
                                                      )
                                                    }
                                                  >
                                                    <i className="icon anm anm-plus-r" />
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <div className="product-form-submit buyit d-flex w-100 mt-3">
                                            <button
                                              type="submit"
                                              className="btn btn-primary proceed-to-checkout"
                                              onClick={(e) =>
                                                add_to_cart_and_navigate(
                                                  e,
                                                  packIndex,
                                                  optionIndex,
                                                  pack,
                                                  packOption
                                                )
                                              }
                                            >
                                              <span>Buy it now</span>
                                            </button>
                                            <span
                                              className="btn btn-secondary wishlist-submit w-auto p-3 ms-2"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="Add to Wishlist"
                                            >
                                              <i className="icon anm anm-heart-l fs-6" />
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div key={optionIndex}></div>
                                        </>
                                      )
                                  )}
                                </div>
                                <p className="infolinks d-flex-center">
                                  <a className="text-link compare" to="">
                                    <i className="icon anm anm-sync-ar me-2" />{" "}
                                    <span>Add to Compare</span>
                                  </a>
                                  <a
                                    to="#shippingInfo-modal"
                                    className="text-link shippingInfo"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#shippingInfo_modal"
                                  >
                                    <i className="icon anm anm-paper-l-plane me-2" />{" "}
                                    <span>Delivery &amp; Returns</span>
                                  </a>
                                  <a
                                    to="#productInquiry-modal"
                                    className="text-link emaillink me-0"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#productInquiry_modal"
                                  >
                                    <i className="icon anm anm-question-cil me-2" />{" "}
                                    <span>Ask A Question</span>
                                  </a>
                                </p>
                              </form>
                              {/* <div
                                className="userViewMsg featureText"
                                data-user={20}
                                data-time={11000}
                              >
                                <i className="icon anm anm-eye-r" />
                                <b className="uersView">1</b>
                                People are Looking for this Product
                              </div> */}
                              <div className="shippingMsg featureText">
                                <i className="icon anm anm-clock-r" />
                                Estimated Delivery Between{" "}
                                <b id="fromDate">
                                  {moment(first_dates).format("DD MMM")}
                                </b>{" "}
                                and{" "}
                                <b id="toDate">
                                  {moment(second_dates).format("DD MMM")}
                                </b>
                                .
                              </div>
                              {amountToGetfeeDelivery === 0 ? (<>
                                <div
                                className="freeShipMsg featureText"
                                data-price={199}
                              >
                                <i className="icon anm anm-truck-r" />
                                Congrats! You are eligible forFree Shipping
                              </div>
                              
                              </>) : (<>
                                <div
                                className="freeShipMsg featureText"
                                data-price={199}
                              >
                                <i className="icon anm anm-truck-r" />
                                Spent{" "}
                                <b className="freeShip">
                                  <span
                                    className="money"
                                    data-currency-usd=" ₹199.00"
                                    data-currency="USD"
                                  >
                                    {" "}
                                    ₹{amountToGetfeeDelivery}
                                  </span>
                                </b>
                                More for Free shipping
                              </div>
                              </>)}
                            
                             
                            </div>
                          </div>
                          {/* <div className="upsell-bundle">
                            <hr />
                            <h4 className="mb-3">
                              Buy The Look - Upsell Bundle
                            </h4>
                            <form
                              method="post"
                              action="#"
                              id="usbForm"
                              className="usbFrm"
                            >
                              <div className="usbGroup d-md-flex">
                                <div className="usbImgWrap mb-3 mb-md-0">
                                  <div className="row g-4 mb-3">
                                    <div className="col usbImgCall usbImg1">
                                      <a className="usb-link" to="#">
                                        <img
                                          className="usbImg1"
                                          src="assets/images/products/product1-120x170.jpg"
                                          alt="product"
                                          width={100}
                                        />
                                      </a>
                                    </div>
                                    <div className="col usbImgCall usbImg2">
                                      <a className="usb-link" to="#">
                                        <img
                                          className="usbImg2"
                                          src="assets/images/products/product2-120x170.jpg"
                                          alt="product"
                                          width={100}
                                        />
                                      </a>
                                    </div>
                                    <div className="col usbImgCall usbImg3">
                                      <a className="usb-link" to="#">
                                        <img
                                          className="usbImg3"
                                          src="assets/images/products/product3-120x170.jpg"
                                          alt="product"
                                          width={100}
                                        />
                                      </a>
                                    </div>
                                    <div className="col usbImgCall usbImg4">
                                      <a className="usb-link" to="#">
                                        <img
                                          className="usbImg4"
                                          src="assets/images/products/product4-120x170.jpg"
                                          alt="product"
                                          width={100}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="usbRow d-flex align-items-center mb-2 active">
                                    <div className="customCheckbox clearfix">
                                      <input
                                        id="usbCk1"
                                        name="usbCk1"
                                        type="checkbox"
                                        defaultChecked="checked"
                                      />
                                      <label htmlFor="usbCk1">
                                        <b>This Item</b>: Oxford Cuban Shirt
                                      </label>
                                    </div>
                                    <b className="price mx-2"> ₹195.00</b>
                                    <select
                                      className="usbVrt"
                                      name="items[][id]"
                                      id="PrSelect-195"
                                      data-section={195}
                                    >
                                      <option
                                        selected="selected"
                                        value={195}
                                        data-price={195}
                                      >
                                        Red / S
                                      </option>
                                      <option value={195} data-price={195}>
                                        Black / S
                                      </option>
                                      <option value={195} data-price={195}>
                                        Pink / S
                                      </option>
                                      <option value={195} data-price={195}>
                                        Red / M
                                      </option>
                                      <option value={195} data-price={195}>
                                        Blue / M
                                      </option>
                                      <option value={195} data-price={195}>
                                        Pink / M
                                      </option>
                                      <option value={195} data-price={195}>
                                        Red / L
                                      </option>
                                      <option value={195} data-price={195}>
                                        Blue / L
                                      </option>
                                      <option value={195} data-price={195}>
                                        Black / L
                                      </option>
                                    </select>
                                  </div>
                                  <div className="usbRow d-flex align-items-center mb-2 active">
                                    <div className="customCheckbox clearfix">
                                      <input
                                        id="usbCk2"
                                        name="usbCk2"
                                        type="checkbox"
                                        defaultChecked="checked"
                                      />
                                      <label htmlFor="usbCk2">
                                        Cuff Beanie Cap
                                      </label>
                                    </div>
                                    <b className="price mx-2"> ₹172.00</b>
                                    <select
                                      className="usbVrt"
                                      name="items[][id]"
                                      id="PrSelect-172"
                                      data-section={172}
                                    >
                                      <option
                                        selected="selected"
                                        value={39320359731260}
                                        data-price={172}
                                      >
                                        Pink / S
                                      </option>
                                      <option value={172} data-price={172}>
                                        Red / M
                                      </option>
                                      <option value={172} data-price={172}>
                                        Black / L
                                      </option>
                                    </select>
                                  </div>
                                  <div className="usbRow d-flex align-items-center mb-2 active">
                                    <div className="customCheckbox clearfix">
                                      <input
                                        id="usbCk3"
                                        name="usbCk3"
                                        type="checkbox"
                                      />
                                      <label htmlFor="usbCk3">
                                        Ankle Casual Pants
                                      </label>
                                    </div>
                                    <b className="price mx-2"> ₹128.00</b>
                                    <select
                                      className="usbVrt"
                                      name="items[][id]"
                                      id="PrSelect-128"
                                      data-section={128}
                                    >
                                      <option
                                        selected="selected"
                                        value={128}
                                        data-price={128}
                                      >
                                        28
                                      </option>
                                      <option value={128} data-price={128}>
                                        30
                                      </option>
                                      <option value={128} data-price={128}>
                                        32
                                      </option>
                                    </select>
                                  </div>
                                  <div className="usbRow d-flex align-items-center mb-0 active">
                                    <div className="customCheckbox clearfix">
                                      <input
                                        id="usbCk4"
                                        name="usbCk4"
                                        type="checkbox"
                                      />
                                      <label htmlFor="usbCk4">
                                        Black Handbag
                                      </label>
                                    </div>
                                    <b className="price mx-2"> ₹99.00</b>
                                    <select
                                      className="usbVrt hide"
                                      name="items[][id]"
                                      id="PrSelect-99"
                                      data-section={99}
                                    >
                                      <option
                                        selected="selected"
                                        value={99}
                                        data-price={99}
                                      >
                                        Default Title
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="usbbtnCall text-md-center">
                                  <p className="mb-3">
                                    TOTAL:{" "}
                                    <b
                                      className="usbPrice product-price-sale fs-6 fw-600 text-primary"
                                      data-total={594}
                                      data-pr={594}
                                    >
                                      {" "}
                                      ₹594.00
                                    </b>
                                  </p>
                                  <p className="mb-3">
                                    <button
                                      type="submit"
                                      name="upselladd"
                                      id="upsellAddToCart"
                                      className="btn upsellAddToCart"
                                    >
                                      Add selected to cart
                                    </button>
                                  </p>
                                  <p className="mb-0">
                                    Get a 15% discount buying these products
                                    together
                                  </p>
                                </div>
                              </div>
                            </form>
                          </div> */}
                          {/* <a
                            className="product-nav prev-pro clr-none d-flex-center justify-content-between"
                            title="Previous Product"
                          >
                            <span className="details">
                              <span className="name">Oxford Cuban Shirt</span>
                              <span className="price"> ₹99.00</span>
                            </span>
                            <span className="img">
                              <img
                                src="assets/images/products/product1-120x170.jpg"
                                alt="product"
                                width={120}
                                height={170}
                              />
                            </span>
                          </a>
                          <a
                            to=""
                            className="product-nav next-pro clr-none d-flex-center justify-content-between"
                            title="Next Product"
                          >
                            <span className="img">
                              <img
                                src="assets/images/products/product2-120x170.jpg"
                                alt="product"
                                width={120}
                                height={170}
                              />
                            </span>
                            <span className="details">
                              <span className="name">Cuff Beanie Cap</span>
                              <span className="price"> ₹128</span>
                            </span>
                          </a> */}
                          <div
                            className="accordion tab-accordian-style section pb-0"
                            id="productAccordian"
                          >
                            <div className="accordion-item border-0 bg-transparent mb-2">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  Description
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#productAccordian"
                              >
                                <div className="accordion-body px-0 product-description">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <p>
                                        There are many variations of passages of
                                        Lorem Ipsum available, but the majority
                                        have suffered alteration in some form,
                                        by injected humour, or randomised words
                                        which don't look even slightly
                                        believable.
                                      </p>
                                      {productifo.designDetails.Length !==
                                        0 && (
                                        <>
                                          <h4 className="mb-3">Features</h4>
                                          <ul className="checkmark-info">
                                            {productifo.designDetails.map(
                                              (data, index) => (
                                                <li key={index}>
                                                  {data.designContent}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </>
                                      )}
                                      {productifo.SizeFit.Length !== 0 && (
                                        <>
                                          <h4 className="mb-3">Size and Fit</h4>
                                          <ul className="checkmark-info">
                                            {productifo.SizeFit.map(
                                              (data, index) => (
                                                <li key={index}>{data}</li>
                                              )
                                            )}
                                          </ul>
                                        </>
                                      )}
                                      {productifo.MaterialCare.Length !== 0 && (
                                        <>
                                          <h4 className="mb-3">Fabric</h4>
                                          <ul className="checkmark-info">
                                            {productifo.MaterialCare.map(
                                              (data, index) => (
                                                <li key={index}>{data}</li>
                                              )
                                            )}
                                          </ul>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item border-0 bg-transparent mb-2">
                              <h2 className="accordion-header" id="headingFive">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseFive"
                                >
                                  Additional Information
                                </button>
                              </h2>
                              <div
                                id="collapseFive"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFive"
                                data-bs-parent="#productAccordian"
                              >
                                <div
                                  className="accordion-body px-0 product-description"
                                  id="additionalInformation"
                                >
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4 mb-md-0">
                                      <div className="table-responsive">
                                        <table className="table table-bordered align-middle table-part mb-0">
                                          <tbody>
                                            {productifo.clothing.Type !==
                                              "" && (
                                              <tr>
                                                <th>Type</th>
                                                <td>
                                                  {productifo.clothing.Type}
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.topDesign !==
                                              "" && (
                                              <tr>
                                                <th>Top Design</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .topDesign
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing
                                              .SleeveLength !== "" && (
                                              <tr>
                                                <th>Sleeve Length</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .SleeveLength
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.Shape !==
                                              "" && (
                                              <tr>
                                                <th>Shape</th>
                                                <td>
                                                  {productifo.clothing.Shape}
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.Neck !==
                                              "" && (
                                              <tr>
                                                <th>Neck</th>
                                                <td>
                                                  {productifo.clothing.Neck}
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.Length !==
                                              "" && (
                                              <tr>
                                                <th>Length</th>
                                                <td>
                                                  {productifo.clothing.Length}
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing
                                              .WeavePattern !== "" && (
                                              <tr>
                                                <th>Weave Pattern</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .WeavePattern
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.WeaveType !==
                                              "" && (
                                              <tr>
                                                <th>Weave Type</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .WeaveType
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.Occasion !==
                                              "" && (
                                              <tr>
                                                <th>Occasion</th>
                                                <td>
                                                  {productifo.clothing.Occasion}
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing
                                              .DupattaBorder !== "" && (
                                              <tr>
                                                <th>Dupatta Border</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .DupattaBorder
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.BottomType !==
                                              "" && (
                                              <tr>
                                                <th>Bottom Type</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .BottomType
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing
                                              .BottomPattern !== "" && (
                                              <tr>
                                                <th>Bottom Pattern</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .BottomPattern
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.TopPattern !==
                                              "" && (
                                              <tr>
                                                <th>Top Pattern</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .TopPattern
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.Dupatta !==
                                              "" && (
                                              <tr>
                                                <th>Dupatta</th>
                                                <td>
                                                  {productifo.clothing.Dupatta}
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing
                                              .DupattaPattern !== "" && (
                                              <tr>
                                                <th>Dupatta Pattern</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .DupattaPattern
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing
                                              .BottomClosure !== "" && (
                                              <tr>
                                                <th>Bottom Closure</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .BottomClosure
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing.waistband !==
                                              "" && (
                                              <tr>
                                                <th>waistband</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .waistband
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {productifo.clothing
                                              .patternCoverage !== "" && (
                                              <tr>
                                                <th>pattern Coverage</th>
                                                <td>
                                                  {
                                                    productifo.clothing
                                                      .patternCoverage
                                                  }
                                                </td>
                                              </tr>
                                            )}

                                            {/* <tr>
                                              <th>Date First Available</th>
                                              <td>14 May 2023</td>
                                            </tr>
                                            <tr>
                                              <th>Manufacturer</th>
                                              <td>
                                                Fashion and Retail Limited
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>Department</th>
                                              <td>Men Shirt</td>
                                            </tr> */}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item border-0 bg-transparent mb-2">
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  Size Chart
                                </button>
                              </h2>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#productAccordian"
                              >
                                <div
                                  className="accordion-body px-0 pb-3"
                                  id="size-chart"
                                >
                                  <h4 className="mb-2">
                                    Ready to Wear Clothing
                                  </h4>
                                  <p className="mb-4">
                                    This is a standardised guide to give you an
                                    idea of what size you will need, however
                                    some brands may vary from these conversions.
                                  </p>
                                  <div className="size-chart-tbl table-responsive px-1">
                                    <table className="table-bordered align-middle mb-0">
                                      <thead>
                                        <tr>
                                          <th>Size</th>
                                          <th>XXS - XS</th>
                                          <th>XS - S</th>
                                          <th>S - M</th>
                                          <th>M - L</th>
                                          <th>L - XL</th>
                                          <th>XL - XXL</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th>UK</th>
                                          <td>6</td>
                                          <td>8</td>
                                          <td>10</td>
                                          <td>12</td>
                                          <td>14</td>
                                          <td>16</td>
                                        </tr>
                                        <tr>
                                          <th>US</th>
                                          <td>2</td>
                                          <td>4</td>
                                          <td>6</td>
                                          <td>8</td>
                                          <td>10</td>
                                          <td>12</td>
                                        </tr>
                                        <tr>
                                          <th>Italy (IT)</th>
                                          <td>38</td>
                                          <td>40</td>
                                          <td>42</td>
                                          <td>44</td>
                                          <td>46</td>
                                          <td>48</td>
                                        </tr>
                                        <tr>
                                          <th>France (FR/EU)</th>
                                          <td>34</td>
                                          <td>36</td>
                                          <td>38</td>
                                          <td>40</td>
                                          <td>42</td>
                                          <td>44</td>
                                        </tr>
                                        <tr>
                                          <th>Denmark</th>
                                          <td>32</td>
                                          <td>34</td>
                                          <td>36</td>
                                          <td>38</td>
                                          <td>40</td>
                                          <td>42</td>
                                        </tr>
                                        <tr>
                                          <th>Russia</th>
                                          <td>40</td>
                                          <td>42</td>
                                          <td>44</td>
                                          <td>46</td>
                                          <td>48</td>
                                          <td>50</td>
                                        </tr>
                                        <tr>
                                          <th>Germany</th>
                                          <td>32</td>
                                          <td>34</td>
                                          <td>36</td>
                                          <td>38</td>
                                          <td>40</td>
                                          <td>42</td>
                                        </tr>
                                        <tr>
                                          <th>Japan</th>
                                          <td>5</td>
                                          <td>7</td>
                                          <td>9</td>
                                          <td>11</td>
                                          <td>13</td>
                                          <td>15</td>
                                        </tr>
                                        <tr>
                                          <th>Australia</th>
                                          <td>6</td>
                                          <td>8</td>
                                          <td>10</td>
                                          <td>12</td>
                                          <td>14</td>
                                          <td>16</td>
                                        </tr>
                                        <tr>
                                          <th>Korea</th>
                                          <td>33</td>
                                          <td>44</td>
                                          <td>55</td>
                                          <td>66</td>
                                          <td>77</td>
                                          <td>88</td>
                                        </tr>
                                        <tr>
                                          <th>China</th>
                                          <td>160/84</td>
                                          <td>165/86</td>
                                          <td>170/88</td>
                                          <td>175/90</td>
                                          <td>180/92</td>
                                          <td>185/94</td>
                                        </tr>
                                        <tr>
                                          <th>Jeans</th>
                                          <td>24-25</td>
                                          <td>26-27</td>
                                          <td>27-28</td>
                                          <td>29-30</td>
                                          <td>31-32</td>
                                          <td>32-33</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item border-0 bg-transparent mb-2">
                              <h2
                                className="accordion-header"
                                id="headingThree"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  Shipping &amp; Return
                                </button>
                              </h2>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#productAccordian"
                              >
                                <div
                                  className="accordion-body px-0"
                                  id="shipping-return"
                                >
                                  <h4 className="pb-1">
                                    Shipping &amp; Return
                                  </h4>
                                  <ul className="checkmark-info">
                                    <li>Dispatch: Within 24 Hours</li>
                                    <li>1 Year Brand Warranty</li>
                                    <li>
                                      Free shipping across all products on a
                                      minimum purchase of ₹50.
                                    </li>
                                    <li>
                                      International delivery time - 7-10
                                      business days
                                    </li>
                                    <li>Cash on delivery might be available</li>
                                    <li>Easy 30 days returns and exchanges</li>
                                  </ul>
                                  <h4 className="pt-1">
                                    Free and Easy Returns
                                  </h4>
                                  <p>
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled
                                    it to make a type specimen book. It has
                                    survived not only five centuries, but also
                                    the leap into electronic typesetting,
                                    remaining essentially unchanged.
                                  </p>
                                  <h4 className="pt-1">Special Financing</h4>
                                  <p>
                                    There are many variations of passages of
                                    Lorem Ipsum available, but the majority have
                                    suffered alteration in some form, by
                                    injected humour, or randomised words which
                                    don't look even slightly believable. If you
                                    are going to use a passage.
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="accordion-item border-0 bg-transparent mb-2">
                              <h2 className="accordion-header" id="headingFour">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseFour"
                                >
                                  Reviews
                                </button>
                              </h2>
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#productAccordian"
                              >
                                <div
                                  className="accordion-body px-0"
                                  id="reviews"
                                >
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                                      <div className="ratings-main">
                                        <div className="avg-rating d-flex-center mb-3">
                                          <h4 className="avg-mark">4.5</h4>
                                          <div className="avg-content ms-3">
                                            <p className="text-rating">
                                              Average Rating
                                            </p>
                                            <div className="ratings-full product-review">
                                              <a
                                                className="reviewLink d-flex-center"
                                                to="#reviews"
                                              >
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star-o" />
                                                <span className="caption ms-2">
                                                  24 Ratings
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ratings-list">
                                          <div className="ratings-container d-flex align-items-center mt-1">
                                            <div className="ratings-full product-review m-0">
                                              <a
                                                className="reviewLink d-flex align-items-center"
                                                to="#reviews"
                                              >
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star-o" />
                                              </a>
                                            </div>
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow={99}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{ width: "99%" }}
                                              />
                                            </div>
                                            <div className="progress-value">
                                              99%
                                            </div>
                                          </div>
                                          <div className="ratings-container d-flex align-items-center mt-1">
                                            <div className="ratings-full product-review m-0">
                                              <a
                                                className="reviewLink d-flex align-items-center"
                                                to="#reviews"
                                              >
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star-o" />
                                              </a>
                                            </div>
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow={75}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{ width: "75%" }}
                                              />
                                            </div>
                                            <div className="progress-value">
                                              75%
                                            </div>
                                          </div>
                                          <div className="ratings-container d-flex align-items-center mt-1">
                                            <div className="ratings-full product-review m-0">
                                              <a
                                                className="reviewLink d-flex align-items-center"
                                                to="#reviews"
                                              >
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star-o" />
                                                <i className="icon anm anm-star-o" />
                                              </a>
                                            </div>
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow={50}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{ width: "50%" }}
                                              />
                                            </div>
                                            <div className="progress-value">
                                              50%
                                            </div>
                                          </div>
                                          <div className="ratings-container d-flex align-items-center mt-1">
                                            <div className="ratings-full product-review m-0">
                                              <a
                                                className="reviewLink d-flex align-items-center"
                                                to="#reviews"
                                              >
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star-o" />
                                                <i className="icon anm anm-star-o" />
                                                <i className="icon anm anm-star-o" />
                                              </a>
                                            </div>
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow={25}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{ width: "25%" }}
                                              />
                                            </div>
                                            <div className="progress-value">
                                              25%
                                            </div>
                                          </div>
                                          <div className="ratings-container d-flex align-items-center mt-1">
                                            <div className="ratings-full product-review m-0">
                                              <a
                                                className="reviewLink d-flex align-items-center"
                                                to="#reviews"
                                              >
                                                <i className="icon anm anm-star" />
                                                <i className="icon anm anm-star-o" />
                                                <i className="icon anm anm-star-o" />
                                                <i className="icon anm anm-star-o" />
                                                <i className="icon anm anm-star-o" />
                                              </a>
                                            </div>
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow={5}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                                style={{ width: "5%" }}
                                              />
                                            </div>
                                            <div className="progress-value">
                                              05%
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 mb-4">
                                      <form
                                        method="post"
                                        action="#"
                                        className="product-review-form new-review-form"
                                      >
                                        <h3 className="spr-form-title">
                                          Write a Review
                                        </h3>
                                        <p>
                                          Your email address will not be
                                          published. Required fields are marked
                                          *
                                        </p>
                                        <fieldset className="row spr-form-contact">
                                          <div className="col-sm-6 spr-form-contact-name form-group">
                                            <label
                                              className="spr-form-label"
                                              htmlFor="nickname"
                                            >
                                              Name{" "}
                                              <span className="required">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              className="spr-form-input spr-form-input-text"
                                              id="nickname"
                                              type="text"
                                              name="name"
                                              required
                                            />
                                          </div>
                                          <div className="col-sm-6 spr-form-contact-email form-group">
                                            <label
                                              className="spr-form-label"
                                              htmlFor="email"
                                            >
                                              Email{" "}
                                              <span className="required">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              className="spr-form-input spr-form-input-email "
                                              id="email"
                                              type="email"
                                              name="email"
                                              required
                                            />
                                          </div>
                                          <div className="col-sm-6 spr-form-review-title form-group">
                                            <label
                                              className="spr-form-label"
                                              htmlFor="review"
                                            >
                                              Review Title
                                            </label>
                                            <input
                                              className="spr-form-input spr-form-input-text "
                                              id="review"
                                              type="text"
                                              name="review"
                                            />
                                          </div>
                                          <div className="col-sm-6 spr-form-review-rating form-group">
                                            <label className="spr-form-label">
                                              Rating
                                            </label>
                                            <div className="product-review pt-1">
                                              <div className="review-rating">
                                                <a >
                                                  <i className="icon anm anm-star-o" />
                                                </a>
                                                <a >
                                                  <i className="icon anm anm-star-o" />
                                                </a>
                                                <a >
                                                  <i className="icon anm anm-star-o" />
                                                </a>
                                                <a >
                                                  <i className="icon anm anm-star-o" />
                                                </a>
                                                <a >
                                                  <i className="icon anm anm-star-o" />
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12 spr-form-review-body form-group">
                                            <label
                                              className="spr-form-label"
                                              htmlFor="message"
                                            >
                                              Body of Review{" "}
                                              <span className="spr-form-review-body-charactersremaining">
                                                (1500) characters remaining
                                              </span>
                                            </label>
                                            <div className="spr-form-input">
                                              <textarea
                                                className="spr-form-input spr-form-input-textarea"
                                                id="message"
                                                name="message"
                                                rows={3}
                                                defaultValue={""}
                                              />
                                            </div>
                                          </div>
                                        </fieldset>
                                        <div className="spr-form-actions clearfix">
                                          <input
                                            type="submit"
                                            className="btn btn-primary spr-button spr-button-primary"
                                            defaultValue="Submit Review"
                                          />
                                        </div>
                                      </form>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="spr-reviews">
                                        <h3 className="spr-form-title">
                                          Customer Reviews
                                        </h3>
                                        <div className="review-inner">
                                          <div className="spr-review d-flex w-100">
                                            <div className="spr-review-profile flex-shrink-0">
                                              <img
                                                className="blur-up lazyload"
                                                data-src="assets/images/users/user-img1.jpg"
                                                src="assets/images/users/user-img1.jpg"
                                                alt
                                                width={200}
                                                height={200}
                                              />
                                            </div>
                                            <div className="spr-review-content flex-grow-1">
                                              <div className="d-flex justify-content-between flex-column mb-2">
                                                <div className="title-review d-flex align-items-center justify-content-between">
                                                  <h5 className="spr-review-header-title text-transform-none mb-0">
                                                    Eleanor Pena
                                                  </h5>
                                                  <span className="product-review spr-starratings m-0">
                                                    <span className="reviewLink">
                                                      <i className="icon anm anm-star" />
                                                      <i className="icon anm anm-star" />
                                                      <i className="icon anm anm-star" />
                                                      <i className="icon anm anm-star" />
                                                      <i className="icon anm anm-star" />
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                              <b className="head-font">
                                                Good and High quality
                                              </b>
                                              <p className="spr-review-body">
                                                There are many variations of
                                                passages of Lorem Ipsum
                                                available, but the majority have
                                                suffered alteration in some
                                                form, by injected humour.
                                              </p>
                                            </div>
                                          </div>
                                          <div className="spr-review d-flex w-100">
                                            <div className="spr-review-profile flex-shrink-0">
                                              <img
                                                className="blur-up lazyload"
                                                data-src="assets/images/users/testimonial1.jpg"
                                                src="assets/images/users/testimonial1.jpg"
                                                alt
                                                width={200}
                                                height={200}
                                              />
                                            </div>
                                            <div className="spr-review-content flex-grow-1">
                                              <div className="d-flex justify-content-between flex-column mb-2">
                                                <div className="title-review d-flex align-items-center justify-content-between">
                                                  <h5 className="spr-review-header-title text-transform-none mb-0">
                                                    Courtney Henry
                                                  </h5>
                                                  <span className="product-review spr-starratings m-0">
                                                    <span className="reviewLink">
                                                      <i className="icon anm anm-star" />
                                                      <i className="icon anm anm-star" />
                                                      <i className="icon anm anm-star" />
                                                      <i className="icon anm anm-star-o" />
                                                      <i className="icon anm anm-star-o" />
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                              <b className="head-font">
                                                Feature Availability
                                              </b>
                                              <p className="spr-review-body">
                                                The standard chunk of Lorem
                                                Ipsum used since the 1500s is
                                                reproduced below for those
                                                interested. Sections 1.10.32 and
                                                1.10.33
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          {/*End Product Accordian*/}
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={packIndex}></div>
                      </>
                    )
                  )}
                  <div className="col-lg-3 col-md-12 col-sm-12 col-12 product-sidebar sidebar sidebar-bg">
                    {/*Shipping Info*/}
                    <div className="sidebar-widget clearfix">
                      <div className="widget-content pt-0 mt-0 border-0">
                        <div className="store-info-item d-flex align-items-center mb-3">
                          <div className="icon me-3">
                            <i className="icon anm anm-truck-r fs-5" />
                          </div>
                          <div className="content">
                            <h5 className="title text-transform-none mb-1">
                              Free &amp; Fast shipping
                            </h5>
                            <p className="text text-muted text-small">
                              Free Shipping Worldwide
                            </p>
                          </div>
                        </div>
                        <div className="store-info-item d-flex align-items-center mb-3">
                          <div className="icon me-3">
                            <i className="icon anm anm-credit-card-l fs-5" />
                          </div>
                          <div className="content">
                            <h5 className="title text-transform-none mb-1">
                              Secure Checkout
                            </h5>
                            <p className="text text-muted text-small">
                              We accept all major credit cards
                            </p>
                          </div>
                        </div>
                        <div className="store-info-item d-flex align-items-center mb-3">
                          <div className="icon me-3">
                            <i className="icon anm anm-clock-r fs-5" />
                          </div>
                          <div className="content">
                            <h5 className="title text-transform-none mb-1">
                              Online Support
                            </h5>
                            <p className="text text-muted text-small">
                              We support online 24/7 on day
                            </p>
                          </div>
                        </div>
                        <div className="store-info-item d-flex align-items-center mb-3">
                          <div className="icon me-3">
                            <i className="icon anm anm-thumbs-up-l fs-5" />
                          </div>
                          <div className="content">
                            <h5 className="title text-transform-none mb-1">
                              Satisfaction Guarantee
                            </h5>
                            <p className="text text-muted text-small">
                              1 Year Brand Warranty
                            </p>
                          </div>
                        </div>
                        <div className="store-info-item d-flex align-items-center">
                          <div className="icon me-3">
                            <i className="icon anm anm-shield-alt fs-5" />
                          </div>
                          <div className="content">
                            <h5 className="title text-transform-none mb-1">
                              Privacy Protected
                            </h5>
                            <p className="text text-muted text-small">
                              More than 8 different secure
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="sidebar-widget clearfix">
                      <div className="widget-title">
                        <h2>Recently Viewed Products</h2>
                      </div>
                      <div className="widget-content">
                        <div className="list list-sidebar-products">
                          <div className="mini-list-item d-flex w-100 clearfix">
                            <div className="mini-image">
                              <a className="item-link" to="">
                                <img
                                  className="primary blur-up lazyload"
                                  data-src="assets/images/products/product1-120x170.jpg"
                                  src="assets/images/products/product1-120x170.jpg"
                                  alt="image"
                                  title="product"
                                  width={120}
                                  height={170}
                                />
                              </a>
                            </div>
                            <div className="ms-3 details">
                              <div className="product-name">
                                <a className="item-title" to="">
                                  Oxford Cuban Shirt
                                </a>
                              </div>
                              <div className="product-price">
                                <span className="old-price"> ₹114.00</span>
                                <span className="price"> ₹99.00</span>
                              </div>
                              <div className="product-review d-flex align-items-center justify-content-start">
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star-o" />
                                <i className="icon anm anm-star-o" />
                                <span className="caption hidden ms-2">
                                  3 reviews
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="mini-list-item d-flex w-100 clearfix">
                            <div className="mini-image">
                              <a className="item-link" to="">
                                <img
                                  className="primary blur-up lazyload"
                                  data-src="assets/images/products/product2-120x170.jpg"
                                  src="assets/images/products/product2-120x170.jpg"
                                  alt="image"
                                  title="product"
                                  width={120}
                                  height={170}
                                />
                              </a>
                            </div>
                            <div className="ms-3 details">
                              <div className="product-name">
                                <a className="item-title" to="">
                                  Cuff Beanie Cap
                                </a>
                              </div>
                              <div className="product-price">
                                <span className="price"> ₹128.00</span>
                              </div>
                              <div className="product-review d-flex align-items-center justify-content-start">
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <span className="caption hidden ms-2">
                                  9 reviews
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="mini-list-item d-flex w-100 clearfix">
                            <div className="mini-image">
                              <a className="item-link" to="prod">
                                <img
                                  className="primary blur-up lazyload"
                                  data-src="assets/images/products/product3-120x170.jpg"
                                  src="assets/images/products/product3-120x170.jpg"
                                  alt="image"
                                  title="product"
                                  width={120}
                                  height={170}
                                />
                              </a>
                            </div>
                            <div className="ms-3 details">
                              <div className="product-name">
                                <a className="item-title" to="">
                                  Flannel Collar Shirt
                                </a>
                              </div>
                              <div className="product-price">
                                <span className="price"> ₹99.00</span>
                              </div>
                              <div className="product-review d-flex align-items-center justify-content-start">
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star" />
                                <i className="icon anm anm-star-o" />
                                <span className="caption hidden ms-2">
                                  30 reviews
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="sidebar-widget clearfix">
                      <div className="widget-title">
                        <h2>Static Block Content</h2>
                      </div>
                      <div className="widget-content">
                        <p>
                          Use this text to share information about your brand
                          with your customers. Describe a product, share
                          announcements, or welcome customers to your store.
                        </p>
                      </div>
                    </div>
                    {/* <div className="sidebar-widget static-banner p-0">
                      <a to="">
                        <img
                          className="blur-up lazyload"
                          data-src="assets/images/banners/shop-banner.jpg"
                          src="assets/images/banners/shop-banner.jpg"
                          alt="image"
                          width={274}
                          height={367}
                        />
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <TopProducts /> */}
          <div className="stickyCart">
            {productifo.packSize.map((pack, packIndex) =>
              pack.PsPrime === true ? (
                <>
                  {pack.PackSizeOptions.map((packOption, optionIndex) =>
                    packOption.PsnPrime === true ? (
                      <>
                        <div className="container">
                          <span
                            id="stickycart-form"
                            className="d-flex-center justify-content-center"
                          >
                            <div className="product-featured-img">
                              <img
                                className="blur-up lazyload"
                                data-src={pack.thumbnail}
                                src={pack.thumbnail}
                                alt="product"
                                width={120}
                                height={170}
                              />
                            </div>
                            <div className="sticky-title ms-2 ps-1 pe-5">
                              {packOption.packSizeOptionName}
                            </div>
                            <div className="stickyOptions position-relative">
                              <div className="selectedOpt">
                                <span className="money">
                                  ₹{packOption.Price}
                                </span>
                              </div>
                            </div>
                            {packOption.cart_Quentity === 0 ? (
                              <>
                                <button
                                  name="add"
                                  onClick={(e) =>
                                    addTocartPress(
                                      e,
                                      packIndex,
                                      optionIndex,
                                      pack,
                                      packOption
                                    )
                                  }
                                  className="btn btn-secondary product-form-cart-submit mx-2 cursor_pointer"
                                >
                                  <span>Add to cart</span>
                                </button>
                              </>
                            ) : (
                              <>
                                <div className="qtyField mx-2">
                                  <span
                                    className="qtyBtn minus cursor_pointer"
                                    onClick={(e) =>
                                      decrementTocartPress(
                                        e,
                                        packIndex,
                                        optionIndex,
                                        pack,
                                        packOption
                                      )
                                    }
                                  >
                                    <i className="icon anm anm-minus-r" />
                                  </span>
                                  <input
                                    type="text"
                                    name="quantity"
                                    contentEditable={false}
                                    className="product-form-input qty"
                                    value={packOption.cart_Quentity}
                                  />
                                  <span
                                    className="qtyBtn plus cursor_pointer"
                                    onClick={(e) =>
                                      addTocartPress(
                                        e,
                                        packIndex,
                                        optionIndex,
                                        pack,
                                        packOption
                                      )
                                    }
                                  >
                                    <i className="icon anm anm-plus-r" />
                                  </span>
                                </div>
                              </>
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div key={optionIndex}></div>
                      </>
                    )
                  )}
                </>
              ) : (
                <>
                  <div key={packIndex}></div>
                </>
              )
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ProductInfoPage;
