import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CheckoutStepsPage from "./CheckoutStepsPage";
import { set_checkout_process_steps } from "../../redux/athentication/Athentication";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth, current_address, loginData } = useSelector(
    (state) => state.Athentication
  );
  const { CartItems } = useSelector((state) => state.cart);

  useEffect(() => {
    if (CartItems.length === 0) {
      navigate("/");
    }
  }, [CartItems]);

  const chgangeProccessStep1 = () => {
    dispatch(set_checkout_process_steps(0));
  };
  const chgangeProccessStep2 = () => {
    if (isAuth === true) {
      dispatch(set_checkout_process_steps(1));
    }
  };
  const chgangeProccessStep3 = () => {
    if (isAuth === true && current_address !== "") {
      dispatch(set_checkout_process_steps(2));
    }
  };
  const chgangeProccessStep4 = () => {
    if (isAuth === true && current_address !== "") {
      dispatch(set_checkout_process_steps(3));
    }
  };

  return (
    <>
      <div id="page-content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div id="nav-tabs" className="step-checkout">
                <ul className="nav nav-tabs step-items">
                  <li className="nav-item ">
                    <Link
                      className="nav-link"
                      //   data-bs-toggle="tab"
                      //   to="#steps1"
                      onClick={() => chgangeProccessStep1()}
                    >
                      authentication
                    </Link>
                  </li>
                  <li className="nav-item">
                    <span
                      className="nav-link"
                      //   data-bs-toggle="tab"
                      onClick={() => chgangeProccessStep2()}
                    >
                      Address
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className="nav-link"
                      //   data-bs-toggle="tab"
                      onClick={() => chgangeProccessStep3()}
                    >
                      Order Summary
                    </span>
                  </li>
                  {/* <li className="nav-item">
                    <span
                      className="nav-link"
                      onClick={() => chgangeProccessStep4()}
                      //   data-bs-toggle="tab"
                    >
                      Payment
                    </span>
                  </li> */}
                </ul>
              </div>

              <div className="tab-content checkout-form">
                <CheckoutStepsPage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
