import React from "react";
import ShopByCategorySlider from "../../component/homecomponent/ShopByCategorySlider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ShopByCategory = () => {
  const { categoryShow } = useSelector((store) => store.category);

  return (
    <>
      {categoryShow.length > 0 && (
        <section className="section collection-slider pb-0">
          <div className="container">
            <div className="section-header">
              <p className="mb-2 mt-0">Shop by category</p>
              <h2>Popular Collections</h2>
            </div>
            <div className="collection-slider-5items gp15 arwOut5 hov-arrow">
              {/* <ShopByCategorySlider /> */}
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  "@0.75": {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  "@1.00": {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                  "@1.50": {
                    slidesPerView: 6,
                    spaceBetween: 25,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {categoryShow &&
                  categoryShow.map((showData, index) => (
                    <SwiperSlide key={index}>
                      <div className="category-item zoomscal-hov">
                        <Link
                          to={`/category/${showData.slugUrl}`}
                          className="category-link clr-none"
                        >
                          <div className="zoom-scal zoom-scal-nopb rounded-3">
                            <img
                              className="blur-up lazyload"
                              src={showData.desktopImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "assets/images/collection/sub-collection3.jpg";
                              }}
                              alt="Tops"
                              title="Tops"
                              width={365}
                              height={365}
                            />
                          </div>
                          <div className="details mt-3 text-center detailsSwipper">
                            <h4 className="category-title mb-0">
                              {showData.name}
                            </h4>
                            <p className="counts">
                              {showData.product_Length} Products
                            </p>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  ))}

                {/* <SwiperSlide>
                <div className="category-item zoomscal-hov">
                  <Link className="category-link clr-none">
                    <div className="zoom-scal zoom-scal-nopb rounded-3">
                      <img
                        className="blur-up lazyload"
                        data-src="assets/images/collection/sub-collection3.jpg"
                        src="assets/images/collection/sub-collection3.jpg"
                        alt="Tops"
                        title="Tops"
                        width={365}
                        height={365}
                      />
                    </div>
                    <div className="details mt-3 text-center detailsSwipper">
                      <h4 className="category-title mb-0">Tops</h4>
                      <p className="counts">13 Products</p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-item zoomscal-hov">
                  <Link className="category-link clr-none">
                    <div className="zoom-scal zoom-scal-nopb rounded-3">
                      <img
                        className="blur-up lazyload"
                        data-src="assets/images/collection/sub-collection5.jpg"
                        src="assets/images/collection/sub-collection5.jpg"
                        alt="T-Shirts"
                        title="T-Shirts"
                        width={365}
                        height={365}
                      />
                    </div>
                    <div className="details mt-3 text-center detailsSwipper">
                      <h4 className="category-title mb-0">T-Shirts</h4>
                      <p className="counts">18 Products</p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-item zoomscal-hov">
                  <Link className="category-link clr-none">
                    <div className="zoom-scal zoom-scal-nopb rounded-3">
                      <img
                        className="blur-up lazyload"
                        data-src="assets/images/collection/sub-collection6.jpg"
                        src="assets/images/collection/sub-collection6.jpg"
                        alt="Shoes"
                        title="Shoes"
                        width={365}
                        height={365}
                      />
                    </div>
                    <div className="details mt-3 text-center detailsSwipper">
                      <h4 className="category-title mb-0">Shoes</h4>
                      <p className="counts">11 Products</p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-item zoomscal-hov">
                  <Link className="category-link clr-none">
                    <div className="zoom-scal zoom-scal-nopb rounded-3">
                      <img
                        className="blur-up lazyload"
                        data-src="assets/images/collection/sub-collection9.jpg"
                        src="assets/images/collection/sub-collection9.jpg"
                        alt="Shorts"
                        title="Shorts"
                        width={365}
                        height={365}
                      />
                    </div>
                    <div className="details mt-3 text-center detailsSwipper">
                      <h4 className="category-title mb-0">Shorts</h4>
                      <p className="counts">28 Products</p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-item zoomscal-hov">
                  <Link className="category-link clr-none">
                    <div className="zoom-scal zoom-scal-nopb rounded-3">
                      <img
                        className="blur-up lazyload"
                        data-src="assets/images/collection/sub-collection2.jpg"
                        src="assets/images/collection/sub-collection2.jpg"
                        alt="Sunglasses"
                        title="Sunglasses"
                        width={365}
                        height={365}
                      />
                    </div>
                    <div className="details mt-3 text-center detailsSwipper">
                      <h4 className="category-title mb-0">Sunglasses</h4>
                      <p className="counts">24 Products</p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="category-item zoomscal-hov">
                  <Link className="category-link clr-none">
                    <div className="zoom-scal zoom-scal-nopb rounded-3">
                      <img
                        className="blur-up lazyload"
                        data-src="assets/images/collection/sub-collection4.jpg"
                        src="assets/images/collection/sub-collection4.jpg"
                        alt="Girls Top"
                        title="Girls Top"
                        width={365}
                        height={365}
                      />
                    </div>
                    <div className="details mt-3 text-center detailsSwipper">
                      <h4 className="category-title mb-0">Girls Top</h4>
                      <p className="counts">26 Products</p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ShopByCategory;
