import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Input } from "antd";
import {
  getUserByMob,
  set_checkout_authentication_status,
  set_checkout_process_steps,
  signin,
} from "../../redux/athentication/Athentication";
import { Link, useNavigate } from "react-router-dom";

const Otp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { otp, mob } = useSelector((state) => state.Athentication);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [otpValuesErrors, setOtpValuesErrors] = useState([]);
  const inputRefs = useRef([]);
  const [buttonPress, setbuttonPress] = useState(false);
  console.log(otp, "otp");
  useEffect(() => {
    if (buttonPress === true) {
      const allEmpty = otpValues.every((item) => item !== "");
      if (allEmpty === false) {
        let errorarray = [];
        for (let index = 0; index < otpValues.length; index++) {
          let element = otpValues[index];
          let oject = "";
          if (element === "") {
            oject = index;
          } else {
            oject = "";
          }
          errorarray = [...errorarray, oject];
        }
        setOtpValuesErrors([...errorarray]);
      }
    }
  }, [buttonPress, otpValues]);

  const handleOtpChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && !otpValues[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    const allEmpty = otpValues.every((item) => item !== "");
    if (allEmpty === true) {
      const concatenatedString = otpValues.join("");

      if (concatenatedString.length === 6 && concatenatedString === otp) {
        const formData = {
          Mobile: mob,
        };
        const userDetails = await dispatch(getUserByMob(formData));
        if (userDetails.payload.success === true) {
          const user_data = userDetails.payload.client;
          dispatch(
            signin({
              ...user_data,
              isAuth: true,
            })
          );
          dispatch(set_checkout_authentication_status(0));
          navigate("/");
        } else {
          dispatch(set_checkout_authentication_status(2));
        }
      }
    }
  };
  return (
    <>
      <div className="container">
        <div className="login-register pt-2">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mb-5">
              <div className="block h-100">
                <div className="block-content">
                  <form method="post" action="#" className="login-form">
                    <h3 className="title">Enter Otp</h3>
                    <div className="row">
                      <div className="col-12 form-group">
                        {otpValues.map((value, index) => (
                          <Input
                            key={index}
                            ref={(input) => (inputRefs.current[index] = input)}
                            style={
                              otpValuesErrors.includes(index)
                                ? {
                                    width: "50px",
                                    marginRight: "10px",
                                    border: "1px solid red",
                                    backgroundColor: "#ffe6e6",
                                  }
                                : { width: "50px", marginRight: "10px" }
                            }
                            maxLength={1}
                            value={value}
                            onChange={(e) =>
                              handleOtpChange(
                                index,
                                e.target.value.replace(/\D/g, "")
                              )
                            }
                            onKeyDown={(e) => handleKeyDown(e, index)}
                          />
                        ))}
                      </div>
                      <div className="col-12 d-flex justify-content-between align-items-center">
                        <input
                          type="submit"
                          onClick={(e) => verifyOtp(e)}
                          className="btn"
                          defaultValue="Sign In"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
